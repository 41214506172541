import React from "react";
import BuildResume from "../config/BuildResume";

const ISMResumeBuild = () => {
  const config = {
    name: "Untitled",
    language: "en",
    direction: "ltr",
    company: "Indian Institute of Technology, Bombay",
    sections: [
      {
        name: "Personal Details",
        key: "sections.profile.data",
        description: "Please fill the fields below with your contact and personal details.",
        type: "static",
        displayKey: "sections.profile.data.name",
        fields: [
          {
            name: "name",
            key: "sections.profile.data.name",
            label: "Name",
            placeholder: "",
            type: "text",
          },
          {
            name: "degree",
            key: "sections.profile.data.degree",
            label: "Degree",
            placeholder: "",
            type: "text",
          },
          {
            name: "branch",
            key: "sections.profile.data.branch",
            label: "Branch",
            placeholder: "",
            type: "text"
          },
          {
            name: "college",
            key: "sections.profile.data.college",
            label: "College",
            placeholder: "",
            type: "text",
            defaultValue: "",
          },
          {
            name: "admissionNumber",
            key: "sections.profile.data.admissionNumber",
            label: "Admission Number",
            placeholder: "",
            type: "text",
          },
          {
            name: "dob",
            key: "sections.profile.data.dob",
            label: "Date of Birth",
            placeholder: "",
            type: "text",
          },
        ],
      },
      {
        name: "Education",
        key: "sections.education.data",
        description: "Your academic achievements",
        type: "dynamic",
        displayKey: "degree",
        fields: [
          {
            name: "degree",
            key: "degree",
            label: "Degree",
            placeholder: "e.g. B.Tech, Class 12th, etc.",
            type: "text",
          },
          {
            name: "university",
            key: "university",
            label: "University",
            placeholder: "Eg. CBSE, IIT Bombay, etc.",
            type: "text",
          },
          {
            name: "college",
            key: "college",
            label: "College",
            placeholder: "",
            type: "text",
          },
          {
            name: "year",
            key: "year",
            label: "Year",
            placeholder: "",
            type: "text",
          },
          {
            name: "cgpa",
            key: "cgpa",
            label: "GPI",
            placeholder: "",
            type: "text",
          },
          {
            name: "percentage",
            key: "percentage",
            label: "Percentage",
            placeholder: "",
            type: "text",
          },
        ],
      },
      {
        name: "Experience",
        key: "sections.experience.data",
        description: "Experience always counts! Add your experience here.",
        type: "dynamic",
        displayKey: "company",
        fields: [
          {
            name: "company",
            key: "company",
            label: "Company Name",
            placeholder: "",
            type: "text",
          },
          {
            name: "role",
            key: "role",
            label: "Role",
            placeholder: "",
            type: "text",
          },
          {
            name: "timePeriod",
            key: "timePeriod",
            label: "Time Period",
            placeholder: "e.g. (Jan 2020 - Feb 2021)",
            type: "date",
          },
          // {
          //   name: "description",
          //   key: "description",
          //   label: "Description",
          //   placeholder: "e.g. (Worked on ...)",
          //   type: "textarea",
          // },
          {
            name: "points",
            key: "points",
            label: "Key Points",
            placeholder: "",
            type: "list-string",
          },
        ],
      },
      // {
      //   name: "Scholastic Achievements",
      //   key: "sections.achievements.data",
      //   description: "Your achievements",
      //   type: "dynamic",
      //   displayKey: "description",
      //   fields: [
      //     {
      //       name: "description",
      //       key: "description",
      //       label: "Description",
      //       placeholder: "",
      //       type: "text",
      //     },
      //     {
      //       name: "year",
      //       key: "year",
      //       label: "Year",
      //       placeholder: "",
      //       type: "text",
      //     },
      //   ],
      // },
     
      {
        name: "Projects",
        key: "sections.projects.data",
        description: "Good and relevant projects are always a plus!",
        type: "dynamic",
        displayKey: "title",
        fields: [
          {
            name: "title",
            key: "title",
            label: "Title",
            placeholder: "",
            type: "text",
          },
          {
            name: "organisation",
            key: "organisation",
            label: "Organisation",
            placeholder: "e.g. Institute technical Summer Project, etc.",
            type: "text",
          },
          {
            name: "year",
            key: "year",
            label: "Time Period",
            placeholder: "",
            type: "date",
          },
          {
            name: "description",
            key: "description",
            label: "Description",
            placeholder: "",
            type: "textarea",
          },
          {
            name: "points",
            key: "points",
            label: "Feature Points",
            placeholder: "",
            type: "list-string",
          },
        ],
      },
      {
        name: "Technical Skills",
        key: "sections.technicalSkills.data",
        description: "Your technical skills",
        type: "dynamic",
        displayKey: "name",
        fields: [
          {
            name: "name",
            key: "name",
            label: "Name",
            placeholder: "E.g Basic, intermediate, Advance etc",
            type: "text",
          },
          {
            name: "skills",
            key: "skills",
            label: "Skills",
            placeholder: "",
            type: "list-string",
          },
        ],
      },
      
      // {
      //   name: "Professional Summary",
      //   key: "sections.Professionalsummary.data",
      //   description: "Please fill the fields below with your Summary details.",
      //   type: "static",
      //   displayKey: "sections.Professionalsummary.data.summary",
      //   fields: [
      //     {
      //       name: "Professionalsummary",
      //       key: "sections.Professionalsummary.data.summary",
      //       label: "Professional Summary",
      //       placeholder: "",
      //       type: "textarea",
      //     }
      //   ],
      // },
      {
        name: "Optional",
        key: "sections.optional.data",
        description: "Tell a little more about yourself",
        type: "dynamic",
        displayKey: "name",
        fields: [
          {
            name: "name",
            key: "name",
            label: "Name",
            placeholder: "Example: Achievements, Extra Curricular",
            type: "text",
          },
          {
            name: "description",
            key: "description",
            label: "Description",
            placeholder: "",
            type: "textarea",
          },
          // {
          //   name: "points",
          //   key: "points",
          //   label: "Feature Points",
          //   placeholder: "",
          //   type: "list-string",
          // },
        ],
      },
     
    ],
  };
  return <BuildResume config={config} />;
};

export default ISMResumeBuild;
