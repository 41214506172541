import React, { useEffect, useState } from "react";
import Navbar from "../components/DashBoard.js/Navbar";
import Left from "../components/DashBoard.js/Left";
import styles from "../assets/Dashboard/dashboard.module.css";
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";
import HeadCont from "../components/DashBoard.js/HeadCont";
import Resume from "../components/DashBoard.js/Resume";
import Recomanded from "../components/DashBoard.js/Recomanded";
import Blog from "../components/DashBoard.js/Blog";
import MyResponsiveNavbar from "./Navbar";
import HeadContMobile from "../components/DashBoard.js/HeadContMobile";
import BottomBar from "../components/BottomBar";
import {useNavigate } from "react-router-dom";
import Modeluploadscratch from "../components/Modeluploadscratch";
import ModelChoseTemplate from "../components/ModelChoseTemplate";
import ModalChooseDomain from "../components/ModalChooseDomain";
import ModelAskExp from "../components/ModelAskExp";
const DashBoardNew = () => {
  const Navigate = useNavigate();
  const [modalShow,setModalShow] = useState(false); 
  const [SecondmodalShow,setSecondModalShow] = useState(false); 
  const [ThirdmodalShow,setThirdModalShow] = useState(false);
  const [FourthmodalShow,setFourthModalShow] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [imgSrc, setImgSrc] = useState("https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg");
  const { auth , previousUrlCheatSheet , isCheatSheetRdirect , setIsCheatSheetRdirect , setFlagdownloadCheatSheet} = useAuth();
  
  useEffect(() => {
    if (Array.isArray(auth?.user) && auth.user.length > 0) {
      setImgSrc(
        auth.user[0]?.images ||
          "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
      );
    }

    if(isCheatSheetRdirect){
      setIsCheatSheetRdirect(false); 
      setFlagdownloadCheatSheet(true);
      Navigate(previousUrlCheatSheet);
    }
  }, [auth]);



  return (
    <>
      <div className={styles.navbarMobile}> <MyResponsiveNavbar /></div>
      <div className={styles.main}>
          <div className={styles.left}><Left auth={auth} image={imgSrc} /></div>
        <div className={styles.right}>
          <div className={styles.navbar}> <Navbar auth={auth} image={imgSrc} /></div>
          <div className={styles.headcontmobile}> <HeadContMobile auth={auth} modalShow={modalShow} setModalShow={setModalShow} SecondmodalShow={SecondmodalShow} setSecondModalShow={setSecondModalShow} /></div>
          <div className={styles.headdesktop}> <HeadCont auth={auth} modalShow={modalShow} setModalShow={setModalShow} SecondmodalShow={SecondmodalShow} setSecondModalShow={setSecondModalShow} /></div>
          <Resume modalShow={modalShow} setModalShow={setModalShow} SecondmodalShow={SecondmodalShow} setSecondModalShow={setSecondModalShow} />
          <Recomanded />
          <Blog/>
        </div>
        <BottomBar />
      </div>
        <Modeluploadscratch modalShow={modalShow} setModalShow={setModalShow} setSecondModalShow={setFourthModalShow}  /> 
        <ModelAskExp modalShow={FourthmodalShow} setModalShow={setFourthModalShow} setSecondModalShow={setSecondModalShow} setSelectedExperience={setSelectedExperience} setSelectedSector={setSelectedSector} setSelectedDomain={setSelectedDomain}  selectedExperience={selectedExperience} selectedSector={selectedSector} selectedDomain={selectedDomain} /> 
        {/* <ModalChooseDomain modalShow={SecondmodalShow} setModalShow={setSecondModalShow} setThirdModalShow={setThirdModalShow} /> */}
        <ModelChoseTemplate modalShow={SecondmodalShow} setModalShow={setSecondModalShow} selectedExperience={selectedExperience} selectedSector={selectedSector} selectedDomain={selectedDomain} /> 
    </>
  );
};

export default DashBoardNew;
