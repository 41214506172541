import React from "react";
import styles from "./MMLResume.module.css";
import { getSafeMarkdownString } from "../../../../utils/markdown";
import { Check, TicketCheck } from "lucide-react";
const MMLResume = ({ sections }) => {
  return (
    <div className={styles.resumeContainer}>
      <div className={styles.headerBar}></div>
      <div className={styles.resume}>
        <Header profile={sections.profile.data} />
        <About Professionalsummary={sections.Professionalsummary.data} />
        <TechnicalSkills technicalSkills={sections.technicalSkills?.data} />
        <Awards awards={sections.awards?.data} />
        <Experience experience={sections.experience?.data} />
        <Projects projects={sections.projects?.data} />
        <Education education={sections.education?.data} />
        <Optional optional={sections.optional?.data} />
      </div>
    </div>
  );
};

const SectionTitle = ({ title }) => {
  return (
    <div className={styles.sectionTitleWrapper}>
      <div className={styles.dividerLine}></div>
      <span className={styles.sectionTitle}>{title}</span>
      <div className={styles.dividerLine}></div>
    </div>
  );
};

const Header = ({ profile }) => {
  if (!profile) return null;
  return (
    <div className={styles.header}>
      <div className={styles.fullName}>{profile.name}</div>
      <div className={styles.jobTitle}>{profile.position}</div>
      <div className={styles.contactInfo}>
        <span>
          {" "}
          <span className={styles.bold}>{profile.address}</span>{" "}
        </span>

        <span>
          {" "}
          {profile.phone ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.phone}</span>{" "}
        </span>
        <span>
          {profile.email ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.email}</span>{" "}
        </span>
        <span>
          {" "}
          {profile.linkedinUsername ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.linkedinUsername}</span>{" "}
        </span>
        <span>
          {" "}
          {profile.githubUsername ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.githubUsername}</span>{" "}
        </span>
      </div>
    </div>
  );
};

const About = ({ Professionalsummary }) => {
  if (!Professionalsummary) return null;
  return (
    <div>
        <SectionTitle title="PROFESSIONAL SUMMARY" />

      <div className={styles.secondline}>
        <span
          className={styles.resumesampletextabout}
          dangerouslySetInnerHTML={{ __html: Professionalsummary?.summary }}
        />
      </div>
    </div>
  );
};

const Points = ({ points, years, secondary }) => {
  if (!points) return null;
  return (
    <ul className={styles.points + " " + (secondary ? styles.secondary : null)}>
      {points?.map((point, index) => (
        <li key={index} className={styles.li}>
          <div className={styles.eeor}>
            <div
              key={index}
              dangerouslySetInnerHTML={{
                __html: getSafeMarkdownString(point),
              }}
            />
            {years && <i>{years[index]}</i>}
          </div>
        </li>
      ))}
    </ul>
  );
};

const Experience = ({ experience }) => {
  if (!experience || experience.length === 0) return null;
  return (
    <div>

      <SectionTitle title="PROFESSIONAL EXPERIENCE" />

      {experience?.map((exp, index) => (
        <div key={index}>
          <div className={styles.her}>
            <div className={styles.resumesampleh2}>
              {exp.role}
              <span className={styles.removefontwidth}>
                {" "}
                  <span>
                    {exp.company ? `, ${exp.company}` : ""}
                    {exp.location ? `, ${exp.location}` : ""}
                  </span>
                  <span className={styles.timeperiod}>
                    {exp.timePeriod ? `| ${exp.timePeriod}` : ""}{" "}
                  </span>
              </span>{" "}
            </div>
          </div>

          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: exp.description }}
          />
          <Points points={exp.points} markdown={true} secondary={true} />
        </div>
      ))}
    </div>
  );
};

const Projects = ({ projects }) => {
  if (!projects || projects.length === 0) return null;
  return (
    <div>
          <SectionTitle title="PROJECTS" />

      {projects?.map((project, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
            <div className={styles.resumesampleh2}>
              {project.title}
              <span className={styles.projectDetails}>
                {project.organisation ? `, ${project.organisation}` : ""}
                {project.year ? ` | ${project.year}` : ""}{" "}
              </span>
            </div>
          </div>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: project.description }}
          />
          <div>
            <Points points={project.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Education = ({ education }) => {
  if (!education || education.length === 0) return null;
  return (
    <div className={styles.educationMainContainer}>
      
          <SectionTitle title="EDUCATION" />

      {education.map((edu, index) => (
        <div key={index} className={styles.educationContainer}>
          <div className={styles.collegeContainer}>
            <div className={styles.collegeName}>{edu.college}</div>
            <div>{edu.year}</div>
          </div>
          <div className={styles.degreeContainer}>
            <div>
              {edu.degree} {edu.branch ? `in ${edu.branch} ` : " "}{" "}
            </div>
            <div>
              {edu.cgpa
                ? `${edu.cgpa}`
                : "" || edu.percentage
                ? `${edu.percentage}%`
                : ""}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const TechnicalSkills = ({ technicalSkills }) => {
  if (!technicalSkills || technicalSkills.length === 0) return null;
  return (
    <div>
      
      <SectionTitle title="Areas of Expertise" />
      <div className={styles.hr}></div>
      {technicalSkills.map((tech, index) => (
        <div key={index}>
          <strong>{tech.name}</strong> {tech.name ? ":" : ""}  
          <span >{tech.skills?.join(",")}</span>
        </div>
      ))}
    </div>
  );
};
const Awards = ({ awards }) => {
  if (!awards || awards.length === 0) return null;
  return (
    <div className={styles.awardContainer}>
       <SectionTitle title="CERTIFICATIONS" />

      <ul className={`${styles.points} ${styles.noBullets}`}>
        {awards.map((award, index) => (
          <li key={index} className={styles.awardsli}>
            <div className={styles.awardpoint}>
              <strong>{award.name ? `${award.name} : ` : ""}</strong>
              <span
                className={styles.awarddescription}
                dangerouslySetInnerHTML={{ __html: award.description }}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Optional = ({ optional }) => {
  if (!optional || optional.length === 0) return null;
  return (
    <div>
      {optional.map((item, index) => (
        <>
        <SectionTitle title={item.name} />

          <span
            className={styles.resumesampletextabout}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </>
      ))}
    </div>
  );
};

export default MMLResume;
