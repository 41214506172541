import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import styles from '../../assets/Dashboard/ChooseTemplate.module.css';
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { useJobs } from "../../ResumeBuilder/builder/components/context/Jobs";
import { useNavigate } from "react-router-dom";
import { Eye, Award, Sparkles, Clock, Zap, Star, User, CheckCircle, FileText, Settings, Layout, Brain, Cpu, Layers } from 'lucide-react';
import axios from "axios";
import MyResponsiveNavbar from '../../pages/Navbar';
import { privateApi } from '../../ResumeBuilder/utils/aixos';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.12,
      delayChildren: 0.3
    }
  }
};

const headerVariants = {
  hidden: { 
    opacity: 0,
    y: -50
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20
    }
  }
};

const cardVariants = {
  hidden: { 
    opacity: 0,
    y: 50,
    scale: 0.95
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 100,
      damping: 20,
      duration: 0.6
    }
  },
  hover: {
    y: -12,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 25
    }
  },
  tap: {
    scale: 0.98,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 25
    }
  }
};

// Random data for more engaging notifications
const randomUsers = [
  { name: "Rohan", location: "Mumbai" },
  { name: "Priya", location: "Delhi" },
  { name: "Amit", location: "Bangalore" },
  { name: "Sarah", location: "Pune" },
  { name: "Raj", location: "Chennai" },
  { name: "Neha", location: "Hyderabad" },
  { name: "Arjun", location: "Kolkata" },
  { name: "Zara", location: "Ahmedabad" }
];

const randomActions = [
  "selected",
  "just downloaded",
  "customized",
  "choose",
  "picked"
];

const randomTimes = ["just now", "2 mins ago", "5 mins ago", "moments ago"];

const ChooseTemplate = () => {
  const [hoveredTemplate, setHoveredTemplate] = useState(null); 
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const { templates, handleTemplateChange, getInitialConfigResume, resetResume } = useResume();
  const { setPreviewImage } = useJobs();
  const navigate = useNavigate();

  // Move URL parameter handling into useEffect
  useEffect(() => {
    const domain = new URLSearchParams(window.location.search).get('domain');
    const sector = new URLSearchParams(window.location.search).get('sector');
    // console.log(domain, sector);
    setSelectedExperience(sector);
    setSelectedSector(domain);
  }, []); // Run once on component mount

  useEffect(() => {
    let notificationTimeout;
    let intervalId;

    const showRandomNotification = () => {
      const randomUser = randomUsers[Math.floor(Math.random() * randomUsers.length)];
      const randomTemplate = Object.values(templates)[Math.floor(Math.random() * Object.values(templates).length)];
      const randomAction = randomActions[Math.floor(Math.random() * randomActions.length)];
      const randomTime = randomTimes[Math.floor(Math.random() * randomTimes.length)];

      setNotification({
        user: randomUser.name,
        location: randomUser.location,
        template: randomTemplate?.name,
        action: randomAction,
        time: randomTime
      });

      // Hide notification after 4 seconds
      notificationTimeout = setTimeout(() => {
        setNotification(null);
      }, 4000);
    };

    // Initial delay before first notification
    const initialDelay = setTimeout(() => {
      showRandomNotification();
      
      // Show notifications at random intervals between 15-25 seconds
      intervalId = setInterval(() => {
        const randomInterval = Math.floor(Math.random() * 10000) + 15000; // 15-25 seconds
        setTimeout(showRandomNotification, randomInterval);
      }, 25000);
    }, 1000);

    return () => {
      clearTimeout(initialDelay);
      clearTimeout(notificationTimeout);
      clearInterval(intervalId);
    };
  }, [templates]);

  const insertResumeToDatabase = async (image, name , templateKey) => { 
    try {
      const freshConfig = getInitialConfigResume();
      const  {data} = await privateApi.post("/api/resume/createresume", {
        resume_values: freshConfig,
        template_key: templateKey,
        template_name: name,
        template_image: image,
      });
      const insertId = data?.resumeId;
      resetResume();
      return insertId;
    } catch (error) {
      console.error("Error inserting resume to database:", error);
      throw error;
    }
  };

  const loadingSteps = [
    {
      text: "AI Analyzing Template",
      subText: "Optimizing layout and structure",
      icon: <Brain className={styles.stepIcon} />,
    },
    {
      text: "Processing Resume Sections",
      subText: "Preparing professional components",
      icon: <Layers className={styles.stepIcon} />,
    },
    {
      text: "Initializing Smart Editor",
      subText: "Setting up AI-powered features",
      icon: <Cpu className={styles.stepIcon} />,
    }
  ];

  const navigateToEditor = async (templateKey, preview, name) => {
    try {
      setIsLoading(true);
      await handleTemplateChange(templateKey);
      await getInitialConfigResume();
      resetResume();
      setPreviewImage(preview);
      for (let i = 0; i < loadingSteps.length; i++) {
        setLoadingStep(i);
        await new Promise(resolve => setTimeout(resolve, 1000));
      } 
      const insertId = await insertResumeToDatabase(preview, name , templateKey); 
      console.log("insertId", insertId);
      // Navigate after loading
      setTimeout(() => {
        setIsLoading(false);
        navigate(`/editor/${name}-${insertId}`);
      }, 500);

    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const getTemplateLevel = (template) => {
    if (template.experience.min >= 5) return 'Advanced';
    if (template.experience.min >= 2) return 'Intermediate';
    return 'Entry Level';
  };

  return ( 
    <div> 
        <MyResponsiveNavbar/>
    <motion.div 
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={styles.backgroundDecoration}>
        <motion.div 
          className={`${styles.floatingCircle} ${styles.circle1}`}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 0.1, scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.div 
          className={`${styles.floatingCircle} ${styles.circle2}`}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 0.1, scale: 1 }}
          transition={{ duration: 1, delay: 0.2 }}
        />
        <motion.div 
          className={`${styles.floatingCircle} ${styles.circle3}`}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 0.1, scale: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
        />
        <motion.div 
          className={`${styles.dots} ${styles.dots1}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 1, delay: 0.6 }}
        />
        <motion.div 
          className={`${styles.dots} ${styles.dots2}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 1, delay: 0.8 }}
        />
      </div>

      <motion.div
        className={styles.header}
        variants={headerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div 
          className={styles.headerContent}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <motion.h1
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            Let's pick out a template for you!
          </motion.h1>
          <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            Choose from our professionally designed templates to kickstart your career journey
          </motion.p>
          <motion.div 
            className={styles.headerDecoration}
            animate={{ 
              rotate: [0, 360],
              scale: [1, 1.1, 1]
            }}
            transition={{ 
              duration: 20,
              repeat: Infinity,
              ease: "linear"
            }}
          />
        </motion.div>
      </motion.div>

      <AnimatePresence>
        {notification && (
          <motion.div
            className={styles.notification}
            initial={{ x: 100, opacity: 0, scale: 0.8 }}
            animate={{ x: 0, opacity: 1, scale: 1 }}
            exit={{ x: 100, opacity: 0, scale: 0.8 }}
            transition={{ 
              type: "spring",
              stiffness: 200,
              damping: 20
            }}
          >
            <div className={styles.notificationIcon}>
              <User size={18} className={styles.userIcon} />
              <div className={styles.activeIndicator} />
            </div>
            
            <div className={styles.notificationContent}>
              <div className={styles.notificationHeader}>
                <span className={styles.userName}>{notification.user}</span>
                <span className={styles.userLocation}>
                  from {notification.location}
                </span>
              </div>
              
              <div className={styles.notificationMessage}>
                <span>{notification.action} </span>
                <span className={styles.templateNameNoti}>
                  {notification.template}
                </span>
              </div>
              
              <div className={styles.notificationFooter}>
                <span className={styles.timeAgo}>
                  <Clock size={12} />
                  {notification.time}
                </span>
                <CheckCircle size={14} className={styles.checkIcon} />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isLoading && (
          <motion.div 
            className={styles.loadingModal}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className={styles.loadingContent}
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: "spring", duration: 0.5 }}
            >
              <div className={styles.loadingIcon}>
                <div className={`${styles.loadingCircle} ${styles.outerCircle}`}></div>
                <div className={`${styles.loadingCircle} ${styles.middleCircle}`}></div>
                <div className={`${styles.loadingCircle} ${styles.innerCircle}`}></div>
                <Brain className={styles.aiIcon} size={32} />
              </div>
              
              <h3 className={styles.loadingTitle}>AI Resume Builder</h3>
              <p className={styles.loadingSteps}>
                Creating your professional resume with AI
              </p>
              
              <div className={styles.loadingProgress}>
                <motion.div 
                  className={styles.progressBar}
                  initial={{ width: "0%" }}
                  animate={{ width: `${(loadingStep / 3) * 100}%` }}
                />
              </div>
              
              <div className={styles.loadingStep}>
                {loadingStep === 0 && "Analyzing your profile..."}
                {loadingStep === 1 && "Optimizing template layout..."}
                {loadingStep === 2 && "Applying professional styling..."}
                {loadingStep === 3 && "Finalizing your resume..."}
              </div>

              <div className={styles.loadingDots}>
                <div className={styles.dot}></div>
                <div className={styles.dot}></div>
                <div className={styles.dot}></div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        className={styles.templatesGrid}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {Object.values(templates)
          .map((template) => {
            // Convert experience string to numeric value
            const getExperienceYears = (expString) => {
              switch(expString) {
                case "No Experience":
                  return 0;
                case "Less Than 3 Years":
                  return 1;
                case "3-5 Years":
                  return 3;
                case "5-10 Years":
                  return 5;
                case "10+ Years":
                  return 10;
                default:
                  return 0;
              }
            };

            const experienceYears = getExperienceYears(selectedExperience);
            
            // Match exact sector names from Resume.jsx
            const normalizedSelectedSector = selectedSector?.trim();
            const isRecommended = template.sector.some(sector => 
              sector === normalizedSelectedSector
            ) && 
            experienceYears >= template.experience.min &&
            (!template.experience.max || experienceYears <= template.experience.max);

           

            return {
              ...template,
              isRecommended
            };
          })
          .sort((a, b) => (b.isRecommended ? 1 : 0) - (a.isRecommended ? 1 : 0))
         
          .map((template, index) => (
            <motion.div
              key={template.key}
              variants={cardVariants}
              whileHover="hover"
              whileTap="tap"
              onHoverStart={() => setHoveredTemplate(template.key)}
              onHoverEnd={() => setHoveredTemplate(null)}
              className={`${styles.templateCard} ${template.isRecommended ? styles.recommended : ''}`}
              onClick={() => navigateToEditor(template.key, template.preview, template.name)}
              style={{ cursor: 'pointer' }}
            >
              {template.isRecommended && (
                <motion.div 
                  className={styles.recommendedBadge}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ 
                    type: "spring",
                    stiffness: 200,
                    damping: 20,
                    delay: 0.3 
                  }}
                >
                  <div className={styles.recommendedContent}>
                    <Star size={14} className={styles.starIcon} />
                    <span>Recommended</span>
                  </div>
                  <motion.div 
                    className={styles.recommendedGlow}
                    animate={{ 
                      opacity: [0.5, 1, 0.5],
                      scale: [1, 1.2, 1]
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut"
                    }}
                  />
                </motion.div>
              )}

              <motion.div 
                className={styles.imageContainer}
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.3 }}
              >
                <motion.img 
                  src={template.preview} 
                  alt={template.name}
                  initial={{ scale: 1 }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.4 }}
                />
                <AnimatePresence>
                  {hoveredTemplate === template.key && (
                    <motion.div 
                      className={styles.imageOverlay}
                      initial={{ opacity: 0 }}
                      animate={{ 
                        opacity: 1,
                        transition: { duration: 0.2 }
                      }}
                      exit={{ opacity: 0 }}
                    >
                      <motion.button 
                        className={styles.previewButton}
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ 
                          y: 0, 
                          opacity: 1,
                          transition: { delay: 0.1 }
                        }}
                        whileHover={{ 
                          scale: 1.05,
                          y: -2,
                          transition: { type: "spring", stiffness: 400 }
                        }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Eye size={18} />
                        Use Template
                        <motion.span
                          className={styles.buttonIcon}
                          whileHover={{ rotate: -12, scale: 1.2 }}
                        >
                          <Zap size={16} />
                        </motion.span>
                      </motion.button>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>

              <motion.div 
                className={styles.templateDetails}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <h3 className={styles.templateName}>{template.name}</h3>
                
                <div className={styles.templateInfo}>
                  <motion.div 
                    className={styles.templateTag}
                    whileHover={{ scale: 1.05 }}
                  >
                    <Sparkles size={14} />
                    {getTemplateLevel(template)}
                  </motion.div>
                  <motion.div 
                    className={styles.templateTag}
                    whileHover={{ scale: 1.05 }}
                  >
                    <Clock size={14} />
                    {template.experience.min}+ years
                  </motion.div>
                </div>
              </motion.div>
            </motion.div>
          ))}
      </motion.div>
    </motion.div>
    </div>
  );
};

export default ChooseTemplate;