import { Route, Routes } from "react-router-dom";
import "./App.css";
import mixpanel from "mixpanel-browser";
import Welcome from "./components/Welcome";
import Login from "./components/Login";
import PrivateRoute from "./Routes/PrivateRoute";
import AdminRoute from "./Routes/AdminRoute";
import SingUp from "./components/Signup";
import ForgotPassword from "./components/ForgotPassword";
import Verify from "./components/pages/Verify_otp";
import Blog2 from "./components/pages/Blog";
import NotFound from "./components/pages/NotFound";
import Jobs from "./components/pages/Jobs";
import TemplatePreview from "./components/pages/TemplatePreview";
import Account from "./components/pages/Account";
import ChoseTemplate from "./components/pages/ChoseTemplate";
import JobRecomandation from "./components/pages/JobRecomandation";
import Home from "./components/pages/Home";
import Tnc from "./components/pages/Tnc";
import Privacy from "./components/pages/Privacy";
import ForgotVerify from "./components/pages/ForgotVerify";
import Jobs2 from "./components/pages/Jobs2";
import Refundandpolidcy from "./components/pages/Refundandpolidcy";
import Contactus from "./components/pages/Contactus";
import AboutUs from "./components/pages/AboutUs";
import TestFor from "./components/pages/TestFor";
import Success from "./components/pages/Payment/Success";
import Fail from "./components/pages/Payment/Fail";
import AdminDashboard from "./components/pages/Admin/AdminDashboard";
import Blog from "./components/pages/Admin/Blog";
import SingleBlog from "./components/pages/SingleBlog";
import Sitemap from "./components/pages/Sitemap";
import ResumeBuilder from "./components/pages/Admin/ResumeBuilder"; 
import ResumeBuilderUser from "./components/ResumeBuilder/builder/ResumeBuilder.jsx"
import { useAuth } from "./components/ResumeBuilder/builder/components/context/auth";
import { useEffect } from "react";
import { useJobs } from "./components/ResumeBuilder/builder/components/context/Jobs";
import Pricing from "./components/pages/Pricing";
import Test2 from "./components/pages/Test2";
import MyfuseScore from "./components/pages/MyfuseScore.js";
import DashBoardNew from "./components/pages/DashBoardNew.js";
import ResumeScore from "./components/pages/ResumeScore.js";
import DataSharing from "./components/pages/DataSharing/DataSharing.js";
import AccountNew from "./components/pages/AccountNew.js";
import { privateApi } from "./components/ResumeBuilder/utils/aixos.js";
import ChooseTemplate from "./components/components/DashBoard.js/ChooseTemplate.js";
// Route configurations
const privateRoutes = [
  { path: "/editor/:key?", element: <ResumeBuilderUser /> },
  { path: "/welcome", element: <DashBoardNew /> },
  { path: "/account", element: <Account /> },
  { path: "/accountnew", element: <AccountNew /> },
  { path: "/choose-template/:domain?", element: <ChooseTemplate /> }
];

const adminRoutes = [
  { path: "/admin/dashboard", element: <AdminDashboard /> },
  { path: "/admin/Blog", element: <Blog /> },
  { path: "/admin/Resume", element: <ResumeBuilder /> }
];

const publicRoutes = [
  { path: "/", element: <Home /> },
  { path: "/airesume", element: <Welcome /> },
  { path: "/pricing", element: <Pricing /> },
  { path: "/myfusescore", element: <MyfuseScore /> },
  { path: "/preview", element: <TemplatePreview /> },
  { path: "/cancellationandrefundpolicy", element: <Refundandpolidcy /> },
  { path: "/blogs", element: <Blog2 /> },
  { path: "/blogs/:slug", element: <SingleBlog /> },
  { path: "/resume-examples", element: <ChoseTemplate /> },
  { path: "/aboutus", element: <AboutUs /> },
  { path: "/contactus", element: <Contactus /> },
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <SingUp /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/verify/:email", element: <Verify /> },
  { path: "/forgot-verify/:email", element: <ForgotVerify /> },
  { path: "/jobs-in-india/*", element: <Jobs /> },
  { path: "/sitemap", element: <Sitemap /> },
  { path: "/term-and-conditions", element: <Tnc /> },
  { path: "/privacy-policy", element: <Privacy /> },
  { path: "/jobs/details/:slug", element: <Jobs2 /> },
  { path: "/success", element: <Success /> },
  { path: "/fail", element: <Fail /> },
  { path: "/resume-score", element: <ResumeScore /> },
  { path: "/cheatsheet/:slug", element: <DataSharing /> },
  { path: "/:jobsInLocation/*", element: <Jobs /> },
  { path: "/test2", element: <Test2 /> }, 
  { path: "*", element: <NotFound /> },
   
];

function App() {
  const { auth } = useAuth();
  let { setPayment } = useJobs();
  useEffect(() => {
    if (auth?.token !== "") {
      mixpanel.identify(auth?.user[0].id_user);
      mixpanel.people.set({
        $name: auth?.user[0].firstname,
        $email: auth?.user[0].email,
      });
      mixpanel.track("Page Loaded");
    }
  }, [auth]);

  const datalocal = JSON.parse(localStorage.getItem("auth"));
  const getData = async () => {
    try {
      const response = await privateApi.post(`/api/checkprimium`, {});
      setPayment({
        paymentStart: response?.data?.data?.date_joined || "",
        paymentEnd: response?.data?.data?.date_end || "",
        paymentStatus: response?.data?.data?.status || "",
        paymentAmount: response?.data?.data?.amount || "",
        produceName: response?.data?.data?.product_name || "",
      });
      
    } catch (error) {
      // Handle different types of errors appropriately
      if (error.response) {
        // Server responded with error status (404, 500, etc)
        if (error.response.status === 404) {
          // Handle no payment record found
          setPayment({
            paymentStart: "",
            paymentEnd: "",
            paymentStatus: "",
            paymentAmount: "",
            produceName: "",
          });
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
      } else {
        // Error in request setup
        console.error("Error setting up request:", error.message);
      }
    }
  };

  useEffect(() => {
    if (datalocal?.token) {
      getData();
    }
  }, [auth]);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        {privateRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Route>
      
      <Route element={<AdminRoute />}>
        {adminRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Route>

      {publicRoutes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
    </Routes>
  );
}

export default App;
