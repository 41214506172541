import React, { useState } from "react";
import styles from "../../assets/Dashboard/HeadCont.module.css";
import { useNavigate } from "react-router-dom";
import { useJobs } from "../../ResumeBuilder/builder/components/context/Jobs";
const HeadCont = ({auth , modalShow , setModalShow , SecondmodalShow , setSecondModalShow}) => {
  const navigate = useNavigate(); 
  const { setActiveResume} = useJobs();

  const handleNavigateAtsAnalysis = (name) => {
    setActiveResume(name)
    if(name === 'ATS'){
      setActiveResume(3)
    } else if(name === 'Analysis'){
      setActiveResume(2)
    }
    else if(name === 'Jobs'){
      setActiveResume(5)
    }
    navigate('/editor')
  }



  return (
    <div className={styles.main}>
      <div className={styles.mainContianer}>
        <div className={styles.headingContianer}>
          <div className={styles.head}>Hello {auth?.user?.[0]?.firstname}  !</div>
          <div className={styles.dhead}>
            Let’s help you find your next best opportunity{" "}
          </div>
        </div>
        <div className={styles.rightside}  onClick={() => setModalShow(true)}>
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729251085/all/bjdnesnqtnvh3nnqy5dz.svg" />
        </div>
      </div> 
      <div className={styles.eachContainerMainContainer}>
      <div className={styles.eachcont}>
        <div className={styles.eachimageContainer}>
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729251588/all/yzhotd5rlcg2lmb9akne.svg" />
        </div>
        <div className={styles.beloweachImageContainer}>
          <div className={styles.numberContainer}  onClick={()=>navigate('/welcome/account')}>
            <div className={styles.numberContainerleft}>
              <div className={styles.leftround}>60%</div>
            </div>
            <div className={styles.numberContainerright}>
              <div className={styles.title}>Profile Completed</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.eachcont}>
        <div className={styles.eachimageContainer}>
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729252768/all/vveycedsyyiinrhh3taf.svg" />
        </div>
        <div className={styles.beloweachImageContainer}>
          <div className={styles.numberContainer}  onClick={()=>handleNavigateAtsAnalysis('Jobs')}>
            <div className={styles.numberContainerleft}>
              <div className={styles.leftround}>07</div>
            </div>
            <div className={styles.numberContainerright}>
              <div className={styles.title}>Jobs Matched</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.eachcont}>
        <div className={styles.eachimageContainer}>
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729253034/all/djip5jw0tk6hlvtoqdmc.svg" />
        </div>
        <div className={styles.beloweachImageContainer}>
          <div className={styles.numberContainer}  onClick={()=>handleNavigateAtsAnalysis('Analysis')}>
            <div className={styles.numberContainerleft}>
              <div className={styles.leftround}>64%</div>
            </div>
            <div className={styles.numberContainerright}>
              <div className={styles.title}>Resume 
              Score</div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.eachcont}>
        <div className={styles.eachimageContainerspecial}>
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729253676/all/jjtfmtzjjcgqrdyetv3e.svg" />
        </div>
        <div className={styles.beloweachImageContainerspecial}>
          <div className={styles.ContentContainer}>
            <div className={styles.ContentContainerUp}>
              <div className={styles.contenthead}>Check ATS Score before applying</div>
            </div>
            <div className={styles.ContentContainerDown} onClick={()=>handleNavigateAtsAnalysis('ATS')}>
              <div className={styles.buttonCheck}>Check Now</div>
            </div>
          </div>
        </div>
      </div>
      </div>
       
    </div>
  );
};

export default HeadCont;
