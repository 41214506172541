import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetConfig = ({ title, description, keywords, canonical}) => (
    <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link rel="canonical" href={canonical} />
      {/* Add Open Graph meta tags for social media */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonical} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://lh3.googleusercontent.com/p/AF1QipPGx5_qBb6a3bGRrRlOjKlcbgAThPlmF1MYVHJD=s1360-w1360-h1020" />
      <meta property="og:site_name" content="MyFuse" />

      {/* Add Twitter Card meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content="https://lh3.googleusercontent.com/p/AF1QipPGx5_qBb6a3bGRrRlOjKlcbgAThPlmF1MYVHJD=s1360-w1360-h1020" />

      {/* Add WhatsApp specific meta tag */}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />


    </Helmet>
);

export default HelmetConfig;
