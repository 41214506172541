import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../assets/Filter.module.css";
import { ChevronUp } from "lucide-react";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { publicApi } from "../ResumeBuilder/utils/aixos";

const Filter = ({ setJobs, jobs, pageNumber, joblocation, isLoading , setIsLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loadingJobs, setLoadingJobs] = useState(false);
  const [openSection, setOpenSection] = useState({
    jobMode: true,
    jobType: true,
    location: true,
    industry: true
  });

  const getFilterFromURL = (key) => {
    const params = new URLSearchParams(location.search);
    return params.get(key) ? params.get(key).split(",") : [];
  };

  const handleCheckboxChange = (e, category) => {
    const { name, checked } = e.target;
    const params = new URLSearchParams(window.location.search);
    let currentFilters = params.get(category)
      ? params.get(category).split(",")
      : [];
    if (checked) {
      currentFilters.push(name);
    } else {
      currentFilters = currentFilters.filter((filter) => filter !== name);
    }
    if (currentFilters.length > 0) {
      params.set(category, currentFilters.join(","));
    } else {
      params.delete(category);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl); 
  };

  const getFilterJobs = async () => {
    try {
      setJobs([]);
      setLoadingJobs(true);
      setIsLoading(true);
      let filters = {
        jobType: getFilterFromURL("jobType"),
        location: getFilterFromURL("location"),
        industry: getFilterFromURL("industry"),
        position: getFilterFromURL("position"),
        jobMode: getFilterFromURL("jobMode"),
      }; 

      if (joblocation) {
        filters = {
          ...filters,
          location: Array.isArray(filters.location) 
            ? [...filters.location, joblocation]  
            : [filters.location, joblocation].filter(Boolean), 
        };
      }
      
      const response = await publicApi.post(`/api/auth/filterJobPost?page=${pageNumber}`,filters);

      const data = response.data;
      if (data?.success) {
        setJobs(data.users);
      }
        setLoadingJobs(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoadingJobs(false);
      setIsLoading(false);
    }
  };
  useEffect(() => { 
    getFilterJobs();
  }, [location.search, pageNumber,joblocation]);

  const toggleSection = (section) => {
    setOpenSection(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <div className={`col-md-3 mb-4 col-11 d-none d-lg-block`}>
      <motion.div 
        className={styles.mains}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        {/* Filter Header */}
        <motion.div className={styles.filterHeader}>
          <span className={styles.dasdf}>
            <motion.div
              animate={{ rotate: [0, 180, 360] }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
              style={{ display: "inline-block", marginRight: "8px" }}
            >
              <FontAwesomeIcon icon={faFilter} className={styles.ci} />
            </motion.div>
            Filters
          </span>
        </motion.div>

        <motion.div className={styles.pro} />

        {/* Job Mode Section */}
        <motion.div layout>
          <motion.div 
            className={styles.dlsr}
            onClick={() => toggleSection('jobMode')}
            whileHover={{ backgroundColor: "#f8f9fa" }}
          >
            <span>Experience Level</span>
            <motion.span
              animate={{ rotate: openSection.jobMode ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <ChevronUp />
            </motion.span>
          </motion.div>

          <AnimatePresence>
            {openSection.jobMode && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                {["Part Time", "Full Time", "Internship"].map((mode, index) => (
                  <motion.div 
                    className={styles.fr}
                    key={mode}
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ x: 5, transition: { duration: 0.2 } }}
                  >
                    <input
                      type="checkbox"
                      id={mode}
                      name={mode}
                      checked={getFilterFromURL("jobMode").includes(mode)}
                      onChange={(e) => handleCheckboxChange(e, "jobMode")}
                      className={styles.checkbox}
                    />
                    <label htmlFor={mode} className={styles.marginle}>
                      {mode}
                    </label>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div className={styles.pro} />

        {/* Experience Level Section */}
        <motion.div layout>
          <motion.div 
            className={styles.dlsr}
            onClick={() => toggleSection('jobType')}
            whileHover={{ backgroundColor: "#f8f9fa" }}
          >
            <span>Experience Level</span>
            <motion.span
              animate={{ rotate: openSection.jobType ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <ChevronUp />
            </motion.span>
          </motion.div>

          <AnimatePresence>
            {openSection.jobType && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                {["Freshers", "Experience"].map((type, index) => (
                  <motion.div 
                    className={styles.fr}
                    key={type}
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ x: 5, transition: { duration: 0.2 } }}
                  >
                    <input
                      type="checkbox"
                      id={type}
                      name={type}
                      checked={getFilterFromURL("jobType").includes(type)}
                      onChange={(e) => handleCheckboxChange(e, "jobType")}
                      className={styles.checkbox}
                    />
                    <label htmlFor={type} className={styles.marginle}>
                      {type}
                    </label>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div className={styles.pro} />

        {/* Location Filter */}
        <motion.div layout>
          <motion.div 
            className={styles.dlsr}
            onClick={() => toggleSection('location')}
            whileHover={{ backgroundColor: "#f8f9fa" }}
          >
            <span>Location</span>
            <motion.span
              animate={{ rotate: openSection.location ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <ChevronUp />
            </motion.span>
          </motion.div>

          <AnimatePresence>
            {openSection.location && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                {["Bangalore", "Pune", "Delhi", "Mumbai", "Lucknow", "Gurugram"].map((location, index) => (
                  <motion.div 
                    className={styles.fr}
                    key={location}
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ x: 5, transition: { duration: 0.2 } }}
                  >
                    <input
                      type="checkbox"
                      id={location}
                      name={location}
                      checked={getFilterFromURL("location").includes(location)}
                      onChange={(e) => handleCheckboxChange(e, "location")}
                      className={styles.checkbox}
                    />
                    <label htmlFor={location} className={styles.marginle}>
                      {location}
                    </label>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div className={styles.pro} />

        {/* Industry Filter */}
        <motion.div layout>
          <motion.div 
            className={styles.dlsr}
            onClick={() => toggleSection('industry')}
            whileHover={{ backgroundColor: "#f8f9fa" }}
          >
            <span>Industry</span>
            <motion.span
              animate={{ rotate: openSection.industry ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <ChevronUp />
            </motion.span>
          </motion.div>

          <AnimatePresence>
            {openSection.industry && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                {[
                  "Technology and IT",
                  "Education",
                  "Transportation And Logistics",
                  "Travel And Tourism",
                  "Health Care",
                  "Retail and Consumer",
                ].map((industry, index) => (
                  <motion.div 
                    className={styles.fr}
                    key={industry}
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ x: 5, transition: { duration: 0.2 } }}
                  >
                    <input
                      type="checkbox"
                      id={industry}
                      name={industry}
                      checked={getFilterFromURL("industry").includes(industry)}
                      onChange={(e) => handleCheckboxChange(e, "industry")}
                      className={styles.checkbox}
                    />
                    <label htmlFor={industry} className={styles.marginle}>
                      {industry}
                    </label>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div className={styles.pro} />
      </motion.div>
    </div>
  );
};

export default Filter;
