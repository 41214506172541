import React, {useEffect } from "react";

// Build
import IITBResumeBuild from "../templates/IITB/IITBResumeBuild";
import IITGResumeBuild from "../templates/IITG/IITGResumeBuild";
import IITEResumeBuild from "../templates/IITE/IITEResumeBuild";
import MITResumeBuild from "../templates/MIT/MITResumeBuild";
import LLKResumeBuild from "../templates/LLK/LLKResumeBuild";
import LLLResumeBuild from "../templates/LLL/LLLResumeBuild";
import LLNResumeBuild from "../templates/LLN/LLNResumeBuild";
import LLOResumeBuild from "../templates/LLO/LLOResumeBuild";
import LLPResumeBuild from "../templates/LLP/LLPResumeBuild";
import LLMResumeBuild from "../templates/LLM/LLMResumeBuild";
import LLSResumeBuild from "../templates/LLS/LLSResumeBuild";
import MMLResumeBuild from "../templates/MML/MMLResumeBuild";
import LLQResumeBuild from "../templates/LLQ/LLQResumeBuild";
import LLRResumeBuild from "../templates/LLR/LLRResumeBuild";
import IITKResumeBuild from "../templates/IITK/IITKResumeBuild";
import LLTResumeBuild from "../templates/LLT/LLTResumeBuild";


//Resum
import IITBResume from "../templates/IITB/IITBResume";
import IITGResume from "../templates/IITG/IITGResume";
import IITEResume from "../templates/IITE/IITEResume";
import MITResume from "../templates/MIT/MITResume";
import LLKResume from "../templates/LLK/LLKResume";
import LLLResume from "../templates/LLL/LLLResume";
import LLOResume from "../templates/LLO/LLOResume";
import LLNResume from "../templates/LLN/LLNResume";
import LLPResume from "../templates/LLP/LLPResume";
import LLMResume from "../templates/LLM/LLMResume";
import MMLResume from "../templates/MML/MMLResume";
import LLQResume from "../templates/LLQ/LLQResume";
import LLRResume from "../templates/LLR/LLRResume";
import LLSResume from "../templates/LLS/LLSResume";
import IITKResume from "../templates/IITK/IITKResume";
import LLTResume from "../templates/LLT/LLTResume";


// Preview Image 
import IITBPreviewImage from "../../../assets/images/temp2.png";
import IITEPreviewImage from "../../../assets/images/latest.png";
import IITLLPreviewImage from "../../../assets/images/iitll.png";
import MITPreviewImage from "../../../assets/images/NEW.png";
import LLKPreviewImage from "../../../assets/images/Fresher.png";
import LLLPreviewImage from "../../../assets/images/exp.png";
import tr7PreviewImage from "../../../assets/images/rt7.png";
import tr8PreviewImage from "../../../assets/images/rt8.png";
import tr9PreviewImage from "../../../assets/images/rt9.png";
import tr10PreviewImage from "../../../assets/images/rt10.png";
import LLPPreviewImage from "../../../assets/images/rt11.png";
import LLQPreviewImage from "../../../assets/images/rt12.png";
import LLRPreviewImage from "../../../assets/images/rt13.png";
import LLSPreviewImage from "../../../assets/images/rt14.png";
import IITKPreviewImage from "../../../assets/images/rt14.png";
import LLTPreviewImage from "../../../assets/images/rt15.png";



const ResumeContext = React.createContext();

export const useResume = () => React.useContext(ResumeContext);

const getInitialConfigResume = () => ({
  name: "Untitled",
  sections: {
    profile: {
      name: "Profile",
      key: "sections.profile",
      data: {},
    },
    education: {
      name: "Education",
      key: "sections.education",
      data: [],
    },
    experience: {
      name: "Experience",
      key: "sections.experience",
      data: [],
    },
    projects: {
      name: "Projects",
      key: "sections.projects",
      data: [],
    },
    technicalSkills: {
      name: "Technical Skills",
      key: "sections.technicalSkills",
      data: [],
    },
    Professionalsummary: {
      name: "Professional Summary",
      key: "sections.Professionalsummary",
      data: {},
    },
    awards: {
      name: "Awards",
      key: "sections.awards",
      data: [],
    },
    optional: {
      name: "Optional",
      key: "sections.optional",
      data: [],
    },
  },
});

const ResumeProvider = ({ children }) => {
  const templates = {
    llp: {
      name: "Executive Standard",
      key: "llp", 
      build: LLPResumeBuild,
      resume: LLPResume,
      page_margins: "0.0in",
      preview: LLPPreviewImage,
      sector: ["Business Management", "Finance"],
      experience: {
        min: 5,
        max: null,
      },
    },
    
    iite: {
      name: "Fresher's Edge",
      key: "iite",
      build: IITEResumeBuild,
      resume: IITEResume,
      page_margins: "0.5in",
      preview: IITEPreviewImage, 
      sector: ["IT", "Data Science and Analytics"],
      experience: {
        min: 0,
        max: 1,
      },

    },
   
    lln: {
      name: "Corporate Pro",
      key: "lln",
      build: LLNResumeBuild,
      resume: LLNResume,
      page_margins: "0.0in",
      preview: tr9PreviewImage,
      sector: ["Accounts", "Marketing"],
      experience: {
        min: 2,
        max: 5,
      },
    },
    mit: {
      name: "Detail-Oriented",
      key: "mit",
      build: MITResumeBuild,
      resume: MITResume,
      page_margins: "0.5in",
      preview: MITPreviewImage,
      sector: ["Sales and Development", "Finance"],
      experience: {
        min: 3,
        max: 5,
      },
    },
    // from here make font size 13px headding 15 px
    llo: {
      name: "Bold Approach",
      key: "llo",
      build: LLOResumeBuild,
      resume: LLOResume,
      page_margins: "0.0in",
      preview: tr10PreviewImage,
      sector: ["Design and UX", "Marketing"],
      experience: {
        min: 2,
        max: 5,
      },
    },
    lll: {
      name: "Skills First",
      key: "lll",
      build: LLLResumeBuild,
      resume: LLLResume,
      page_margins: "0.5in",
      preview: LLLPreviewImage, 
      sector: ["IT", "Data Science and Analytics"],
      experience: {
        min: 1,
        max: 3,
      },
    },
    llm: {
      name: "Classic Professional",
      key: "llm",
      build: LLMResumeBuild,
      resume: LLMResume,
      page_margins: "0.5in",
      preview: tr7PreviewImage,
      sector: ["Human Resource", "Business Management"],
      experience: {
        min: 5,
        max: null,
      },
    },
    mml: {
      name: "Balanced Generalist",
      key: "mml",
      build: MMLResumeBuild,
      resume: MMLResume,
      page_margins: "0.0in",
      preview: tr8PreviewImage, 
      sector: ["Human Resource", "Business Management" , "Accounts" , "Marketing" , "Sales and Development" , "Data Science and Analytics" , "Design and UX" , "IT" , "Finance" ],
      experience: {
        min: 0,
        max: 5,
      },
    },
    iitg: {
      name: "Tech Innovator",
      key: "iitg",
      build: IITGResumeBuild,
      resume: IITGResume,
      page_margins: "0.5in",
      preview: IITLLPreviewImage,
      sector: ["IT", "Data Science and Analytics"],
      experience: {
        min: 0,
        max: 3,
      },
    },
    llk: {
      name: "Project Achiever",
      key: "llk",
      build: LLKResumeBuild,
      resume: LLKResume,
      page_margins: "0.5in",
      preview: LLKPreviewImage,
      sector: ["Sales and Development", "IT"],
      experience: {
        min: 0,
        max: 2,
      },
    },
   
    iitb: {
      name: "Comprehensive Profile",
      key: "iitb",
      build: IITBResumeBuild,
      resume: IITBResume,
      page_margins: "0.5in",
      preview: IITBPreviewImage,
      sector: ["Design and UX", "Marketing"],
      experience: {
        min: 0,
        max: 2,
      },
    },
    llq: {
      name: "Professional All-Rounder",
      key: "llq",
      build: LLQResumeBuild,
      resume: LLQResume,
      page_margins: "0.0in",
      preview: LLQPreviewImage,
      sector: ["Human Resource", "Business Management"],
      experience: {
        min: 2,
        max: 5,
      },
    },
    llr: {
      name: "Account Specialist",
      key: "llr",
      build: LLRResumeBuild,
      resume: LLRResume,
      page_margins: "0.0in",
      preview: LLRPreviewImage, 
      sector: ["Accounts"],
      experience: {
        min: 2,
        max: 4,
      },
    },
    lls: {
      name: "Marketing Maven",
      key: "lls",
      build: LLSResumeBuild,
      resume: LLSResume,
      page_margins: "0.0in",
      preview: LLSPreviewImage,
      sector: ["Marketing"],
      experience: {
        min: 4,
        max: 6,
      },
    }, 
    llt: {
      name: "Generalist Pro",
      key: "llt",
      build: LLTResumeBuild,
      resume: LLTResume,
      page_margins: "0.0in",
      preview: LLTPreviewImage,
      sector: ["Human Resource", "Business Management"],
      experience: {
        min: 2,
        max: 5,
      },
    },
  };

  const [selectedTemplate, setSelectedTemplate] = React.useState(templates.lll);
  const [values, setValues] = React.useState(getInitialConfigResume());
  const [loading, setLoading] = React.useState(false);
  const [index, setIndex] = React.useState("");
  const [CurentSection, SetCurentSection] = React.useState("Personal Details");
  const [sectionsArray, setSectionsArray] = React.useState([]);
  const [nextSection, setNextSection] = React.useState("");
  const [prevSection, setPrevSection] = React.useState("");
  const [currentStep, setCurrentStep] = React.useState({ current: 1, total: 0 }); 
  const printContainerRef = React.useRef(null);
  const [currentJobRole, setCurrentJobRole] = React.useState("");
  const [ai, setAi] = React.useState("");
  const [visitedSections, setVisitedSections] = React.useState(new Set());

 

  const handleResumeChange = (field, value) => {
    setLoading(true); 
    setValues((prev) => {
      let temp = prev;
      const navigate = field.split(".");
      navigate.forEach((key, index) => {
        if (index === navigate.length - 1) {
          if (Number.isInteger(parseInt(key))) {
            console.log(parseInt(key));
            temp[parseInt(key)] = value;
          } else {
            temp[key] = value;
          }
        } else {
          if (Number.isInteger(parseInt(key))) {
            temp = temp[parseInt(key)];
          } else {
            temp = temp[key];
          }
        }
      });
      return { ...prev };
    });
    setLoading(false);
  };

  const get = (field) => { 
    const navigate = field.split(".");
    let temp = values;
    navigate.forEach((key) => {
      if (!temp) return null;
      if (Number.isInteger(parseInt(key))) {
        temp = temp[parseInt(key)];
      } else {
        temp = temp[key];
      }
    });
    return temp;
  };

  const handleTemplateChange = (templateKey) => {
    if (!templateKey) setSelectedTemplate(null);
    else {
      setSelectedTemplate(templates[templateKey]);
      setCurrentStep(prev => ({ ...prev, current: 1 }));
    }
  };

  const handleSectionChange = (key) => { 
    const currentIndex = sectionsArray.indexOf(CurentSection);
    
    if (key === "next" && currentIndex < sectionsArray.length - 1) {
      const nextSectionName = sectionsArray[currentIndex + 1];
      
      let sectionKey;
      if (nextSectionName === 'Skills') {
        sectionKey = 'technicalSkills';
      } else if (nextSectionName === 'Certification') {
        sectionKey = 'awards';
      }   else {
        sectionKey = Object.keys(values.sections).find(
          key => values.sections[key].name === nextSectionName
        );
      }

      // Only initialize if section doesn't exist or has no data
      if (sectionKey && !visitedSections.has(sectionKey) && 
          (!values.sections[sectionKey]?.data || values.sections[sectionKey].data.length === 0)) {
        const newElement = {};
        handleResumeChange(`sections.${sectionKey}.data`, [newElement]);
      }
      setVisitedSections(prev => new Set([...prev, sectionKey]));

      SetCurentSection(nextSectionName);
      setCurrentStep({ ...currentStep, current: currentStep.current + 1 });
      
    } else if (key === "prev" && currentIndex > 0) {
      const prevSectionName = sectionsArray[currentIndex - 1];
      
      let sectionKey;
      if (prevSectionName === 'Skills') {
        sectionKey = 'technicalSkills';
      } else if (prevSectionName === 'Certification') {
        sectionKey = 'awards';
      } else {
        sectionKey = Object.keys(values.sections).find(
          key => values.sections[key].name === prevSectionName
        );
      }

      // Only initialize if section doesn't exist or has no data
      if (sectionKey && !visitedSections.has(sectionKey) && 
          (!values.sections[sectionKey]?.data || values.sections[sectionKey].data.length === 0)) {
        const newElement = {};
        handleResumeChange(`sections.${sectionKey}.data`, [newElement]);
      }
      setVisitedSections(prev => new Set([...prev, sectionKey]));

      SetCurentSection(prevSectionName);
      setCurrentStep({ ...currentStep, current: currentStep.current - 1 });
    }
  };

  const AddSectionToArray = () => {
    const config = selectedTemplate?.build
      ? selectedTemplate.build()?.props?.config
      : {};
    const sections = config.sections || [];
    const newSectionsArray = sections.map((section) => section.name);
    setSectionsArray(newSectionsArray);
    if (newSectionsArray.length > 0) {
      SetCurentSection(newSectionsArray[0]);
    }
  };

   

  useEffect(() => {
    AddSectionToArray();
  }, [selectedTemplate]); 

  useEffect(() => {
    const currentIndex = sectionsArray.indexOf(CurentSection);
    setCurrentStep((prev) => ({ ...prev, total: sectionsArray.length }));
    setNextSection(
      currentIndex < sectionsArray.length - 1
        ? sectionsArray[currentIndex + 1]
        : ""
    );
    setPrevSection(currentIndex > 0 ? sectionsArray[currentIndex - 1] : "");
  }, [CurentSection, sectionsArray]);

  const resetResume = () => {
    const freshConfig = getInitialConfigResume();
    setValues(freshConfig);
    setVisitedSections(new Set());
  };

  const value = {
    values,
    setValues,
    handleResumeChange,
    get,
    loading,
    setLoading,
    templates,
    selectedTemplate,
    handleTemplateChange,
    printContainerRef,
    setAi,
    ai,
    index,
    setIndex,
    CurentSection,
    handleSectionChange,
    prevSection,
    nextSection,
    currentStep,
    resetResume,
    getInitialConfigResume,
    currentJobRole,
    setCurrentJobRole,
  };

  return (
    <ResumeContext.Provider value={value}>{children}</ResumeContext.Provider>
  );
};

export default ResumeProvider;
