import React, { useEffect, useState } from "react";
import MyResponsiveNavbar from "./Navbar";
import Footer from "./Footer";
import styles from "../assets/Blog/BlogPublic.module.css";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import axios from "axios";
import HelmetConfig from "../components/HelmetConfig";
import BottomBar from "../components/BottomBar";

const Blog = () => {
  const navigate = useNavigate();
  // let array = [];
  const [blogs, setBlogs] = useState([]);
  const getAllBlog = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/Blogs`,
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        }
      );

      setBlogs(data?.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBlog();
  }, []);
  const handleMove = async (value, heading) => {
    const slug = `${slugify(heading)}-${value}`;
    navigate(`/blogs/${slug}`);
  };

  return (
    <>
      <HelmetConfig 
           title="MyFuse Blog | Job Search Tips, Resume Building, and Career Advice"
           description="Stay informed with the MyFuse Blog. Explore articles on job search tips, resume building, industry trends, and career advice to help you succeed in your professional journey."
           keywords="job portal, job search, careers, employment, jobs, MyFuse.in, job opportunities"
           canonical="https://myfuse.in/blog"
        />
      <MyResponsiveNavbar />
      <div className={styles.main}>
        <div className={styles.higligtingContainer}>
          {blogs.slice(0, 1).map((ele, index) => {
            return (
              <div key={index} className={`relative ${styles.contianerSingle}`}>
                <div
                  className={`d-flex justify-content-center relative ${styles.imagecontainer}`}
                  style={{
                    backgroundImage: `url(${ele.img_link})`,
                  }}
                >
                </div>
                <div className={styles.goro}>
                  <div className={styles.dioro}>
                    <h2
                      className={styles.headingsre}
                      onClick={() => handleMove(ele.S_No, ele.blog_heading)}
                    >
                      {ele.blog_heading}
                    </h2>
                    <p className={styles.shortre}>{ele.short_desc}</p>
                  </div>

                  <div className={styles.ineoro}>
                    <div className={styles.prohfi}>
                      <div>
                        <img
                          src={
                            ele.user_pic
                              ? ele.user_pic
                              : "https://secure.gravatar.com/avatar/6cd5b85af31e4cf59961198f55ff4098?s=128&d=mm&r=g"
                          }
                          className={styles.myimages}
                          alt="ss"
                        />
                      </div>
                      <div>
                        <p className={styles.name}>
                            MyFuse.in
                        </p>
                        <p className={styles.time}>
                          {new Date(ele.time_stamp).toLocaleString()}
                        </p>
                      </div>
                    </div>
                    <div className={styles.trscontianer}>
                      <p className={styles.trs}>{ele.TR} mins to read</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* // blog section  */}
        <div className={styles.recentblog}>
          {/* <h1 className={styles.tayos}>Recent Blogs</h1> */}
          <div className={styles.mainContinaer}>
            {blogs.map((ele, index) => {
              return (
                <div className={` ${styles.contianer}`}>
                  <div className={`d-flex justify-content-center  ${styles.dfosr}`} 
                   style={{
                    backgroundImage: `url(${ele.img_link})`,
                     
                  }}>
                     
                    <br></br>
                  </div>
                  <div className={styles.tagcont}>
                    {ele.blog_tag.split(",").map((individualTag, index) => (
                      <span key={index} className={styles.tagItem}>
                        {individualTag.trim()}
                      </span>
                    ))}
                  </div>
                  <div className={styles.dioro}>
                    <h2
                      className={styles.headings}
                      onClick={() => handleMove(ele.S_No, ele.blog_heading)}
                    >
                      {ele.blog_heading}
                    </h2>
                    <p className={styles.short}> {ele.short_desc}</p>
                  </div>

                  <div className={styles.ineoro}>
                    <div className={styles.prohfi}>
                      <div>
                        <img
                           src= "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiMxOKqtdSW8ybpkCH_mVD7dmsIv5z03gaHg&s"
                          className={styles.myimages}
                          alt="ss"
                        />
                      </div>
                      <div>
                        <p className={styles.name}>
                           MyFuse.In
                        </p>
                        <p className={styles.time}>
                          {new Date(ele.time_stamp).toLocaleString()}{" "}
                        </p>
                      </div>
                    </div>
                    <div className={styles.trscontianer}>
                      <p className={styles.trs}>{ele.TR} mins to read</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
  <BottomBar />
      <Footer />
    </>
  );
};

export default Blog;
