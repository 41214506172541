import React, { useEffect, useRef, useState } from "react";
import styles from "./Preview.module.css";
import { useResume } from "../context/Resume";
import { htmlToCanvasImage } from "../../../utils/html_to_canvas_image";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import RiseLoader from "react-spinners/RiseLoader";
import { CircleCheck, ToggleLeft, ToggleRight } from "lucide-react";
import { useJobs } from "../context/Jobs";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import Model from "../../../../components/Model";
import { motion } from "framer-motion";
import { jsPDF } from "jspdf";
import { AlertTriangle } from "lucide-react";
import { useAuth } from "../context/auth";

const PreviewLoader = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="d-flex justify-content-center align-items-center flex-column"
    style={{
      minHeight: "842px",
      width: "100%",
      background: "#ffffff",
      padding: "2rem",
      borderRadius: "12px",
      boxShadow: "0 8px 24px rgba(0, 0, 0, 0.06)",
      position: "relative",
    }}
  >
    <motion.div
      className="text-center"
      style={{
        maxWidth: "400px",
        padding: "2rem",
        background: "#f8fafc",
        borderRadius: "12px",
        boxShadow: "0 4px 16px rgba(0, 0, 0, 0.03)",
      }}
    >
      {/* Logo */}
      <motion.div
        style={{
          width: "48px",
          height: "48px",
          borderRadius: "12px",
          background: "#4F46E5",
          margin: "0 auto 1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#ffffff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
        </svg>
      </motion.div>

      {/* Text Content */}
      <h2
        style={{
          fontSize: "20px",
          fontWeight: "600",
          color: "#1f2937",
          marginBottom: "0.75rem",
        }}
      >
        Generating Preview
      </h2>
      <p
        style={{
          fontSize: "14px",
          color: "#6b7280",
          marginBottom: "2rem",
        }}
      >
        Please wait while we prepare your resume
      </p>

      {/* Loading Indicator */}
      <div className="d-flex gap-2 justify-content-center">
        {[...Array(3)].map((_, i) => (
          <motion.div
            key={i}
            style={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              background: "#4F46E5",
            }}
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.3, 1, 0.3],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              delay: i * 0.2,
            }}
          />
        ))}
      </div>
    </motion.div>
  </motion.div>
);

const PreviewImage = ({
  image,
  orginal,
  previewResume,
  templates,
  selectedTemplate,
  handleImageLoad,
  isImageLoaded,
  imageTransformsY,
  selectedImageTransformY,
  previewRef,
}) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{ position: "relative", display: "inline-block" }}
  >
    <motion.img
      src={
        orginal
          ? image
          : previewResume || templates[selectedTemplate?.key].preview
      }
      alt="Resume"
      onLoad={handleImageLoad}
      className={isImageLoaded ? styles.imageLoaded : ""}
      style={{
        transform: `translateY(-${
          imageTransformsY[selectedImageTransformY] *
            previewRef.current?.offsetHeight || 0
        }px)`,
        borderRadius: "8px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease-in-out",
      }}
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300 }}
    />
  </motion.div>
);

const Preview = ({ setPreviewLoading, previewLoading }) => {
  const {
    values,
    printContainerRef,
    handleTemplateChange,
    templates,
    selectedTemplate,
    currentStep,
  } = useResume();

  const { payment, previewResume } = useJobs();
  const previewRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [image, setImage] = useState(null);
  const [imageTransformsY, setImageTransformsY] = useState([]);
  const [selectedImageTransformY, setSelectedImageTransformY] = useState(0);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [orginal, setOriginal] = useState(true);
  const [hasShownWarning, setHasShownWarning] = useState(false);
  const [isExceedingA4, setIsExceedingA4] = useState(false);
  const { auth } = useAuth();
  const getA4Images = () => {
    const newImage = new Image();
    newImage.src = image;

    newImage.onload = () => {
      const width = newImage.width;
      const height = newImage.height;
      const a4aspectRatio = 210 / 297;
      const a4Height = width / a4aspectRatio;
      let h = height;
      const transformsY = [];
      let transformY = 0;
      // Calculate the transformations for A4 pages
      while (h > 0) {
        transformsY.push(transformY);
        transformY += a4Height;
        h -= a4Height;
      }
      setImageTransformsY(transformsY);
    };
  };


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (values) {
        if (printContainerRef.current) {
          htmlToCanvasImage(printContainerRef.current, payment, values , currentStep).then(
            (img) => {
              setImage(img);
            }
          );
        }
      }
    }, 1000); 
    return () => clearTimeout(timeoutId);
  }, [values, handleTemplateChange]);

  // ... existing code ...
  // useEffect(() => {
  //   if (values && printContainerRef.current) {
  //     htmlToCanvasImage(printContainerRef.current, payment).then((img) => {
  //       setImage(img);
  //       // getA4Images();
  //     });
  //   }
  // }, [values, handleTemplateChange]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
    setPreviewLoading(false);
  };

  const handleDownladImage = async () => {
    const canvas = await html2canvas(printContainerRef.current);
    const imgData = canvas.toDataURL("image/png");
    const response = await fetch(imgData);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${values.name}.png`;
    link.click();
  };

  const handlePrintPDF = () => {
    const pageMargins = selectedTemplate.page_margins;
    const style = document.createElement("style");
    style.innerHTML = `@page { margin: ${pageMargins} !important; size: A4 !important; }`;
    const printContent = document.getElementById("print_content");
    printContent.style.width = `calc(210mm - 2 * ${pageMargins})`;
    const originalPadding = printContainerRef.current.style.padding;
    printContainerRef.current.style.padding = "0";
    document.head.appendChild(style);
    window.print();
    style.remove();
    printContent.style.width = "100%";
    printContainerRef.current.style.padding = originalPadding;
  };

  const checkA4Size = () => {
    if (!printContainerRef.current) return;

    const element = printContainerRef.current;
    const a4HeightPx = 1123; // Approximately 297mm in pixels at 96 DPI
    const currentHeight = element.scrollHeight;

    if (currentHeight > a4HeightPx && !hasShownWarning) {
      setIsExceedingA4(true);
      setHasShownWarning(true);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      checkA4Size();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [values]);

  const handleGeneratePdf = async () => {
    const element = printContainerRef.current;

    try {
      const a4HeightPx = 1123;
      if (element.scrollHeight > a4HeightPx && !hasShownWarning) {
        setIsExceedingA4(true);
        setHasShownWarning(true);
        return;
      }

      // First convert to canvas
      const canvas = await html2canvas(element, {
        scale: 2,
        useCORS: true,
        logging: false,
        onclone: (clonedDoc) => {
          const textElements = clonedDoc.querySelectorAll("*");
          textElements.forEach((el) => {
            if (
              el.innerText &&
              window.getComputedStyle(el).display !== "none"
            ) {
              const styles = window.getComputedStyle(el);
              el.style.fontFamily = styles.fontFamily;
              el.style.fontSize = styles.fontSize;
              el.style.fontWeight = styles.fontWeight;
              el.style.color = styles.color;
              el.style.webkitUserSelect = "text";
              el.style.userSelect = "text";
            }
          });
        },
      });

      // A4 dimensions in mm
      const a4Width = 210;
      const a4Height = 297;

      // Calculate dimensions while maintaining aspect ratio
      let imgWidth = a4Width;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Ensure minimum height is A4
      if (imgHeight < a4Height) {
        imgHeight = a4Height;
        imgWidth = (canvas.width * imgHeight) / canvas.height;
      }

      // Create PDF with calculated dimensions
      const pdf = new jsPDF({
        unit: "mm",
        format: [Math.max(imgWidth, a4Width), Math.max(imgHeight, a4Height)],
        orientation: "portrait",
      });

      // Add image to PDF, centered if wider than A4
      const xOffset = imgWidth > a4Width ? 0 : (a4Width - imgWidth) / 2;
      pdf.addImage(
        canvas.toDataURL("image/jpeg", 1.0),
        "JPEG",
        xOffset,
        0,
        imgWidth,
        imgHeight,
        undefined,
        "FAST"
      );

      // Add hidden text layer for searchability
      const textContent = element.innerText;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      // Set font size and color for the hidden text
      pdf.setFontSize(1); // Very small font size
      pdf.setTextColor(255, 255, 255); // White color (invisible)

      // Calculate position at bottom of page
      const bottomMargin = 5; // 5mm from bottom
      const textY = pageHeight - bottomMargin;

      // Add text at the bottom
      pdf.text(textContent, 0, textY, {
        maxWidth: pageWidth,
        align: "left",
      });

      // Save the PDF
      pdf.save(
        `${auth?.user?.[0]?.firstname}_${auth?.user?.[0]?.lastname}_resume.pdf`
      );
    } catch (error) {
      console.error("PDF generation failed:", error);
    }
  };

  const SizeWarningModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <AlertTriangle size={24} className={styles.warningIcon} />
            <h3>Resume Length Exceeds One Page</h3>
          </div>

          <div className={styles.modalBody}>
            <p className={styles.modalDescription}>
              Your resume content is longer than one page. Most recruiters
              prefer single-page resumes.
            </p>

            <div className={styles.tipsContainer}>
              <h4>Professional Tips to Optimize:</h4>
              <ul>
                <li>Use bullet points with maximum 2 lines each</li>
                <li>
                  Keep only recent and relevant experience (last 10 years)
                </li>
                <li>Remove redundant information</li>
                <li>Adjust margins and spacing</li>
                <li>Use concise language</li>
              </ul>
            </div>
          </div>

          <div className={styles.modalFooter}>
            <button className={styles.secondaryButton} onClick={onClose}>
              I'll Revise
            </button>
            <button
              className={styles.primaryButton}
              onClick={() => {
                onClose();
              }}
            >
              Continue Anyway
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.preview}>
      {/* <PreviewOptions preview={data?.preview} /> */}

      {/* <div className={styles.dots}>
        {imageTransformsY &&
          imageTransformsY.map((_, index) => (
            <span
              key={index}
              className={
                styles.dot +
                (index === selectedImageTransformY ? " " + styles.selected : "")
              }
              onClick={() => setSelectedImageTransformY(index)}
            >
              {" "}
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#2dc08d" }}
              />{" "}
              {selectedImageTransformY}{" "}
            </span>
          ))}
      </div>

      <div className={styles.dots}>
        {imageTransformsY &&
          imageTransformsY.map((_, index) => (
            <span
              key={index}
              className={
                styles.dot +
                (index === selectedImageTransformY ? " " + styles.selected : "")
              }
              onClick={() => setSelectedImageTransformY(index)}
            >
              {index > 0 && (
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ color: "#2dc08d" }}
                />
              )}
              {index}
              {index < imageTransformsY.length - 1 && (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ color: "#2dc08d" }}
                />
              )}
            </span>
          ))}
      </div> */}

      <div className={styles.sfs}>
        <div className={styles.containerButton}>
          <div
            className={`${styles.innerbutton} ${
              !orginal ? styles.buttonActive : ""
            }`}
            onClick={() => setOriginal(false)}
          >
            Resume Example
          </div>
          <div
            className={`${styles.innerbutton} ${
              orginal ? styles.buttonActive : ""
            }`}
            onClick={() => setOriginal(true)}
          >
            Your Resume
          </div>
        </div>
      </div>
      <div ref={previewRef} className={styles.preview_image}>
        {previewLoading ? <PreviewLoader /> : null}
        {image && (
          <PreviewImage
            image={image}
            orginal={orginal}
            previewResume={previewResume}
            templates={templates}
            selectedTemplate={selectedTemplate}
            handleImageLoad={handleImageLoad}
            isImageLoaded={isImageLoaded}
            imageTransformsY={imageTransformsY}
            selectedImageTransformY={selectedImageTransformY}
            previewRef={previewRef}
          />
        )}
      </div>
      <div className={styles.downloadContainer}>
        <div
          className={styles.downloadButton}
          onClick={
            payment?.paymentStatus !== "success"
              ? () => setModalShow(true)
              : handleGeneratePdf
          }
        >
          Download
        </div>
      </div>
      <Model modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  );
};

export default Preview;
