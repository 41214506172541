import React, { useState, useRef, useEffect } from "react";
import OpenAI from "openai";

import { useResume } from "../../context/Resume";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPlus,
  faClose,
  faPencilAlt,
  faRedo,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import HashLoader from "react-spinners/HashLoader";
import { useJobs } from "../../context/Jobs";
import Model from "../../../../../components/Model";
import styles from "./Config.module.css";
import { RotateCcw, Plus } from "lucide-react";
import { useCredits } from "../../context/CreditsContext";
import { motion, AnimatePresence } from "framer-motion";
import { RiMagicLine } from "react-icons/ri";
import occupationalData from "../../../../utils/occupationalData";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

export const AIPoints = ({ sectionKey, element, partiCularName, section }) => {
  const [loadingAi, setLoadingAi] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalpaymentshow, setModalPaymentShow] = useState(false);
  const [modelshowskills, setModalShowskills] = useState(false);
  const { handleResumeChange } = useResume();
  const [jobRole, setJobRole] = useState("");
  const [skills, setSkills] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [company, setCompany] = useState("");
  const [current, setCurrent] = useState("");
  const [arrays, setArray] = useState([]);
  const [tickPoint, setTickPoint] = useState(false);
  const [pointArray, setPointArray] = useState([]);
  const [contentArray, setContentArray] = useState([]);
  const [jobDetails, setJobDetails] = useState();
  const { payment } = useJobs();
  const [task, setTask] = useState("");
  const [tools, setTools] = useState("");
  const [showResults, setShowResults] = useState(false);
  const { credits, updateCredits } = useCredits();
  const [tasksByRole, setTasksByRole] = useState([]);
  const [skillsByRole, setSkillsByRole] = useState([]);
  const [toolsByRole, setToolsByRole] = useState([]);
  const [showAllTasks, setShowAllTasks] = useState(false);
  const [showAllSkills, setShowAllSkills] = useState(false);
  const [showAllTools, setShowAllTools] = useState(false);

  useEffect(() => {
    setJobRole(element.role);
    setCompany(element.company);
    setTimePeriod(element.timePeriod);
    setSkills(element?.techstack);
  }, [
    element.company,
    element.role,
    element.company,
    element.timePeriod,
    element?.techstack,
  ]);

  const generatePoint = async () => {
    if (payment?.paymentStatus !== "success" && credits <= 0) {
      setModalPaymentShow(true);
      return;
    }

    try {
      setLoadingAi(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: `You are an expert resume writer. Generate powerful bullet points that MUST follow this format:
            "[Strong Action Verb] [Achievement] [using/with/through] [Specific Tools], resulting in [Quantifiable Metrics]"

            REQUIRED ELEMENTS in EACH point:
            1. START with these action verbs: Led, Developed, Implemented, Spearheaded, Orchestrated, Engineered, Architected
            2. INCLUDE specific tools/technologies after "using", "with", or "through"
            3. INCLUDE numerical metrics in one of these formats:
               - Percentages (e.g., increased by 45%)
               - Dollar values (e.g., $50K, $1M)
               - Quantities (e.g., 1000+ users)
               - Time metrics (e.g., reduced by 5 months)

            Example perfect points:
            "Spearheaded cloud migration using AWS and Docker, reducing costs by 65% and deployment time by 4 hours"
            "Developed e-commerce platform using React.js and Node.js, increasing sales by $500K and user engagement by 85%"`,
          },
          {
            role: "user",
            content: `Generate 8 powerful resume bullet points for:
              Job Title: ${jobRole}
              Tasks: ${task}
              Technical Skills: ${skills}
              Tools Used: ${tools}
  
              Requirements:
              - MUST start with provided action verbs
              - MUST include tools/technologies after "using/with/through"
              - MUST include specific metrics (%, $, or numbers)
              - Keep each point  in range of 25 to 30 words
              - Focus on measurable achievements
              
              Return only a valid JSON array of strings.`,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.7,
        max_tokens: 1000,
      });

      let jsonString = completion.choices[0].message.content;

      // Clean the response if needed
      jsonString = jsonString
        .replace(/```json\n?/g, "") // Remove ```json
        .replace(/```\n?/g, "") // Remove closing ```
        .trim(); // Remove extra whitespace

      // Attempt to parse with error handling
      let suggestions;
      try {
        suggestions = JSON.parse(jsonString);
      } catch (parseError) {
        console.error("Failed to parse AI response:", parseError);
        console.log("Raw response:", jsonString);
        throw new Error("Invalid response format from AI");
      }

      // Validate that we got an array
      if (!Array.isArray(suggestions)) {
        throw new Error("AI response is not an array");
      }

      if (payment?.paymentStatus !== "success") {
        updateCredits(credits - 1);
      }

      setCurrent(jobRole);
      handleResumeChange(sectionKey, [...(section || []), ""]);
      setArray(suggestions);
      setShowResults(true);
      setLoadingAi(false);
    } catch (error) {
      console.error("Error generating points:", error);
      setLoadingAi(false);
      // Optionally show error to user
      // setError("Failed to generate points. Please try again.");
    }
  };

  const generateSkills = async () => {
    try {
      setLoadingAi(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure  only skills filter out from given job details ",
          },
          { role: "user", content: `job Details= ${jobDetails}` },
          {
            role: "user",
            content: `give 8  skills in array in one or two words `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      const skillsString = completion.choices[0].message.content;
      console.log("skills", skillsString);

      // Attempt to parse the skills string to an array
      let suggestions;
      try {
        suggestions = JSON.parse(skillsString);
      } catch (error) {
        console.error("Failed to parse JSON:", error);
        suggestions = skillsString.split(",").map((skill) => skill.trim());
      }

      // Ensure suggestions is an array
      if (!Array.isArray(suggestions)) {
        throw new Error("Parsed suggestions are not an array");
      }
      handleResumeChange(sectionKey, [...(section || []), ...suggestions]);
      setLoadingAi(false);
      setModalShowskills(false);
    } catch (error) {
      console.log(error);
    }
  };

  const pointHandler = (index, point) => {
    setPointArray((prevArray) => {
      // Check if point already exists to prevent duplicates
      const exists = prevArray.some((item) => item.index === index);
      if (exists) return prevArray;

      // Add new point
      const newArray = [...prevArray, { index, content: point }];

      // Update content array immediately
      const extractedContent = newArray.map((item) => item.content);
      setContentArray(extractedContent);

      // Update resume with new content
      extractedContent.forEach((value, idx) => {
        handleResumeChange(`${sectionKey}.${idx}`, value);
      });

      return newArray;
    });
  };

  const removePointHandler = (index) => {
    setPointArray((prevArray) => {
      // Remove point with matching index
      const newArray = prevArray.filter((item) => item.index !== index);

      // Update content array immediately
      const extractedContent = newArray.map((item) => item.content);
      setContentArray(extractedContent);

      // Update resume with new content
      extractedContent.forEach((value, idx) => {
        handleResumeChange(`${sectionKey}.${idx}`, value);
      });

      // Clear any empty slots in the resume
      const remainingIndices = extractedContent.length;
      if (remainingIndices < prevArray.length) {
        handleResumeChange(`${sectionKey}.${remainingIndices}`, "");
      }

      return newArray;
    });
  };

  const AIButtonRenderContent = () => {
    if (loadingAi) {
      return (
        <div className={styles.aibuttt}>
          <div className={styles.spinner}></div>
          Generating Content...
        </div>
      );
    } else if (!loadingAi && arrays.length > 0) {
      return (
        <div className={styles.aibuttt} onClick={() => setModalShow(true)}>
          <RotateCcw size={14} />
          Regenerate{" "}
          {payment?.paymentStatus !== "success" && `(${credits} credits left)`}
        </div>
      );
    } else {
      return (
        <div
          className={styles.aibuttt}
          onClick={
            payment?.paymentStatus !== "success" && credits <= 0
              ? () => setModalPaymentShow(true)
              : () => setModalShow(true)
          }
        >
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730053517/all/axclicveze1ms96nrkww.svg" />{" "}
          Generate with AI{" "}
          {payment?.paymentStatus !== "success" && `(${credits} credits left)`}
        </div>
      );
    }
  };

  useEffect(() => {
    if (jobRole) {
      // Get role-specific data first
      let tasks = occupationalData.getTasksByRole(jobRole || "");
      let skills = occupationalData.getSkillsByRole(jobRole || "");
      let tools = occupationalData.getToolsByRole(jobRole || "");

      // If any of these are empty, fall back to unique collections
      if (!tasks.length) {
        // Get common tasks across all roles
        tasks = occupationalData.getAllUniqueTask();
      }

      if (!skills.length) {
        // Use the existing getAllUniqueSkills utility function
        skills = occupationalData.getAllUniqueSkills();
      }

      if (!tools.length) {
        // Use the existing getAllUniqueTools utility function
        tools = occupationalData.getAllUniqueTools();
      }

      // Update all states
      setTasksByRole(tasks);
      setSkillsByRole(skills);
      setToolsByRole(tools);
    }
  }, [jobRole]);

  // Helper function to paginate items
  const paginateItems = (items, showAll) => {
    if (!items) return [];
    return showAll ? items : items.slice(0, 15);
  };

  return (
    <div className="d-flex justify-content-end">
      <AIButtonRenderContent />

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
        className="custom-modal"
      >
        <Modal.Body className={styles.modelBody}>
          <div className="container" style={{ position: "relative" }}>
            <div className={styles.close}>
              <span
                className={`${styles.closeButton} close`}
                onClick={() => setModalShow(false)}
              >
                <FontAwesomeIcon icon={faClose} className={styles.iconcut} />
              </span>
            </div>

            <div className="row">
              <div className={`col-12 col-md-12 ${styles.second}`}>
                {!showResults ? (
                  <div className={styles.containerModal}>
                    <motion.div
                      className={styles.topHeadingContainer}
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.4 }}
                    >
                      <motion.div
                        className={styles.headtitle}
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ delay: 0.2, duration: 0.3 }}
                      >
                        <motion.span
                          className={styles.titleIconM}
                          initial={{ rotate: -180, opacity: 0 }}
                          animate={{ rotate: 0, opacity: 1 }}
                          transition={{ delay: 0.4, duration: 0.5 }}
                        >
                          ✨
                        </motion.span>
                        <span className={styles.titleTextM}>
                          Generate Key Points with AI
                        </span>
                        <motion.div
                          className={styles.badge}
                          initial={{ opacity: 0, x: 20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ delay: 0.5, duration: 0.3 }}
                        >
                          Pro Feature
                        </motion.div>
                      </motion.div>

                      <motion.div
                        className={styles.headtitlparagraphM}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3, duration: 0.3 }}
                      >
                        Transform your experience into powerful bullet points
                      </motion.div>
                    </motion.div>
                    <div className={styles.ModalinputContainerMain}>
                      <div className={styles.mb}>
                        <label htmlFor="field2" className={styles.label}>
                          Job Title:
                        </label>
                        <input
                          type="text"
                          name="field2"
                          id="field2"
                          placeholder="Job Roles"
                          className={styles.jobRole}
                          value={jobRole}
                          onChange={(e) => setJobRole(e.target.value)}
                        />
                      </div>

                      <div className={styles.mb}>
                        <label htmlFor="field2" className={styles.label}>
                          Task:
                        </label>
                        <div className={styles.taskContainer}>
                          <input
                            type="text"
                            name="field2"
                            id="field2"
                            placeholder="Example: Presentations, Demos, Cold Calling etc "
                            className={styles.task}
                            value={task}
                            onChange={(e) => setTask(e.target.value)}
                          />
                          <div className={styles.suggestions}>
                            {paginateItems(tasksByRole, showAllTasks).map(
                              (suggestion, index) => {
                                const currentTasks = task
                                  ? task
                                      .split(",")
                                      .map((t) => t.trim())
                                      .filter(Boolean)
                                  : [];
                                const isSelected =
                                  currentTasks.includes(suggestion);

                                return (
                                  <div
                                    key={index}
                                    className={styles.suggestionItem}
                                    onClick={() => {
                                      if (isSelected) {
                                        const newTasks = currentTasks
                                          .filter((t) => t !== suggestion)
                                          .join(", ");
                                        setTask(newTasks);
                                      } else {
                                        const newTask =
                                          currentTasks.length > 0
                                            ? `${task}, ${suggestion}`
                                            : suggestion;
                                        setTask(newTask);
                                      }
                                    }}
                                  >
                                    {suggestion}
                                    {isSelected ? (
                                      <span className={styles.crossIcon}>
                                        ×
                                      </span>
                                    ) : (
                                      <Plus
                                        className={styles.plusIcon}
                                        size={16}
                                      />
                                    )}
                                  </div>
                                );
                              }
                            )}
                            {tasksByRole?.length > 15 && (
                              <motion.button
                                className={styles.showMoreButton}
                                onClick={() => setShowAllTasks(!showAllTasks)}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.2 }}
                              >
                                <div className={styles.showMoreContent}>
                                  <span className={styles.showMoreText}>
                                    {showAllTasks ? (
                                      <>
                                        Show Less
                                        <motion.span
                                          initial={{ rotate: 180 }}
                                          animate={{ rotate: 0 }}
                                          transition={{ duration: 0.3 }}
                                        >
                                          ↑
                                        </motion.span>
                                      </>
                                    ) : (
                                      <>
                                        Show More
                                        <motion.span
                                          initial={{ rotate: 0 }}
                                          animate={{ rotate: 180 }}
                                          transition={{ duration: 0.3 }}
                                        >
                                          ↑
                                        </motion.span>
                                      </>
                                    )}
                                  </span>
                                  {!showAllTasks && (
                                    <span className={styles.itemCount}>
                                      +{tasksByRole.length - 15} items
                                    </span>
                                  )}
                                </div>
                              </motion.button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className={styles.mb}>
                        <label htmlFor="field3" className={styles.label}>
                          Skills:
                        </label>
                        <div className={styles.taskContainer}>
                          <input
                            type="text"
                            name="field3"
                            id="field3"
                            placeholder="Example: React, JavaScript, Node.js etc "
                            className={styles.task}
                            value={skills}
                            onChange={(e) => setSkills(e.target.value)}
                          />
                          <div className={styles.suggestions}>
                            {paginateItems(skillsByRole, showAllSkills).map(
                              (suggestion, index) => {
                                const currentSkills = skills
                                  ? skills
                                      .split(",")
                                      .map((s) => s.trim())
                                      .filter(Boolean)
                                  : [];
                                const isSelected =
                                  currentSkills.includes(suggestion);

                                return (
                                  <div
                                    key={index}
                                    className={styles.suggestionItem}
                                    onClick={() => {
                                      if (isSelected) {
                                        const newSkills = currentSkills
                                          .filter((s) => s !== suggestion)
                                          .join(", ");
                                        setSkills(newSkills);
                                      } else {
                                        const newSkills =
                                          currentSkills.length > 0
                                            ? `${skills}, ${suggestion}`
                                            : suggestion;
                                        setSkills(newSkills);
                                      }
                                    }}
                                  >
                                    {suggestion}
                                    {isSelected ? (
                                      <span className={styles.crossIcon}>
                                        ×
                                      </span>
                                    ) : (
                                      <Plus
                                        className={styles.plusIcon}
                                        size={16}
                                      />
                                    )}
                                  </div>
                                );
                              }
                            )}
                            {skillsByRole?.length > 15 && (
                              <motion.button
                                className={styles.showMoreButton}
                                onClick={() => setShowAllSkills(!showAllSkills)}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.2 }}
                              >
                                <div className={styles.showMoreContent}>
                                  <span className={styles.showMoreText}>
                                    {showAllSkills ? (
                                      <>
                                        Show Less
                                        <motion.span
                                          initial={{ rotate: 180 }}
                                          animate={{ rotate: 0 }}
                                          transition={{ duration: 0.3 }}
                                        >
                                          ↑
                                        </motion.span>
                                      </>
                                    ) : (
                                      <>
                                        Show More
                                        <motion.span
                                          initial={{ rotate: 0 }}
                                          animate={{ rotate: 180 }}
                                          transition={{ duration: 0.3 }}
                                        >
                                          ↑
                                        </motion.span>
                                      </>
                                    )}
                                  </span>
                                  {!showAllSkills && (
                                    <span className={styles.itemCount}>
                                      +{skillsByRole.length - 15} items
                                    </span>
                                  )}
                                </div>
                              </motion.button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className={styles.mb}>
                        <label htmlFor="field3" className={styles.label}>
                          Tools:
                        </label>
                        <div className={styles.taskContainer}>
                          <input
                            type="text"
                            name="field3"
                            id="field3"
                            placeholder="Example: Jira, Slack, Microsoft Office etc"
                            className={styles.task}
                            value={tools}
                            onChange={(e) => setTools(e.target.value)}
                          />
                          <div className={styles.suggestions}>
                            {paginateItems(toolsByRole, showAllTools).map(
                              (suggestion, index) => {
                                const currentTools = tools
                                  ? tools
                                      .split(",")
                                      .map((t) => t.trim())
                                      .filter(Boolean)
                                  : [];
                                const isSelected =
                                  currentTools.includes(suggestion);

                                return (
                                  <div
                                    key={index}
                                    className={styles.suggestionItem}
                                    onClick={() => {
                                      if (isSelected) {
                                        const newTools = currentTools
                                          .filter((t) => t !== suggestion)
                                          .join(", ");
                                        setTools(newTools);
                                      } else {
                                        const newTools =
                                          currentTools.length > 0
                                            ? `${tools}, ${suggestion}`
                                            : suggestion;
                                        setTools(newTools);
                                      }
                                    }}
                                  >
                                    {suggestion}
                                    {isSelected ? (
                                      <span className={styles.crossIcon}>
                                        ×
                                      </span>
                                    ) : (
                                      <Plus
                                        className={styles.plusIcon}
                                        size={16}
                                      />
                                    )}
                                  </div>
                                );
                              }
                            )}
                            {toolsByRole?.length > 15 && (
                              <motion.button
                                className={styles.showMoreButton}
                                onClick={() => setShowAllTools(!showAllTools)}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.2 }}
                              >
                                <div className={styles.showMoreContent}>
                                  <span className={styles.showMoreText}>
                                    {showAllTools ? (
                                      <>
                                        Show Less
                                        <motion.span
                                          initial={{ rotate: 180 }}
                                          animate={{ rotate: 0 }}
                                          transition={{ duration: 0.3 }}
                                        >
                                          ↑
                                        </motion.span>
                                      </>
                                    ) : (
                                      <>
                                        Show More
                                        <motion.span
                                          initial={{ rotate: 0 }}
                                          animate={{ rotate: 180 }}
                                          transition={{ duration: 0.3 }}
                                        >
                                          ↑
                                        </motion.span>
                                      </>
                                    )}
                                  </span>
                                  {!showAllTools && (
                                    <span className={styles.itemCount}>
                                      +{toolsByRole.length - 15} items
                                    </span>
                                  )}
                                </div>
                              </motion.button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.ModalButtonContainerMain}>
                      <div className={styles.ModalButtonContainer}>
                        <span
                          className={styles.skipandgenerate}
                          disabled={loadingAi}
                        >
                          {loadingAi ? (
                            <div className={styles.buttonContent}>
                              <div className={styles.spinnerContainer}>
                                <div className={styles.spinnerRing}></div>
                              </div>
                              <span>Processing...</span>
                            </div>
                          ) : (
                            "Skip & Generate"
                          )}
                        </span>
                        <span
                          className={styles.generateModalButton}
                          onClick={generatePoint}
                          disabled={loadingAi}
                        >
                          {loadingAi ? (
                            <div className={styles.buttonContent}>
                              <div className={styles.spinnerContainer}>
                                <div className={styles.spinnerRing}></div>
                              </div>
                              <span>Generating...</span>
                            </div>
                          ) : (
                            "Generate Points"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.containerModal}>
                    <motion.div
                      className={styles.contentWrapper}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className={styles.topHeadingContainer}>
                        <motion.div
                          initial={{ y: -20, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          transition={{ delay: 0.2 }}
                          className={styles.headingWrapper}
                        >
                          <div className={styles.headtitle}>
                            <span className={styles.titleIcon}>✨</span>
                            <span className={styles.titleText}>
                              AI Generated Points
                            </span>
                            <motion.span
                              className={styles.pointCount}
                              initial={{ scale: 0 }}
                              animate={{ scale: 1 }}
                              transition={{ delay: 0.4 }}
                            >
                              {arrays.length} suggestions
                            </motion.span>
                          </div>
                          <div className={styles.headtitlparagraph}>
                            Select the most relevant points for your
                            professional experience
                          </div>
                        </motion.div>
                      </div>

                      <motion.div
                        className={styles.resultsWrapper}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                      >
                        <div className={styles.resultsContainer}>
                          <AnimatePresence mode="popLayout">
                            {arrays.map((point, index) => {
                              const isSelected = pointArray.some(
                                (item) => item.index === index
                              );
                              return (
                                <motion.div
                                  key={index}
                                  layout
                                  initial={{ opacity: 0, scale: 0.9 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  exit={{ opacity: 0, scale: 0.9 }}
                                  transition={{ duration: 0.2 }}
                                  className={`${styles.skillCard} ${
                                    isSelected ? styles.skillCardSelected : ""
                                  }`}
                                  onClick={() =>
                                    isSelected
                                      ? removePointHandler(index)
                                      : pointHandler(index, point)
                                  }
                                >
                                  <div className={styles.skillContent}>
                                    <span className={styles.pointText}>
                                      {point}
                                    </span>
                                    <motion.div
                                      className={styles.skillToggle}
                                      initial={false}
                                      animate={{
                                        backgroundColor: isSelected
                                          ? "#2563eb"
                                          : "#ffffff",
                                        rotate: isSelected ? 360 : 0,
                                      }}
                                    >
                                      {isSelected ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          className={styles.skillToggleIcon}
                                        />
                                      ) : (
                                        <Plus
                                          className={styles.skillToggleIcon}
                                          size={14}
                                        />
                                      )}
                                    </motion.div>
                                  </div>
                                </motion.div>
                              );
                            })}
                          </AnimatePresence>
                        </div>
                      </motion.div>

                      <motion.div
                        className={styles.ModalButtonContainerMain}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4 }}
                      >
                        <div className={styles.ModalButtonContainer}>
                          <motion.button
                            className={styles.editButton}
                            onClick={() => setShowResults(false)}
                            whileHover={{
                              scale: 1.02,
                              backgroundColor: "#f5f5f5",
                            }}
                            whileTap={{ scale: 0.98 }}
                          >
                            <FontAwesomeIcon
                              icon={faPencilAlt}
                              className={styles.buttonIcon}
                            />
                            <span>Edit Details</span>
                          </motion.button>
                          <motion.button
                            className={styles.regenerateButton}
                            onClick={() => generatePoint()}
                            whileHover={{
                              scale: 1.02,
                              backgroundColor: "#2563eb",
                            }}
                            whileTap={{ scale: 0.98 }}
                          >
                            <FontAwesomeIcon
                              icon={faRedo}
                              className={styles.buttonIcon}
                            />
                            <span>Regenerate Points</span>
                          </motion.button>
                        </div>
                      </motion.div>
                    </motion.div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modelshowskills}
        onHide={() => setModalShowskills(false)}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ lineHeight: "1.5rem" }}>
            Generate With AI <br />{" "}
            <span
              style={{
                fontSize: "1rem",
                color: "hsl(223.13deg 14.68% 57.25%)",
              }}
            >
              {" "}
              For {partiCularName}
            </span>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className={styles.mb}>
                  <label
                    htmlFor="jobDescription"
                    style={{
                      display: "block",
                      marginBottom: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    Job Description:
                  </label>
                  <textarea
                    name="jobDescription"
                    id="jobDescription"
                    placeholder="Enter the job roles and responsibilities"
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      boxSizing: "border-box",
                      fontSize: "16px",
                      resize: "vertical",
                      minHeight: "100px",
                    }}
                    value={jobDetails}
                    onChange={(e) => setJobDetails(e.target.value)}
                  />
                </div>
                <Button
                  style={{
                    background:
                      "linear-gradient(270deg,#a066ff 16.03%,#666cff 88.19%)",
                    color: "white",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  onClick={() => generateSkills()}
                  disabled={loadingAi}
                >
                  {loadingAi ? (
                    <HashLoader color="white" size={25} />
                  ) : (
                    "Generate"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Model modalShow={modalpaymentshow} setModalShow={setModalPaymentShow} />
    </div>
  );
};

export const AISkillsPoints = ({
  sectionKey,
  element,
  section,
}) => {
  const [loadingAi, setLoadingAi] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [modalpaymentshow, setModalPaymentShow] = useState(false);
  const { handleResumeChange, values } = useResume();
  const { payment } = useJobs();
  const [arrays, setArray] = useState([]);
  const { credits, updateCredits } = useCredits();
  const jobRole = element?.role || "Professional"; // Get job role from element or default
  const [selectedSkills, setSelectedSkills] = useState(new Set());
  const modalRef = useRef(null);

  const project = values.sections.projects?.data;
  const experience = values.sections.experience?.data;

  // Handle click outside to close modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalShow(false);
      }
    };

    if (modalShow) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalShow]);

  const generatePoint = async () => {
    if (payment?.paymentStatus !== "success" && credits <= 0) {
      setModalPaymentShow(true);
      return;
    }

    try {
      setLoadingAi(true);

      // Format experience context - handle any experience entries dynamically
      const experienceContext = experience
        ?.map((exp) => {
          if (exp.company || exp.role) {
            return `Role: ${exp.role || "Not specified"} at ${
              exp.company || "Not specified"
            } (${exp.timePeriod || "No date specified"})`;
          }
          return "";
        })
        .filter(Boolean)
        .join("\n");

      // Format project context - handle any project entries dynamically
      const projectContext = project
        ?.map((p) => {
          if (p.title || p.organisation) {
            const description =
              p.description?.replace(/<[^>]*>/g, "").trim() || "";
            return `Project: ${p.title || "Untitled"} at ${
              p.organisation || "No organization"
            } (${p.year || "No year specified"}). ${description}`;
          }
          return "";
        })
        .filter(Boolean)
        .join("\n");

      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You are a professional resume writer. Generate a comprehensive list of relevant skills based on the provided background. Include both technical and soft skills that would make the candidate stand out.",
          },
          {
            role: "user",
            content: `Based on this background:

              Professional Experience:
              ${experienceContext || "No experience provided"}
              
              Project Experience:
              ${projectContext || "No projects provided"}

              Requirements:
              1. Generate a list of 15-20 most relevant skills that include:
                 - Technical skills from their experience
                 - Soft skills demonstrated in their roles
                 - Tools and technologies mentioned
                 - Industry-specific knowledge
                 - Methodologies they've likely used
              
              2. Skills Selection Rules:
                 - Prioritize skills explicitly mentioned in experience/projects
                 - Include complementary skills commonly associated with the role
                 - Balance technical and soft skills
                 - Keep skills concise (1-3 words each)
              
              Return only a JSON array of strings. Example:
              ["JavaScript", "Team Leadership", "Project Management", ...]`,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.7,
        max_tokens: 1000,
      });

      let jsonString = completion.choices[0].message.content.trim();
      jsonString = jsonString.replace(/```json\n?/g, "").replace(/```\n?/g, "");
      let skillsArray = JSON.parse(jsonString);

      // Format skills for UI
      const formattedSkills = skillsArray.map((skill) => ({
        name: skill,
        id: Math.random().toString(36).substr(2, 9),
      }));

      if (payment?.paymentStatus !== "success") {
        updateCredits(credits - 1);
      }

      setArray(formattedSkills);
      setLoadingAi(false);
    } catch (error) {
      console.error("Error generating skills:", error);
      setLoadingAi(false);
    }
  };

  const toggleSkill = (skillId, skillName) => {
    setSelectedSkills((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(skillId)) {
        newSelected.delete(skillId);
        // Remove from resume immediately
        const currentSkills = (section || []).filter(skill => skill !== "" && skill !== skillName);
        handleResumeChange(sectionKey, currentSkills);
      } else {
        newSelected.add(skillId);
        // Add to resume, replacing empty strings if they exist
        const currentSkills = section || [];
        const nonEmptySkills = currentSkills.filter(skill => skill !== "");
        const updatedSkills = [...nonEmptySkills, skillName];
        handleResumeChange(sectionKey, updatedSkills);
      }
      return newSelected;
    });
  };

  return (
    <div className="d-flex justify-content-end">
      <div
        className={styles.aibuttt}
        onClick={() => {
          setModalShow(true);
          generatePoint();
        }}
      >
        <img
          src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730053517/all/axclicveze1ms96nrkww.svg"
          alt="AI icon"
        />{" "}
        Generate with AI{" "}
        {payment?.paymentStatus !== "success" && `(${credits} credits left)`}
      </div>

      {modalShow && (
        <div className={styles.modalOverlay}>
          <div ref={modalRef} className={styles.customModal}>
            <div className={styles.modalContent}>
              <div
                className={styles.modalHeader}
                style={{ marginBottom: "0px" }}
              >
                <div className={styles.headerContent}>
                  <div className={styles.titleWrapper}>
                    <span className={styles.titleIcon}>✨</span>
                    <h2>AI Skills Suggestions</h2>
                  </div>
                  <p className={styles.subtitle}>
                    Enhance your resume with AI-powered skill recommendations
                  </p>
                </div>
                <button
                  className={styles.closeButton}
                  onClick={() => setModalShow(false)}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>

              <div className={styles.modalBody}>
                {loadingAi ? (
                  <div className={styles.loadingState}>
                    <div className={styles.loadingAnimation}>
                      <div className={styles.loadingIcon}>
                        <RiMagicLine />
                      </div>
                      <div className={styles.loadingText}>
                        <h3>Analyzing Your Profile</h3>
                        <p>
                          Discovering relevant skills based on your
                          experience...
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={styles.skillsContainer}>
                    <div className={styles.skillsMetrics}>
                      <div className={styles.metricItem}>
                        <span className={styles.metricValue}>
                          {arrays.length}
                        </span>
                        <span className={styles.metricLabel}>Suggested</span>
                      </div>
                      <div className={styles.metricDivider} />
                      <div className={styles.metricItem}>
                        <span className={styles.metricValue}>
                          {selectedSkills.size}
                        </span>
                        <span className={styles.metricLabel}>Selected</span>
                      </div>
                    </div>

                    <div className={styles.skillsScrollArea}>
                      <div className={styles.skillsGrid}>
                        {arrays.map((skill) => {
                          const isSelected = selectedSkills.has(skill.id);
                          return (
                            <div
                              key={skill.id}
                              className={`${styles.skillPill} ${
                                isSelected ? styles.selected : ""
                              }`}
                              onClick={() => toggleSkill(skill.id, skill.name)}
                            >
                              <span className={styles.skillName}>
                                {skill.name}
                              </span>
                              <div
                                className={`${styles.pillIcon} ${
                                  isSelected ? styles.selectedIcon : ""
                                }`}
                              >
                                {isSelected ? (
                                  <FontAwesomeIcon icon={faCheck} />
                                ) : (
                                  <Plus size={12} />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className={styles.modalFooter}>
                      <button
                        className={styles.regenerateButton}
                        onClick={generatePoint}
                        disabled={loadingAi}
                      >
                        <FontAwesomeIcon
                          icon={faRedo}
                          className={styles.regenerateIcon}
                        />
                        <span>Regenerate Suggestions</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Model modalShow={modalpaymentshow} setModalShow={setModalPaymentShow} />
    </div>
  );
};

export const AiDescription = ({
  element,
  updateDescription,
  partiCularName,
  name,
}) => {
  const { setLoading, values } = useResume();
  const { payment } = useJobs();
  const [loadingAi, setLoadingAi] = useState(false);
  const [modalpaymentshow, setModalPaymentShow] = useState(false);
  const [description, setDescription] = useState(
    element?.description || element?.summary
  );
  const { credits, updateCredits } = useCredits();
  const generateDescExperience = async () => {
    if (payment?.paymentStatus !== "success" && credits <= 0) {
      setModalPaymentShow(true);
      return;
    }

    try {
      setLoading(true);
      setLoadingAi(true);
      if (name === "Professionalsummary") {
        partiCularName = "Professional Summary";
      }
      let systemMessage = "";
      let prompt = "";

      // Configure prompts based on section type
      switch (partiCularName) {
        case "Experience":
          systemMessage =
            "You're a professional resume writer specializing in crafting impactful job descriptions.";
          prompt = `I have provided the company name, job roles, and time period. Company: ${element?.company}, Job Role: ${element?.role}, Time Period: ${element?.timePeriod}. Please generate a professional job description in 30-40 words highlighting key responsibilities, achievements, and impact.`;
          break;

        case "Projects":
          systemMessage =
            "You're a technical writer specializing in describing software and engineering projects.";
          prompt = `Please generate a 30-40 word project description for a project named "${
            element?.title
          }" that used ${
            element?.organisation || "various technologies"
          }. Focus on the project's purpose, technical implementation, and outcomes. Time frame: ${
            element?.year
          }.`;
          break;

        case "Certification":
          systemMessage =
            "You're a career advisor specializing in professional certifications and credentials.";
          prompt = `Please suggest 5 relevant and popular professional certifications for a ${
            values.sections.profile.data.position || "professional"
          } role. Format each as: "Certification Name - Issuing Organization". Focus on industry-recognized certifications that would enhance career prospects.`;
          break;

        case "Professional Summary":
          systemMessage =
            "You're an expert resume writer specializing in professional summaries and career highlights.";
          
          const position = values.sections.profile.data.position;
          prompt = position 
            ? `Please generate a compelling 40-50 word professional summary for a ${position} position. 
               Include: 
               - Years of experience (if provided: ${position})
               - Key skills and expertise
               - Career achievements and value proposition
               - Professional goals and industry focus
               Make it powerful, concise, and achievement-oriented.`
            : `Please generate a compelling 40-50 word general professional summary.
               Include:
               - Core professional strengths
               - Adaptability and versatility
               - Key transferable skills
               - Professional approach and work ethic
               Make it powerful, concise, and focused on universal professional qualities.`;
          break;

        default:
          systemMessage = "You're a professional resume writer.";
          prompt = `Please generate appropriate content for the ${partiCularName} section.`;
      }

      const completion = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: systemMessage,
          },
          {
            role: "user",
            content: prompt,
          },
        ],
        temperature: 0.7,
        max_tokens: partiCularName === "Certification" ? 200 : 100, // Allow more tokens for certification lists
      });

      const aiResponse = completion.choices[0].message.content;
      setDescription(aiResponse);

      if (payment?.paymentStatus !== "success") {
        updateCredits(credits - 1);
      }
    } catch (error) {
      console.error("Error generating description:", error);
    } finally {
      setLoading(false);
      setLoadingAi(false);
    }
  };

  useEffect(() => {
    // handleResumeChange(sectionKey,description)
    updateDescription(description);
  }, [description]);

  const AIButtonRenderContent = () => {
    if (loadingAi) {
      return (
        <div className={styles.aiButtonDiv}>
          <div className={styles.spinner}></div>
          Generating Content...
        </div>
      );
    } else if (!loadingAi && description) {
      return (
        <div className={styles.aiButtonDiv} onClick={generateDescExperience}>
          <RotateCcw size={14} />
          Regenerate{" "}
          {payment?.paymentStatus !== "success" && `(${credits} credits left)`}
        </div>
      );
    } else {
      return (
        <div className={styles.aiButtonDiv} onClick={generateDescExperience}>
          <img
            src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730053517/all/axclicveze1ms96nrkww.svg"
            alt="AI icon"
          />{" "}
          Generate with AI{" "}
          {payment?.paymentStatus !== "success" && `(${credits} credits left)`}
        </div>
      );
    }
  };

  return (
    <div className={styles.mian}>
      <div className={styles.aibuttt}>
        <AIButtonRenderContent />
      </div>

      <Model
        modalShow={modalpaymentshow}
        setModalShow={setModalPaymentShow}
        message={
          credits <= 0
            ? "You've used all your free credits. Upgrade to continue using AI features!"
            : undefined
        }
      />
    </div>
  );
};

export const AIImprovisePoints = ({ value, onUpdateValue }) => {
  const [improvedPoints, setImprovedPoints] = useState([]);
  const [loadingAi, setLoadingAi] = useState(false);
  const { payment } = useJobs();
  const { credits, updateCredits } = useCredits();
  const [modalpaymentshow, setModalPaymentShow] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);

  const generatePoint = async () => {
    if (payment?.paymentStatus !== "success" && credits <= 0) {
      setModalPaymentShow(true);
      return;
    }

    try {
      setLoadingAi(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: `You are an expert resume writer with years of experience in crafting impactful bullet points.
            Your task is to enhance the given bullet point by:
            1. Using powerful action verbs
            2. Adding specific metrics and quantifiable results
            3. Highlighting key achievements and impact
            4. Incorporating industry-standard terminology
            5. Following STAR (Situation, Task, Action, Result) format

            Respond with exactly 5 improved versions in JSON array format.
            Each improvement should:
            - Start with a strong action verb
            - Include at least one metric (%, $, or numbers)
            - Show clear business impact
            - Be a range of 25 to 30 words
            - Be achievement-focused
            
            
            TO ACHIEVE 100% SCORE, each point MUST include ALL of these:
            1. START with action verbs like: Led, Developed, Implemented, Spearheaded, Orchestrated, etc.
            2. INCLUDE specific metrics: %, $, numbers (e.g., 50%, $10K, 1000+)
            3. INCLUDE task with tools using words like "using", "with", "through"

            `,
          },
          {
            role: "user",
            content: `Transform this bullet point into 5 stronger variations:
            Original: "${value}"

            Focus on:
            1. Quantifiable achievements
            2. Leadership/initiative shown
            3. Business impact
            4. Technical expertise demonstrated
            5. Problem-solving abilities

            Return only a JSON array of 5 strings.`,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.7,
        max_tokens: 500,
      });

      let jsonString = completion.choices[0].message.content.trim();
      jsonString = jsonString
        .replace(/```json\n?/g, "")
        .replace(/```\n?/g, "")
        .trim();

      let suggestions = JSON.parse(jsonString);

      if (!Array.isArray(suggestions)) {
        throw new Error("AI response is not an array");
      }

      setImprovedPoints(suggestions);

      if (payment?.paymentStatus !== "success") {
        updateCredits(credits - 1);
      }
    } catch (error) {
      console.error("Error generating improvements:", error);
    } finally {
      setLoadingAi(false);
    }
  };

  const handleVersionSelect = (point, index) => {
    setSelectedVersion(index);
    onUpdateValue(point);
  };

  return (
    <div className={styles.improvements_section}>
      <div className={styles.enhancementHeader}>
        <div className={styles.headerContent}>
          <div className={styles.titleGroup}>
            <h4>
              <RiMagicLine className={styles.titleIcon} />
              AI Enhancement Studio
            </h4>
            <div className={styles.pillBadge}>
              <span className={styles.dot} />
              Pro Feature
            </div>
          </div>

          <p className={styles.subtitle}>
            Transform your content with AI-powered improvements
          </p>
        </div>

        <motion.button
          className={styles.enhanceButton}
          onClick={generatePoint}
          disabled={loadingAi}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          {loadingAi ? (
            <div className={styles.loadingState}>
              <div className={styles.loadingRipple}>
                <div></div>
                <div></div>
              </div>
              <span>Enhancing your content...</span>
            </div>
          ) : (
            <div className={styles.buttonInner}>
              <div className={styles.buttonContent}>
                <RiMagicLine className={styles.buttonIcon} />
                <span className={styles.buttonText}>
                  {improvedPoints.length > 0 ? (
                    <>
                      <motion.span
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className={styles.reenhanceText}
                      >
                        Reenhance
                        <motion.span
                          className={styles.sparkle}
                          animate={{
                            rotate: [0, 360],
                            scale: [1, 1.2, 1],
                          }}
                          transition={{
                            duration: 2,
                            repeat: Infinity,
                            ease: "linear",
                          }}
                        >
                          ✨
                        </motion.span>
                      </motion.span>
                    </>
                  ) : (
                    "Enhance with AI"
                  )}
                </span>
              </div>
              {payment?.paymentStatus !== "success" && (
                <div className={styles.creditCounter}>
                  <motion.div
                    className={styles.creditBadge}
                    whileHover={{ scale: 1.05 }}
                  >
                    <span className={styles.creditAmount}>{credits}</span>
                    <span className={styles.creditLabel}>credits</span>
                  </motion.div>
                </div>
              )}
            </div>
          )}
        </motion.button>
      </div>

      <AnimatePresence>
        {improvedPoints.length > 0 && (
          <motion.div
            className={styles.improvements_container}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            {improvedPoints.map((point, index) => (
              <motion.div
                key={index}
                className={styles.improvement_card}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  delay: index * 0.1,
                  type: "spring",
                  stiffness: 100,
                }}
              >
                <div className={styles.improvement_content}>
                  <div className={styles.improvement_header}>
                    <span className={styles.version_badge}>
                      Version {index + 1}
                    </span>
                    {/* <div className={styles.metrics_indicators}>
                      {point.includes('%') && 
                        <motion.span 
                          className={`${styles.metric_badge} ${styles.percentage}`}
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ delay: index * 0.1 + 0.2 }}
                        >
                          %
                        </motion.span>
                      }
                      {/\d+/.test(point) && 
                        <motion.span 
                          className={`${styles.metric_badge} ${styles.number}`}
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ delay: index * 0.1 + 0.3 }}
                        >
                          #
                        </motion.span>
                      }
                      {point.includes('$') && 
                        <motion.span 
                          className={`${styles.metric_badge} ${styles.currency}`}
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ delay: index * 0.1 + 0.4 }}
                        >
                          $
                        </motion.span>
                      }
                    </div> */}
                  </div>
                  <p>{point}</p>
                </div>
                <motion.button
                  className={`${styles.use_button} ${
                    selectedVersion === index ? styles.selected : ""
                  }`}
                  onClick={() => handleVersionSelect(point, index)}
                  whileHover={{
                    scale: selectedVersion === index ? 1 : 1.05,
                    backgroundColor:
                      selectedVersion === index ? "#4A3A8A" : "#6B4DC4",
                  }}
                  whileTap={{ scale: 0.95 }}
                  initial={false}
                  animate={{
                    backgroundColor:
                      selectedVersion === index ? "#4A3A8A" : "#ffffff",
                    color: selectedVersion === index ? "#ffffff" : "#6B4DC4",
                    border:
                      selectedVersion === index
                        ? "2px solid #4A3A8A"
                        : "2px solid #6B4DC4",
                  }}
                  transition={{
                    duration: 0.2,
                  }}
                >
                  {selectedVersion === index ? (
                    <div className={styles.selected_content}>
                      <FontAwesomeIcon
                        icon={faCheck}
                        className={styles.check_icon}
                      />
                      <span> Version Selected</span>
                    </div>
                  ) : (
                    "Use This Version"
                  )}
                </motion.button>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <Model modalShow={modalpaymentshow} setModalShow={setModalPaymentShow} />
    </div>
  );
};
