import React, { useState } from "react";
import styles from "./ResumeBuilder.module.css";
import { useJobs } from "./components/context/Jobs";
import { ChevronDown, ChevronUp, Crown, Sparkles } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./components/context/auth";
import { toast } from "react-hot-toast";
import { motion, AnimatePresence } from "framer-motion";
import { useCredits } from "./components/context/CreditsContext";

const RightTop = ({ image, auth }) => {
  const [active, setActive] = useState(false);
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { credits } = useCredits();
  const { payment } = useJobs();
  const Option = ({ label, isActive, onClick }) => (
    <div
      className={`${styles.ddi} ${isActive ? styles.ddiactive : ""}`}
      onClick={onClick}
    >
      <span className={styles.profileEachButton}>{label}</span>
    </div>
  );


  const resumeOptions = [
    { path: "/welcome", label: "Dashboard" },
    { path: "/jobs-in-india", label: "Jobs" },
    { path: "/resume-score", label: "Resume Score" },
    { path: "/pricing", label: "Pricing" },
  ];
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successful!!");
    navigate("/");
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { type: "spring", stiffness: 500, damping: 30 }
    },
    hover: { 
      scale: 1.02,
      transition: { type: "spring", stiffness: 400, damping: 17 }
    }
  };

  const iconVariants = {
    initial: { rotate: 0 },
    animate: { 
      rotate: [0, -10, 10, -10, 10, 0],
      transition: { 
        duration: 2,
        repeat: Infinity,
        repeatType: "loop",
        ease: "easeInOut"
      }
    }
  };

  const sparkleContainerVariants = {
    initial: { backgroundColor: 'transparent' },
    hover: { 
      backgroundColor: '#8061DD',
      color: 'white',
      transition: { duration: 0.2 }
    }
  };

  const noCreditsTextVariants = {
    initial: { opacity: 1, x: 0 },
    animate: {
      x: [0, -3, 3, -3, 3, 0],
      transition: {
        duration: 0.5,
        repeat: Infinity,
        repeatDelay: 3,
        ease: "easeInOut"
      }
    }
  };

  const textHighlightVariants = {
    initial: { color: "inherit" },
    animate: {
      color: ["inherit", "#ff4444", "inherit"],
      transition: {
        duration: 1.5,
        repeat: Infinity,
        repeatDelay: 2,
        ease: "easeInOut"
      }
    }
  };

  return (
    <div className={styles.dldo}>
      <div className={styles.leftdo}>
        {resumeOptions.map((option) => (
          <Option
            key={option.id}
            label={option.label}
            onClick={() => navigate(option.path)}
          />
        ))}
      </div>
      <div className={styles.rightdo}>
        <AnimatePresence mode="wait">
          <motion.div 
            className={styles.sparkleContainerMian}
            initial="hidden"
            animate="visible"
            whileHover="hover"
            variants={containerVariants}
          >
            {payment?.paymentStatus === "success" ? (
              <motion.div 
                className={`${styles.sparkleContainer} ${styles.premiumContainer}`}
                layout
              >
                <motion.div
                  variants={iconVariants}
                  initial="initial"
                  animate="animate"
                  style={{ marginRight: "8px" }}
                >
                  <Crown size={18} className={styles.crownIcon} />
                </motion.div>
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                   Elite User
                </motion.span>
              </motion.div>
            ) : (
              <motion.div 
                className={styles.sparkleContainerMian}
                onClick={() => credits === 0 && navigate("/pricing")}
              >
                <motion.div 
                  className={styles.sparkleContainer}
                  variants={sparkleContainerVariants}
                  initial="initial"
                  whileHover="hover"
                >
                  <motion.img
                    src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730053517/all/axclicveze1ms96nrkww.svg"
                    alt="credits"
                    whileHover={{ filter: 'brightness(0) invert(1)' }}
                  />
                  {credits === 0 ? (
                    <motion.span
                      variants={noCreditsTextVariants}
                      initial="initial"
                      animate="animate"
                      whileHover={{ scale: 1.05 }}
                    >
                      <motion.span
                        variants={textHighlightVariants}
                        initial="initial"
                        animate="animate"
                      >
                        No Credits Left - Upgrade Now
                      </motion.span>
                    </motion.span>
                  ) : (
                    <span>{credits} Credits Left</span>
                  )}
                </motion.div>
              </motion.div>
            )}
          </motion.div>
        </AnimatePresence>
        <div className={styles.allos}>
          <div className={styles.buttonsall} onClick={() => setActive(!active)}>
            <div className={styles.profileEachButton}>
              <img src={image} alt="profileImage" />
              {auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname}
              {active ? (
                <ChevronUp size={15} style={{ marginLeft: "4px" }} />
              ) : (
                <ChevronDown size={15} style={{ marginLeft: "4px" }} />
              )}{" "}
            </div>
          </div>

          {active && (
            <div className={styles.profileButtonMainContainer}>
              <div className={styles.ProfileButtonBox}>
                <span className={styles.profileEachButton} onClick={() => navigate("/welcome/account")}> Your Profile </span>
              </div>

              {/* <div className={styles.ProfileButtonBox}>
                <span className={styles.profileEachButton}  onClick={() => navigate("/welcome/settings")} > Settings </span>
              </div>  */}
              <div className={styles.hr}> </div>
              <div className={`${styles.ProfileButtonBox} ${styles.logoutContainer}`} onClick={handleLogout}> 
                <span className={`${styles.profileEachButton} ${styles.logout}`}> Logout </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RightTop;
