import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/Dashboard/resume.module.css";
import { Copy, Download, Pen, Plus, Trash2 } from "lucide-react";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../ResumeBuilder/builder/components/context/auth";
import { motion } from "framer-motion";
import { privateApi } from "../../ResumeBuilder/utils/aixos";
const Resume = ({modalShow , setModalShow , SecondmodalShow , setSecondModalShow}) => {
  const containerRef = useRef(null); // Reference to the scrollable container
  const [open, setOpen] = useState(false);
  const [data , setData] = useState([]);
  const Navigate = useNavigate(); 
  const {auth} = useAuth();
  const [loading, setLoading] = useState(true);
  // Scroll handler
  const {handleTemplateChange , setValues , templates } = useResume();
  const navigate = useNavigate();
   const getResume = async () =>{ 
    
    try {
      setLoading(true);
      const {data} = await privateApi.get(`/api/resume/getresume`);
      setData(data?.result);
      // count number of words in resume_values
      const words = data?.result[0]?.resume_values?.split(/\s+/g).length;
      // console.log( "ths" ,  words);
       
    } finally {
      setLoading(false);
    }
   }

   const handleDelete = async (id) => { 
    try {
      await privateApi.delete(`/api/resume/deleteresume/${id}`);
      getResume();
    } catch (error) {
      console.error('Error deleting resume:', error);
    }
  }     
  const handleScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 100, // Adjust the scroll distance as needed
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const handleChange = async(templateKey , templateName , id , resume_values) => {
     const parsedData = JSON.parse(resume_values);
     setValues(parsedData);
    handleTemplateChange(templateKey);
    Navigate(`/editor/${templateName}-${templateKey}-${id}`);
  }; 

  const timeformat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const imagerednder = (image, templateKey) => { 
    if (image) {
      return image;
    } 
    else { 
      return  `https://myfuse.in/${image}`;
    }
  }

  const countPercentage = (resume_values) => {
    const words = resume_values?.split(/\s+/g).length;
    const totalWords = 400;
    const percentage = (words / totalWords) * 100;
    const cappedPercentage = Math.min(percentage, 100); // Ensure percentage doesn't exceed 100 and round to nearest integer
    return Math.round(cappedPercentage);
  }
  useEffect(() => {
    getResume();
  }, []);
  return (
    <div className={styles.main}>
      <div className={styles.headingContainer}>
        <div className={styles.headingtitle}>Resume </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.eachContainermain}>
          <div className={styles.eachContTitle}> MAKE A NEW RESUME</div>
          <div className={styles.downContainer}>
            <div className={styles.innerDownContainer} onClick={() => setModalShow(true)}>
              <div className={styles.byparLeft}>
                Create Resume using AI Resume
              </div>
              <div className={styles.byparRight}>
                <img
                  src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729257945/all/gt0bpcxtsllahwg5bdsf.svg"
                  alt="d"
                />
              </div>
            </div>

            <div className={styles.innerDownContainer} onClick={() => navigate("/choose-template")}>
              <div className={styles.byparLeft}>Create from Template</div>
              <div className={styles.byparRight}>
                <img
                  src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729257945/all/gt0bpcxtsllahwg5bdsf.svg"
                  alt="d"
                />
              </div>
            </div>

            <div className={styles.innerDownContainer}>
              <span className={styles.comingSoonBadge}>Coming Soon</span>
              <div className={styles.byparLeftComingSoon}>
                Upload resume from local
              </div>
              {/* <div className={styles.byparRight}>
                <img
                  src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729257945/all/gt0bpcxtsllahwg5bdsf.svg"
                  alt="d"
                />
              </div> */}
            </div>
          </div>
        </div>

        <div className={styles.mobileContainerButton}>
          <div className={styles.createButton} onClick={() => setOpen(!open)}>
            <Plus size={15} /> Create New Resume
          </div>
          {open && (
            <div className={styles.dropdown}>
              <div className={styles.eachBox} onClick={() => setModalShow(true)}>
                Create Resume using AI Resume
              </div>
              <div className={styles.eachBox} onClick={() => navigate("/choose-template")}>Create from Template</div>
              <div className={styles.eachBox}>Upload resume from local</div>
            </div>
          )}
        </div>

        <div className={styles.ScrollContainer} ref={containerRef}>
          {loading ? (
            // Loading skeleton cards
            Array(4).fill(0).map((_, index) => (
              <div className={`${styles.eachContainer} ${styles.skeleton}`} key={`skeleton-${index}`}>
                <div className={styles.eachcontainerUp}>
                  <div className={styles.skeletonImage} />
                </div>
                <div className={styles.eachcontainerDown}>
                  <div className={styles.eachdowninte}>
                    <div className={styles.left}>
                      <div className={styles.skeletonTitle} />
                      <div className={styles.skeletonDate} />
                    </div>
                    <div className={styles.Right}>
                      <div className={styles.skeletonPercent} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : data?.length === 0 ? (
            <motion.div 
              className={styles.noDataContainer}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <motion.div 
                className={styles.noDataContent}
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.3, type: "spring", stiffness: 100 }}
              >
                <motion.img 
                  src="https://cdni.iconscout.com/illustration/premium/thumb/empty-state-4790899-3989037.png"
                  alt="No resumes found"
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: "spring", stiffness: 200 }}
                />
                <motion.h3
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  No Resumes Yet
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.7 }}
                >
                  Create your first resume and start your journey!
                </motion.p>
                <motion.button 
                  className={styles.createFirstButton}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setModalShow(true)}
                >
                  <Plus size={18} />
                  Create First Resume
                </motion.button>
              </motion.div>
              
              <motion.div className={styles.backgroundElements}>
                {[...Array(6)].map((_, i) => (
                  <motion.div
                    key={i}
                    className={styles.floatingResume}
                    initial={{ 
                      x: Math.random() * window.innerWidth,
                      y: Math.random() * window.innerHeight,
                      rotate: Math.random() * 360
                    }}
                    animate={{
                      x: Math.random() * window.innerWidth,
                      y: Math.random() * window.innerHeight,
                      rotate: Math.random() * 360
                    }}
                    transition={{
                      duration: 15 + Math.random() * 10,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "linear"
                    }}
                  >
                    <div className={styles.resumeHeader} />
                    <div className={styles.resumeContent}>
                      {[...Array(3)].map((_, index) => (
                        <div key={index} className={styles.resumeLine} />
                      ))}
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
          ) : (
            // Existing card rendering code
            data?.toReversed().map((item, index) => (
              <div className={styles.eachContainer} key={index}>
                <div className={styles.overlay}></div> 
                <div className={styles.hoverButtons}>
                  <button onClick={() => handleChange(item?.template_key , item?.template_name , item?.id , item?.resume_values)}>
                    <Pen size={14} color={"#806EED"} /> Edit
                  </button>
                  {/* <button>
                    <Download size={14} color={"#806EED"} /> Download
                  </button>
                  <button>
                    <Copy size={14} color={"#806EED"} /> Duplicate
                  </button> */}
                  <button onClick={() => handleDelete(item?.id)}>
                    <Trash2 size={14} color={"#806EED"} /> Delete
                  </button>
                </div>
                <div className={styles.eachcontainerUp}>
                  <img src={imagerednder(item?.template_image , item?.template_key)} alt={item?.template_name} />
                </div>
                <div className={styles.eachcontainerDown}>
                  <div className={styles.eachdowninte}>
                    <div className={styles.left}>
                      <span className={styles.ttitle}>{item?.template_name}</span>


                      <span className={styles.dore}>{timeformat(item?.timestamp)}</span>
                    </div>
                    <div className={styles.Right}>
                      <span className={styles.percentround}>{countPercentage(item?.resume_values)}%</span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        <div className={styles.scroll}>
          <img
            onClick={handleScroll}
            src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729442516/all/v95xfskcuxn9hcz35kvb.svg"
            alt="next"
          />
        </div>
      </div>
      
    </div>
  );
};

export default Resume;
