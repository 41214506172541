import React from "react";
import styles from "./MITResume.module.css";
import { getSafeMarkdownString } from "../../../../utils/markdown";
import { AtSign, Calendar, GitBranchPlusIcon, GitGraphIcon, Github, Mail, MapPin, Paperclip, Phone } from "lucide-react";

const MITResume = ({ sections }) => {
  return (
    <div className={styles.resume}> 
    
      <Header profile={sections.profile.data} />
      <About Professionalsummary={sections.Professionalsummary.data} />
      <Experience experience={sections.experience?.data} />
      <Projects projects={sections.projects?.data} />
      <Education education={sections.education?.data} />
      <TechnicalSkills technicalSkills={sections.technicalSkills?.data} />
      <Awards awards={sections.awards?.data} />
      <Optional optional={sections.optional?.data} />
    </div>
  );
};

const Header = ({ profile }) => {
  if (!profile) return null;
  return (
    <div className={styles.headerContainer}>
      <div className={styles.nameashu}>{profile.name}</div> 
      <div className={styles.nameashudown}>{profile.position}</div>
      <div className={styles.resumesampletexttop}>
      <span> {profile.phone ?<Phone size={12}/> : ""  }   <span className={styles.bold}>{profile.phone}</span>   </span> 
         <span>{profile.email ?<AtSign size={12}/> : ""  } <span className={styles.bold}>{profile.email }</span> </span>   
         <span>  {profile.linkedinUsername ?<Paperclip size={12}/> : ""  }  <span className={styles.bold}>{profile.linkedinUsername}</span> </span> 
      </div>
      <div className={styles.resumesampletexttop2} style={{marginTop:"5px"}}>
        <span>  {profile.githubUsername ?<Github size={12}/> : ""  }  <span className={styles.bold}>{profile.githubUsername}</span> </span> 
         <span > {profile.address ?<MapPin size={12}/> : ""  } <span className={styles.bold}>{profile.address} </span> </span>   
      </div>
      
    </div>
  );
};

const About = ({ Professionalsummary }) => {
  if (!Professionalsummary ) return null;
  return (
    <div>
      <div className={styles.secondline}>
        <span className={styles.postionashu}> PROFESSIONAL SUMMARY </span> 
        <div className={styles.hr}></div>
        <span className={styles.resumesampletextabout}  dangerouslySetInnerHTML={{ __html: Professionalsummary?.summary }} />
      </div>
    </div>
  );
};

const Points = ({ points, years, markdown = true, secondary }) => {
  if (!points) return null;
  return (
    <ul className={styles.points + " " + (secondary ? styles.secondary : null)}>
      {points?.map((point, index) => (
        <li key={index} className={styles.li}>
          {markdown ? (
            <>
              <div
                key={index}
                className={styles.pointsMarkdown}
                dangerouslySetInnerHTML={{
                  __html: getSafeMarkdownString(point),
                }}
              />
              {years && <i>{years[index]}</i>}
            </>
          ) : (
            <>
              <div>{point}</div>
              {years && <i>{years[index]}</i>}
            </>
          )}
        </li>
      ))}
    </ul>
  );
};

const Experience = ({ experience }) => {
  if (!experience || experience.length === 0) return null;
  return (
    <div >
       <span className={styles.postionashu}> PROFESSIONAL EXPERIENCE </span> 
      <div className={styles.hr}></div>
      {experience?.map((exp, index) => (
        <div className={index > 0 ? styles.bordertop : ''} key={index}>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh2}>{exp.role}</h3>
          </div>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh3}>
              <span> {exp.company} </span>{" "}
            </h3>
          </div>
           <div className={styles.bew}>
           <span> {exp.timePeriod ?<Calendar size={12}/> : ""  }    <span className={styles.resumesampletextt}>{exp.timePeriod}</span> </span>  
            <span>  {exp.location ?<MapPin size={12}/> : ""  }  <span className={styles.resumesampletextt}>{exp.location}</span></span> 
            </div>
          <div>
            <Points points={exp.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Projects = ({ projects }) => {
  if (!projects || projects.length === 0) return null;
  return (
    <div>
      
      <span className={styles.postionashu}> PROJECTS </span> 
      <div className={styles.hr}></div>
      {projects?.map((project, index) => (
        <div className={index > 0 ? styles.bordertop : ''} key={index}>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh2}>{project.title}</h3>
           
          </div>
          <div className={styles.her}>
            <h3 className={styles.resumesampleh3}>
              <span> {project.organisation} </span>{" "}
            </h3>
            <span className={styles.resumesampletextt}></span>
          </div>

          <div className={styles.bew}>
           <span> {project.year ?<Calendar size={12}/> : ""  }    <span className={styles.resumesampletextt}>{project.year}</span> </span>  
            
            </div>

          <div>
            <Points points={project.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Education = ({ education }) => {
  if (!education || education.length === 0) return null;
  return (
    <div>
       
      <span className={styles.postionashu}> EDUCATION </span> 
      <div className={styles.hr}></div>
      {education.map((edu, index) => (
        <span key={index} className={styles.doro}>
          <div className={styles.edud}>
            <span className={styles.resumesampleh2}>{edu.college}</span>
            <span>{edu.year}</span>
          </div>
          <div className={styles.edud}>
            <span>
              {" "}
              {edu.degree} {edu.branch ? `in ${edu.branch} ` : " "}{" "}
            </span>
            <span>
              {edu.cgpa
                ? `${edu.cgpa}`
                : "" || edu.percentage
                ? `${edu.percentage}%`
                : ""}
            </span>
          </div>
        </span>
      ))}
    </div>
  );
};

const TechnicalSkills = ({ technicalSkills }) => {
  if (!technicalSkills || technicalSkills.length === 0) return null;
  return (
    <div>
      
      <span className={styles.postionashu}> SKILLS </span> 
      <div className={styles.hr}></div>
      {technicalSkills.map((tech, index) => (
        <div className={styles.skillItem} key={index}>
          <strong>{tech.name}</strong> {tech.name ? ":" : ""}  
          <span >{tech.skills?.join(",")}</span>
        </div>
      ))}
    </div>
  );
};

const Awards = ({ awards }) => {
  if (!awards || awards.length === 0) return null;
  return (
    <>
      <span className={styles.postionashu}> AWARDS </span> 
      <div className={styles.hr}></div>
      <div>
        <Points
          points={awards.map((point) => point.description)}
          years={awards.map((point) => point.year)}
          markdown={true}
        />
      </div>
    </>
  );
};

const Optional = ({ optional }) => {
  if (!optional || optional.length === 0) return null;
  return (
    <div>
      {optional.map((item, index) => (
        <>
        <span className={styles.postionashu}> {item.name}</span>
        <div className={styles.hr}></div>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          ></div>
        </>
      ))}
    </div>
  );
};

export default MITResume;
