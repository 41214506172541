import React, { useEffect, useState } from "react";
import {
  BriefcaseBusinessIcon,
  ClipboardList,
  FilePen,
  Grid2X2,
  Home,
  LockKeyhole,
  LogOut,
  Menu,
  MessageCircleHeart,
  MessageSquareMore,
  School,
  Settings,
  User,
  X,
} from "lucide-react";
import styles from "../../assets/Navbar.module.css";
import { toast } from "react-hot-toast";
import { useAuth } from "../../ResumeBuilder/builder/components/context/auth";
import { useNavigate } from "react-router-dom";
const MobileNav = ({ imgSrc }) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const { auth, setAuth } = useAuth();
  const [loginok, setloginok] = useState(false);
  const navigate = useNavigate();
  const toggelNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successful!!");
    navigate("/");
  };

  useEffect(() => {
    if (auth.token !== "") {
      setloginok(true);
    }
  }, [auth]);
  return (
    <div>
      {" "}
      <div className="d-lg-none d-md-flex flex-col  justify-content-end ">
        <span onClick={toggelNavbar}>
          {
            mobileDrawerOpen ? (
              <X />
            ) : (
              <Menu
                style={{ color: "black", border: "none" }}
              />
            )
          }
          {}
        </span>

        <div
          className={`${styles.mobileNav} ${
            mobileDrawerOpen ? styles.open : ""
          }`}
        >
          <div
            className={`${
              mobileDrawerOpen ? styles.blankSpace : styles.blankSpaceClose
            }`}
            onClick={() => setMobileDrawerOpen(false)}
          ></div>

          <div className={styles.ul}>
            <div className={`${styles.heading} ${styles.headingunder}`}>
              MyFuse<span style={{ color: "#f96f59" }}>.</span>In
            </div>
            {loginok && (
              <div className={styles.mainsim}>
                <div className={styles.imageSection}>
                  <img
                    src={
                      auth?.user?.[0]?.images ? auth?.user?.[0]?.images : imgSrc
                    }
                    alt="sfas"
                  />
                  <div className={styles.wave2}></div>
                </div>

                <div className={styles.textsecion}>
                  <span className={styles.nameofmobile}>
                    {auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname}{" "}
                  </span>
                  <span className={styles.email}>
                    {" "}
                    {auth?.user?.[0]?.email}{" "}
                  </span>
                  <span
                    onClick={() => navigate("/welcome/account")}
                    className={styles.viewandupdate}
                  >
                    {" "}
                    View & Update Profile
                  </span>
                </div>
              </div>
            )}

            <div className={styles.listitemmain}>
              {!loginok && (
                <>
                  <div onClick={() => navigate("/login")}>
                    {" "}
                    <LockKeyhole
                      size={16}
                      color={"#717b9e"}
                      style={{ marginTop: "-5px" }}
                    />{" "}
                    Login
                  </div>
                  <div onClick={() => navigate("/signup")}>
                    {" "}
                    <User
                      size={16}
                      color={"#717b9e"}
                      style={{ marginTop: "-5px" }}
                    />{" "}
                    Register
                  </div>
                </>
              )}
              <div onClick={() => navigate("/")}>
                {" "}
                <Home
                  size={16}
                  color={"#717b9e"}
                  style={{ marginTop: "-5px" }}
                />{" "}
                Home
              </div>
              <div onClick={() => navigate("/jobs")}>
                {" "}
                <BriefcaseBusinessIcon
                  size={16}
                  color={"#717b9e"}
                  style={{ marginTop: "-5px" }}
                />{" "}
                Jobs
              </div>
              <div onClick={() => navigate("/welcome")}>
                {" "}
                <Grid2X2
                  size={16}
                  color={"#717b9e"}
                  style={{ marginTop: "-5px" }}
                />{" "}
                Dashboard
              </div>
              <div onClick={() => navigate("/welcome/jobapplication")}>
                {" "}
                <FilePen
                  size={16}
                  color={"#717b9e"}
                  style={{ marginTop: "-5px" }}
                />{" "}
                Applications
              </div>
              <div onClick={() => navigate("/editor")}>
                {" "}
                <ClipboardList
                  size={16}
                  color={"#717b9e"}
                  style={{ marginTop: "-5px" }}
                />{" "}
                AI Resume
              </div>
              <div onClick={() => navigate("/welcome/chat")}>
                {" "}
                <MessageSquareMore
                  size={16}
                  color={"#717b9e"}
                  style={{ marginTop: "-5px" }}
                />{" "}
                Chat for Help
              </div>
              <div>
                {" "}
                <Settings
                  size={16}
                  color={"#717b9e"}
                  style={{ marginTop: "-5px" }}
                />{" "}
                Settings
              </div>
              <div>
                {" "}
                <MessageCircleHeart
                  size={16}
                  color={"#717b9e"}
                  style={{ marginTop: "-5px" }}
                />{" "}
                Feedback and rate
              </div>
              <div>
                {" "}
                <School
                  size={16}
                  color={"#717b9e"}
                  style={{ marginTop: "-5px" }}
                />{" "}
                About Us
              </div>
              {loginok && (
                <div onClick={handleLogout}>
                  {" "}
                  <LogOut
                    size={16}
                    color={"#717b9e"}
                    style={{ marginTop: "-5px" }}
                  />{" "}
                  Logout
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
