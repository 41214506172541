import React from "react";
import styles from "./SideNavbar.module.css";
import { useNavigate } from "react-router-dom";
import { BarChart2, FilePen, FileSearch, Settings } from "lucide-react";
import { useJobs } from "./components/context/Jobs";
const SideNavbar = ({image,auth}) => {
  const navigate = useNavigate();
  const { setActiveResume, activeResume } = useJobs();
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <span className={styles.logo} onClick={()=>navigate("/welcome")}>
          MyFuse<span className={styles.dorcolor}>.</span>In
        </span>
        <div className={styles.subcontainer}>
          <div className={styles.profilecontainer}>
            <div className={styles.imageContainer}>
              <img src={image} alt="profile" className={styles.profileimage} />
              <span onClick={() => navigate("/welcome/account")}>
                <img  src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186563/all/bqrjzzlw6qfepvwn0o3g.svg" alt="pen" className={styles.penimage} />
              </span>
            </div>
          </div>
          <div className={styles.detailsContainer}>
            <span className={styles.name}>{auth?.user?.[0]?.firstname} {auth?.user?.[0]?.lastname} </span>
          </div>
          <div className={styles.navigationContainer}>
            <div className={`${styles.ee} ${activeResume === 1 ? styles.active : ""}`} onClick={() => setActiveResume(1)}>
               <FilePen size={20} />
              <span className={styles.iconLabel}>Editor</span>
              <div className={`${styles.bar} ${activeResume === 1 ? styles.baractive : ""}`}></div>
            </div>
            <div className={`${styles.ee} ${activeResume === 4 ? styles.active : ""}`} onClick={() => setActiveResume(4)}>
              <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729191042/all/lzu9h5j3quekpekqlxm2.svg" alt="Examples" />
              <span className={styles.iconLabel}>Designs </span>
              <div className={`${styles.bar} ${activeResume === 4 ? styles.baractive : ""}`}></div>
            </div>
            <div className={`${styles.ee} ${activeResume === 2 ? styles.active : ""}` } onClick={() => setActiveResume(2)}>
                <BarChart2 size={20} />
              <span className={styles.iconLabel}>Analysis</span>
              <div className={`${styles.bar} ${activeResume === 2 ? styles.baractive : ""}`}></div>
            </div>
            <div className={`${styles.ee} ${activeResume === 3 ? styles.active : ""}`} onClick={() => setActiveResume(3)}   >
                <FileSearch size={20} />
              <span className={styles.iconLabel}>ATS</span>
              <div className={`${styles.bar} ${activeResume === 3 ? styles.baractive : ""}`}></div>
            </div>
            <div className={`${styles.ee} ${activeResume === 5 ? styles.active : ""}`}  onClick={() => setActiveResume(5)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" width="24" height="24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
              </svg>
              <span className={styles.iconLabel} > Recommended Jobs</span>
              <div className={`${styles.bar} ${activeResume === 5 ? styles.baractive : ""}`}></div>
            </div>

            {/* <div className={`${styles.ee} ${activeResume === 6 ? styles.active : ""}`}  onClick={() => setActiveResume(6)}>
              <Settings size={20} />
              <span className={styles.iconLabel} >Settings</span>
              <div className={`${styles.bar} ${activeResume === 6 ? styles.baractive : ""}`}></div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavbar;
