import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/Dashboard/Blog.module.css";
import axios from "axios";
import slugify from "slugify";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const containerRef = useRef(null); // Reference to the scrollable container
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const getAllBlog = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_BACKEND_URL}/api/auth/Blogs`,
        {
          headers: {
            Authorization: "Bearer madebyashutosh",
          },
        }
      );
      setBlogs(data?.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBlog();
  }, []);




  const handleScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 100, // Adjust the scroll distance as needed
        behavior: "smooth", // Smooth scrolling
      });
    }
  };
  const handleMove = async (value, heading) => {
    const slug = `${slugify(heading)}-${value}`;
    navigate(`/blogs/${slug}`);
  };
  return (
    <div className={styles.main}>
      <div className={styles.headingContainer}>
        <div className={styles.headingtitle}>Recent Blog </div>
      </div>
      <div className={styles.Container} ref={containerRef}>
        <div className={styles.scrollableContainer}>
          {blogs.slice(0, 4).map((ele, index) => {
            return (
              <div className={styles.card} key={index}>
                <div className={styles.innerContainer}>
                  <div className={styles.contentContainer}>
                    <img
                      src={ele.img_link}
                      alt={ele.img_link}
                      className="img-fluid"
                    />
                    <div className={styles.contenttext}>
                      <span
                        className={styles.blogheading}
                        onClick={() => handleMove(ele.S_No, ele.blog_heading)}
                      >
                        {" "}
                        {ele.blog_heading}
                      </span>
                      <span className={styles.date}>
                        {new Date(ele.time_stamp).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.verticalBar}></div>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.scroll}>
        <img
          onClick={handleScroll}
          src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729442516/all/v95xfskcuxn9hcz35kvb.svg"
          alt="next"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Blog;
