import React from "react";
import styles from "./LLQResume.module.css";
import { getSafeMarkdownString } from "../../../../utils/markdown";
import { Check, TicketCheck } from "lucide-react";

const LLQResume = ({ sections }) => {
  return ( 
    <div className={styles.main}>
     
    <div className={styles.resume}>
    <Header profile={sections.profile.data} />
     
      <About Professionalsummary={sections.Professionalsummary.data} />
      <TechnicalSkills technicalSkills={sections.technicalSkills?.data} />
      <Awards awards={sections.awards?.data} />
      <Experience experience={sections.experience?.data} />
      <Projects projects={sections.projects?.data} />
      <Education education={sections.education?.data} />
      <Optional optional={sections.optional?.data} />
    </div>
    </div>
  );
};

const sectionTitle = (title) => {
  return (
    <>
      <span className={styles.title}> {title} </span>{" "}
      <div className={styles.hr}></div> 
      
    </>
  );
};

const Header = ({ profile }) => {
  if (!profile) return null;
  return (
    <div className={styles.headerContainer}>
      <div className={styles.nameashucontainer}> 
        <div className={styles.nameashu}>{profile.name}</div> 
      </div>
      <div className={styles.nameashudown}>{profile.position}</div> 
      <div className={styles.hrtop}></div>

      <div className={styles.resumesampletexttop}>
        <span>
          {" "}
          <span className={styles.bold}>{profile.address}</span>{" "}
        </span>

        <span>
          {" "}
          {profile.phone ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.phone}</span>{" "}
        </span>
        <span>
          {profile.email ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.email}</span>{" "}
        </span>
        <span>
          {" "}
          {profile.linkedinUsername ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.linkedinUsername}</span>{" "}
        </span>
        <span>
          {" "}
          {profile.githubUsername ? "|" : ""}{" "}
          <span className={styles.bold}>{profile.githubUsername}</span>{" "}
        </span>
      </div>
      
    </div>
  );
};

 

const About = ({ Professionalsummary }) => {
  if (!Professionalsummary) return null;
  return (
    <div className={styles.aboutContainer}>
      {sectionTitle("Professional Summary")}
      <div className={styles.secondline}>
        <span
          className={styles.resumesampletextabout}
          dangerouslySetInnerHTML={{ __html: Professionalsummary?.summary }}
        />
      </div>
    </div>
  );
};

const Points = ({ points, years, secondary }) => {
  if (!points) return null;
  return (
    <ul className={styles.points}>
      {points?.map((point, index) => (
        <li key={index} className={styles.li}>
          <div className={styles.eeor}>
            <div
              key={index}
              dangerouslySetInnerHTML={{
                __html: getSafeMarkdownString(point),
              }}
            />
            {years && <i>{years[index]}</i>}
          </div>
        </li>
      ))}
    </ul>
  );
};

const Experience = ({ experience }) => {
  if (!experience || experience.length === 0) return null;
  return (
    <div >
      {sectionTitle("PROFESSIONAL EXPERIENCE")}

      {experience?.map((exp, index) => (
        <div key={index} className={styles.experienceContainer}>
          <div className={styles.her}>
            <div className={styles.resumesampleh2}>
              {exp.role} 
              <span className={styles.removefontwidth}> {exp.company ? `, ${exp.company}` : ""}  
               {exp.location ? `, ${exp.location}` : ""}  
                {exp.timePeriod ? `| ${exp.timePeriod}` : ""} </span>{" "}
            </div>
          </div>

          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: exp.description }}
          /> 
          <div className={styles.pointsContainer}>
            <Points points={exp.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Projects = ({ projects }) => {
  if (!projects || projects.length === 0) return null;
  return (
    <div>
      {sectionTitle("PROJECTS")}
      {projects?.map((project, index) => (
        <div className={index > 0 ? styles.bordertop : ""} key={index}>
          <div className={styles.her}>
             
            <div className={styles.resumesampleh2}>
            {project.title}
              <span className={styles.removefontwidth}> {project.organisation ? `, ${project.organisation}` : ""}  
                {project.year ? ` | ${project.year}` : ""} </span>{" "}
            </div>
          </div>
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: project.description }} />
          <div className={styles.pointsContainer} > 
            <Points points={project.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Education = ({ education }) => {
  if (!education || education.length === 0) return null;
  return (
    <div>
      {sectionTitle("EDUCATION")}
      {education.map((edu, index) => (
        <span key={index} className={styles.doro}>
          <div className={styles.edud}>
            <span className={styles.resumesampleh2}>{edu.college}</span>
            <span>{edu.year}</span>
          </div>
          <div className={styles.edud}>
            <span>
              {" "}
              {edu.degree} {edu.branch ? `in ${edu.branch} ` : " "}{" "}
            </span>
            <span>
              {edu.cgpa
                ? `${edu.cgpa}`
                : "" || edu.percentage
                ? `${edu.percentage}%`
                : ""}
            </span>
          </div>
        </span>
      ))}
    </div>
  );
};

const TechnicalSkills = ({ technicalSkills }) => {
  if (!technicalSkills || technicalSkills.length === 0) return null;
  return (
    <div className={styles.technicalSkillsContainer}>
      {sectionTitle("AREAS OF EXPERTISE ")}

      <div className={styles.skillsContainer}>
        {technicalSkills.map((tech, index) => (
          <div key={index} className={styles.skillItem}>
            <span className={styles.skillSet}>
              {tech.skills?.map((skill, idx) => (
                <span
                  key={idx}
                  className={
                    index === 0 ? styles.firstSkillSet : styles.otherSkillSet
                  }
                >
                  <Check size={17} className={styles.ticketcheck} /> {skill}
                </span>
              ))}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const Awards = ({ awards }) => {
  if (!awards || awards.length === 0) return null;
  return (
    <div className={styles.awardContainer}>
      {sectionTitle("Carrier Highlights")}
      <ul className={`${styles.points} ${styles.noBullets}`}>
        {awards.map((award, index) => (
          <li key={index} className={styles.awardsli}>
            <div className={styles.awardpoint}>
              <strong>{award.name ? `${award.name} : ` : ""}</strong>
              <span className={styles.awarddescription} dangerouslySetInnerHTML={{ __html: award.description }} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Optional = ({ optional }) => {
  if (!optional || optional.length === 0) return null;
  return (
    <div>
      {optional.map((item, index) => (
        <>
          {sectionTitle(item.name)}

          <span
            className={styles.resumesampletextabout}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </>
      ))}
    </div>
  );
};

export default LLQResume;
