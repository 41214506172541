import React, { useEffect, useState } from "react";
import styles from "./ResumeBuilder.module.css";
import Preview from "./components/Preview/Preview";
import previewStyles from "./components/Preview/Preview.module.css";
import { useResume } from "./components/context/Resume";
import MyResponsiveNavbar from "../../pages/Navbar";
import { useAuth } from "./components/context/auth";
import { useJobs } from "./components/context/Jobs";
import RightTop from "./RightTop";
import Analysis from "./Analysis";
import Matching from "./Matching";
import Design from "./Design";
import Jobs from "./Jobs";
import { useNavigate, useParams } from "react-router-dom";
import SideNavbar from "./SideNavbar";
import { ArrowLeft, ArrowRight, Settings, X } from "lucide-react";
import { useSocket } from "./components/context/SocketContext";
import { debounce } from 'lodash';
import toast from "react-hot-toast";
import Finish from "./Finish";

const ResumeBuilder = () => {
  const {
    selectedTemplate,
    printContainerRef,
    values,
    setValues,
    handleSectionChange,
    nextSection,
    prevSection,
    currentStep, 
    currentJobRole, 
    handleResumeChange,
  } = useResume();
  const [previewLoading, setPreviewLoading] = useState(true);
  const { activeResume , setActiveResume} = useJobs();
  const { auth } = useAuth();
  const { socket, isConnected } = useSocket();
  const navigate = useNavigate();
  const Build = selectedTemplate?.build;
  const Resume = selectedTemplate?.resume;
  const { key } = useParams(); 
  const [showPreview, setShowPreview] = useState(false); 
  const [activeResumeId, setActiveResumeId] = useState(null);
  const [imgSrc, setImgSrc] = useState(
    "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
  );

  useEffect(() => {
    if (Array.isArray(auth?.user) && auth.user.length > 0) {
      setImgSrc(
        auth.user[0]?.images ||
          "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
      );
    }
  }, [auth]);
 
  useEffect(() => {
    const resumeId = key?.split('-').pop();
    setActiveResumeId(resumeId);
  }, [key])
  useEffect(() => {
    const debouncedEmit = debounce((resumeId, values) => {
      const idToUse = resumeId || activeResumeId;
      
      if (!isConnected || !socket) {
        console.log("Waiting for socket connection...");
        return;
      }

      if (!idToUse || !values) {
        console.log("Missing required data:", { resumeId: idToUse, hasValues: !!values });
        return;
      }

      try {
        socket.emit("edit", {
          resumeId: idToUse,
          values,
        });
      } catch (error) {
        console.error("Error saving changes:", error);
        toast.error("Failed to save changes");
      }
    }, 1000);

    const resumeId = key?.split('-').pop();
    debouncedEmit(resumeId, values);
    
    return () => {
      debouncedEmit.cancel();
    };
  }, [values, socket, isConnected, key, activeResumeId]);

  useEffect(() => {
    if (socket && isConnected && activeResumeId) {
      // Request initial data
      socket.emit("getInitialData", { resumeId: activeResumeId });

      // Listen for initial data response
      socket.on("initialData", (data) => {
        if (data.resumeId === activeResumeId && data.values) {
          // Update your resume values using the context
          setValues(data.values);
        }
      });

      return () => {
        socket.off("initialData");
      };
    }
  }, [socket, isConnected, activeResumeId]);

 
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const NavigationContainer = () => {
    return (
      <div className={styles.navigationContainer}>
        {prevSection && (
          <div
            className={styles.navigationPrev}
            onClick={() => handleSectionChange("prev")}
          >
            <ArrowLeft size={15} /> Previous: {truncateText(prevSection, 15)}
          </div>
        )}

        {nextSection ? (
          <div
            className={styles.navigationNext}
            onClick={() => handleSectionChange("next")}
          >
            Next: {truncateText(nextSection, 15)}{" "}
            <ArrowRight style={{ marginLeft: "3px" }} size={15} />
          </div>
        ) : (
          <div
          className={styles.navigationNext}
          onClick={() =>  setActiveResume(6)}
        >
               Finish
          <ArrowRight style={{ marginLeft: "3px" }} size={15} />
        </div>
        )}
      </div>
    );
  };

  const NavigationContainerMobile = () => {
    return (
      <>
        <div 
          className={styles.floatingPreviewBtn} 
          onClick={() => setShowPreview(true)}
          title="Preview Resume"
        >
          <div className={styles.previewBtnContent}>
            <svg 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                d="M21.92 11.6C19.9 6.91 16.1 4 12 4S4.1 6.91 2.08 11.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8zM12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6zm0-10a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" 
                fill="currentColor"
              />
            </svg>
            <span>Preview</span>
          </div>
        </div>

        <div className={styles.navigationContainerMobile}>
          {prevSection ? (
            <div
              className={styles.navigationPreviewMobile}
              onClick={() => handleSectionChange("prev")}
            >
              <ArrowLeft style={{ marginRight: "3px" }} size={15} />
              Previous
            </div>
          ) : (
            <div
              className={styles.navigationPreviewMobile}
              onClick={() => navigate("/dashboard")}
            >
              <ArrowLeft style={{ marginRight: "3px" }} size={15} />
              Dashboard
            </div>
          )}

          {nextSection ? (
            <div
              className={styles.navigationNextMobile}
              onClick={() => handleSectionChange("next")}
            >
              Next: {truncateText(nextSection, 10)}{" "}
              <ArrowRight style={{ marginLeft: "3px" }} size={15} />
            </div>
          ) : (
            <div
              className={styles.navigationNextMobile}
              onClick={() => setActiveResume(2)}
            >
              Analysis
              <ArrowRight style={{ marginLeft: "3px" }} size={15} />
            </div>
          )}
        </div>
      </>
    );
  };

  const StepContainer = () => {
    return (
      <div className={styles.stepContainer}>
        {/* Filled Dots on the Left */}
        <div className={styles.dotsContainer}>
          {Array.from({ length: currentStep.current - 1 }).map((_, index) => (
            <div key={index} className={styles.filledDot}></div>
          ))}
        </div>

        {/* Step Box in the Center */}
        <div className={styles.StepBox}>
          Step {currentStep.current} / {currentStep.total}
        </div>

        {/* Empty Dots on the Right */}
        <div className={styles.dotsContainer}>
          {Array.from({ length: currentStep.total - currentStep.current }).map(
            (_, index) => (
              <div key={index} className={styles.emptyDot}></div>
            )
          )}
        </div>
      </div>
    );
  };

  const MobileNavbar = () => {
    return (
      <div className={styles.mobilenavMain}>
        <div
          className={styles.backButton}
        
        >
          {" "}
          <ArrowLeft size={15} /> Back
        </div>

        <div>
          <img
            className={styles.profileImage}
            src={
              "https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186227/all/vzw4bjslemzuuvznzdgs.svg"
            }
            alt="profile"
          />
        </div>
      </div>
    );
  };

  const previewContent = () => {
    return (
      <div className={styles.previewContent}>
        <div
          className={styles.previewClose}
          onClick={() => setShowPreview(false)}
        >
          <X size={24} />
        </div>
        <div className={styles.previewContentInner}>
          <Preview template={selectedTemplate} />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.maintop}>
      <div className={styles.lefttop}>
        <SideNavbar auth={auth} image={imgSrc} />
      </div>
      <div className={styles.righttop}>
        <div className={styles.mobilenav}>
          {/* {MobileNavbar()}  */}
          <MyResponsiveNavbar />
          </div>
        <RightTop auth={auth} image={imgSrc} />
        <div className={previewStyles.print_only_resume_page} id="print_content">
          <div
            ref={printContainerRef}
            className={previewStyles.container}
            style={{
              width: "100%",
              padding: selectedTemplate?.page_margins,
            }}
          >
            {Resume && <Resume sections={values.sections} />}
          </div>
        </div>

        <div className={styles.container}>
          <div className={styles.rightt}>
            <Preview setPreviewLoading={setPreviewLoading} previewLoading={previewLoading} template={selectedTemplate} />
          </div>

          <div className={styles.left}>
            {activeResume === 1 && (
              <>
                {StepContainer()} 
                {Build && <Build />} 
                {NavigationContainer()}
                {NavigationContainerMobile()}
                {showPreview && previewContent()}
              </>
            )}

            {activeResume === 2 && <Analysis />}
            {activeResume === 3 && <Matching />}
            {activeResume === 4 && <Design setPreviewLoading={setPreviewLoading} previewLoading={previewLoading}  />}
            {activeResume === 5 && <Jobs  id={activeResumeId}/>}
            {activeResume === 6 && <Finish />}
          </div>
        </div>
      </div>

      {/* <BottomBar /> */}
    </div>
  );
};

export default ResumeBuilder;
