import React, { useEffect, useRef, useState } from "react";
import styles from "../../assets/Dashboard/recomanded.module.css";
import axios from "axios";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
import { Bookmark } from "lucide-react";
import slugify from "slugify";
import { motion } from "framer-motion";
import { privateApi } from "../../ResumeBuilder/utils/aixos";

const Recomanded = () => {
  const containerRef = useRef(null); // Reference to the scrollable container
  const [skillfromdbapi, setskillfromdibapi] = useState(""); // Initialize as empty string
  const { values } = useResume();
  const [loading, setLoadingJobs] = useState(false);
  const datalocal = JSON.parse(localStorage.getItem("auth"));
  const [jobs, setJobs] = useState([]);
  const resume = values;
  const role = resume?.sections?.technicalSkills?.data?.[0]?.skills || "react";
  const skill_from_resume = Array.isArray(role) ? role.join(",") : ["react"];
  // Merging resume and database skills
  const mergedskill = `${skill_from_resume},${skillfromdbapi}`;

  // Fetch user skills from DB
  const getUserSkill = async () => {
    try {
      const { data } = await privateApi.get(`/api/auth/skillsbyid`);
      if (data?.skillsfromdb?.length > 0) {
        setskillfromdibapi(data.skillsfromdb[0].skills);
      } else {
        console.warn("No skills found in the response");
      }
    } catch (error) {
      console.error("Error fetching user skills:", error);
    }
  };

  // Fetch all jobs based on merged skills
  const getAllJobs = async () => {
    try {
      setLoadingJobs(true);
      const { data } = await privateApi.get(`/api/auth/filterjob?skills=${mergedskill}`);
      if (data?.success) {
        const sortedJobs = [...data.jobs].sort(
          (a, b) => b.percentageMatched - a.percentageMatched
        );
        setJobs(sortedJobs);
      }
      setLoadingJobs(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoadingJobs(false);
    }
  };

  // Navigate to job details
  const handleNavigate = async (value, heading) => {
    const slug = `${slugify(heading)}-${value}`;
    window.open(`/jobs/details/${slug}`, "_blank");
  };

  // Fetch user skills once on component mount
  useEffect(() => {
    if (!datalocal?.token) {
      console.error("No authentication token found in local storage");
      return;
    }
    getUserSkill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch jobs when skills from DB are updated
  useEffect(() => {
    if (skillfromdbapi) {
      getAllJobs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillfromdbapi]); // Only trigger when skills from DB are updated

  const handleScroll = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 200, // Adjust the scroll distance as needed
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const SkeletonCard = () => (
    <motion.div 
      className={styles.recomandedCard} 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ background: '#f9fafb' }}
    >
      <div className={styles.cardImage}>
        <motion.div 
          animate={{ opacity: [0.5, 1, 0.5] }}
          transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
          style={{
            width: '40px',
            height: '40px',
            background: '#e5e7eb',
            borderRadius: '8px',
          }} 
        />
      </div>
      <div className={styles.cardDetails}>
        <motion.div 
          animate={{ opacity: [0.5, 1, 0.5] }}
          transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
          style={{
            width: '80%',
            height: '16px',
            background: '#e5e7eb',
            borderRadius: '4px',
            marginBottom: '8px',
          }} 
        />
        <motion.div 
          animate={{ opacity: [0.5, 1, 0.5] }}
          transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
          style={{
            width: '60%',
            height: '14px',
            background: '#e5e7eb',
            borderRadius: '4px',
          }} 
        />
      </div>
    </motion.div>
  );

  const EmptyState = () => (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '1rem 2rem',
        background: 'linear-gradient(to right, #f9fafb, #ffffff)',
        borderRadius: '12px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.06)',
        width: '96%',
        height: '137px',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {/* Floating Background Elements - Reduced size and quantity */}
      {[...Array(3)].map((_, index) => (
        <motion.div
          key={index}
          style={{
            position: 'absolute',
            background: `rgba(79, 70, 229, ${0.02 + (index * 0.01)})`,
            borderRadius: '50%',
            zIndex: 0,
            width: '30px',
            height: '30px',
            ...{
              0: { top: '20%', right: '10%' },
              1: { bottom: '20%', right: '30%' },
              2: { top: '40%', right: '20%' }
            }[index]
          }}
          animate={{
            y: [0, -10, 0],
            x: [0, 5, 0],
            scale: [1, 1.1, 1],
          }}
          transition={{
            duration: 4 + index * 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />
      ))}

      {/* Main Content */}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1.5rem',
        zIndex: 1
      }}>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ 
            delay: 0.2, 
            type: "spring", 
            stiffness: 200,
            damping: 15
          }}
        >
          <div style={{ 
            background: '#EEF2FF',
            padding: '0.75rem',
            borderRadius: '50%',
          }}>
            <svg
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#4F46E5"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
            </svg>
          </div>
        </motion.div>

        <div style={{
          textAlign: 'left'
        }}>
          <motion.h3 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            style={{ 
              margin: '0 0 0.25rem 0', 
              color: '#111827',
              fontSize: '1.125rem',
              fontWeight: '600'
            }}
          >
            No recommendations yet
          </motion.h3>
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            style={{ 
              margin: 0,
              color: '#6B7280',
              fontSize: '0.875rem',
              lineHeight: '1.4',
            }}
          >
            Add skills to get personalized recommendations
          </motion.p>
        </div>
      </div>
    </motion.div>
  );

   

  return (
    <div className={styles.main}>
      <div className={styles.headingContainer}>
        <div className={styles.headingtitle}>Recommended Jobs</div>
      </div>
      <motion.div 
        className={styles.recomandedContainer} 
        ref={containerRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {loading ? (
          // Show 3 skeleton cards while loading
          [...Array(5)].map((_, index) => (
            <SkeletonCard key={index} />
          ))
        ) : jobs.length === 0 ? (
          <EmptyState />
        ) : (
          // Existing jobs mapping with added motion
          jobs?.map((job) => (
            <motion.div 
              key={job.id_jobpost}
              className={styles.recomandedCard}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              whileHover={{ y: -5, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.2 }}
              onClick={() => handleNavigate(job.id_jobpost, job.jobtitle)}
            >
              <div className={styles.cardImage}>
                <img
                  className="img-fluid"
                  src={`https://old.myfuse.in/uploads/logo/${job.companyLogo}`}
                  alt="Company Logo"
                /> 
                <Bookmark 
                  size={16} 
                  color={"#99A3BC"}
                  style={{ transition: 'all 0.2s ease' }}
                  onMouseEnter={(e) => {
                    e.stopPropagation();
                    e.currentTarget.style.color = '#2563eb';
                    e.currentTarget.style.transform = 'scale(1.2)';
                  }}
                  onMouseLeave={(e) => {
                    e.stopPropagation();
                    e.currentTarget.style.color = '#99A3BC';
                    e.currentTarget.style.transform = 'scale(1)';
                  }}
                />
              </div>
              <div className={styles.cardDetails}>
                <div className={styles.cardTitle}>
                  {" "}
                  {job.jobtitle.length > 20
                    ? `${job.jobtitle.substring(0, 20)}...`
                    : job.jobtitle}
                </div>
                <div className={styles.cardCompany}>
                  {job.companyName.length > 20
                    ? `${job.companyName.substring(0, 20)}...`
                    : job.companyName}
                </div>
              </div>
            </motion.div>
          ))
        )}
      </motion.div>

      <div className={styles.scroll}>
        <img
          onClick={handleScroll}
          src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729442516/all/v95xfskcuxn9hcz35kvb.svg"
          alt="next"
        />
      </div>
    </div>
  );
};

export default Recomanded;
