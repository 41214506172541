import React, { useEffect, useRef, useState } from "react";
import styles from "./Config.module.css";
import { useResume } from "../../context/Resume";
import { Trash2, FilePlus2, Plus } from "lucide-react"; 
import { AIPoints, AiDescription, AISkillsPoints, AIImprovisePoints } from "./AI";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RiMagicLine } from 'react-icons/ri'; 
import { motion } from "framer-motion";
import occupationalData from "../../../../utils/occupationalData";

const BuildResume = ({ config }) => {
  const { get, CurentSection } = useResume();
  return (
    <div className={styles.build}>
      <div className={styles.sections}>
        {config.sections.map((section, index) => {
          //  console.log(section.name);
          if (section.type === "static" && section.name === CurentSection) {
            return (
              <StaticSection
                key={index}
                name={section.name}
                fields={section.fields}
                sectionKey={section.key}
                desc={section.description}
                section={get(section.key)}
                displayKey={section.displayKey}
              />
            );
          } else if (
            section.type === "dynamic" &&
            section.name === CurentSection
          ) {
            return (
              <DynamicSection
                key={index}
                name={section.name}
                fields={section.fields}
                sectionKey={section.key}
                section={get(section.key)}
                desc={section.description}
                displayKey={section.displayKey}
              />
            );
          } else if (section.type === "list-string") {
            return (
              <DynamicListString
                key={index}
                name={section.name}
                sectionKey={section.key}
                section={get(section.key)}
                desc={section.description}
                displayKey={section.displayKey}
              />
            );
          } else return null;
        })}
      </div>
    </div>
  );
};

const TipsContainer = () => {
  return (
    <div className={styles.TipsContainer}>
    <div className={styles.tipleft}>
      <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730031255/all/crw1wqjgdwuae4lb7ybc.svg" />
    </div>
    <div className={styles.tipright}>
      <div className={styles.tipheading}>QUICK TIPS</div>
      <div className={styles.tippara}>
        Create an email that includes your name, avoiding nicknames or
        numbers that may seem unprofessional.{" "}
      </div>
      <div className={styles.tipparMobile}>
        Create an email tha t includes your name, ...{" "}
      </div>
    </div>
  </div> 
  );
};

const CertificationSuggestion = () => {
  const { currentJobRole, values } = useResume();
  const defaultCertifications = [
    "Google Project Management Professional Certificate",
    "CompTIA A+ Certification",
    "AWS Certified Cloud Practitioner",
    "Microsoft Office Specialist (MOS)",
    "PMI Project Management Professional (PMP)",
    "Certified Information Systems Security Professional (CISSP)",
    "Hubspot Digital Marketing Certification",
    "Salesforce Certified Administrator"
  ];
  
  const [certifications, setCertifications] = useState(defaultCertifications);

  useEffect(() => {
    try {
      // First check if occupationalData and the required method exist
      if (!occupationalData?.getCertificationsByRole) {
        console.warn('Occupational data or method not available');
        setCertifications(defaultCertifications);
        return;
      }

      // First try to get position from resume values
      const resumePosition = values?.sections?.profile?.data?.position;
      
      // Then determine which job role to use (position or currentJobRole)
      const jobRole = resumePosition?.trim() || currentJobRole?.trim();

      // Check if we have any valid job role to use
      if (!jobRole) {
        console.warn('No position or job role specified');
        setCertifications(defaultCertifications);
        return;
      }

      // If we have a valid job role, try to get role-specific certifications
      const roleCertifications = occupationalData.getCertificationsByRole(jobRole);
      
      // Verify we got valid certification data back
      if (Array.isArray(roleCertifications) && roleCertifications.length > 0) {
        console.log(`Using certifications for ${resumePosition ? 'position' : 'currentJobRole'}: ${jobRole}`);
        setCertifications(roleCertifications);
      } else {
        console.warn('No valid certifications found for role:', jobRole);
        setCertifications(defaultCertifications);
      }
    } catch (error) {
      console.warn('Error loading role-specific certifications:', error);
      setCertifications(defaultCertifications);
    }
  }, [currentJobRole, values?.sections?.profile?.data?.position]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    // Reset states when certifications change
    setCurrentIndex(0);
    setDisplayText('');
    setIsTyping(true);
  }, [certifications]);

  useEffect(() => {
    // Typing effect
    let currentText = certifications[currentIndex];
    let charIndex = 0;

    if (!currentText) {
      setCurrentIndex(0);
      return;
    }

    const typingInterval = setInterval(() => {
      if (charIndex <= currentText.length) {
        setDisplayText(currentText.substring(0, charIndex));
        charIndex++;
      } else {
        setIsTyping(false);
        clearInterval(typingInterval);
        
        // Wait before moving to next certification
        setTimeout(() => {
          setIsTyping(true);
          setCurrentIndex((prev) => (prev + 1) % certifications.length);
          setDisplayText('');
        }, 2000); // Reduced wait time to 2 seconds
      }
    }, 50); // Typing speed

    return () => {
      clearInterval(typingInterval);
    };
  }, [currentIndex, certifications]);

 

  return (
    <div className={styles.CertificationSuggestion}>
      <div className={styles.CertificationSuggestionHeading}>
        CERTIFICATION SUGGESTIONS
      </div>
      <div className={styles.certificationGrid}>
        <motion.div
          key={currentIndex}
          className={styles.certificationCard}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.5 }}
        >
          <div className={styles.certName}>
            {displayText}
            {isTyping && <span className={styles.cursor}>|</span>}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const StaticSection = ({ name, fields, desc, section }) => { 
  const calculateProgress = () => {
    if (!section || !fields) return { 
      percentage: 0, 
      completed: [], 
      remaining: [],
      totalFields: 0
    };
    
    let completed = [];
    let remaining = [];
    let weightedScore = 0;
    const totalWeight = fields.reduce((sum, field) => sum + (field.weight || 1), 0);
    
    fields.forEach(field => {
      const value = section[field.key?.split(".").pop()];
      const fieldWeight = field.weight || 1;
      
      // Enhanced value validation
      const isValidValue = (value) => {
        if (!value) return false;
        const trimmedValue = value.toString().trim();
        
        // Check minimum length requirements based on field type
        switch (field.type) {
          case 'textarea':
            return trimmedValue.length >= 50; // Requires substantial content
          case 'text':
            return trimmedValue.length >= 3; // Requires meaningful text
          case 'date':
            return true; // Any valid date is acceptable
          case 'list-string':
            return Array.isArray(value) && value.some(item => item.trim().length > 0);
          default:
            return trimmedValue.length > 0;
        }
      };

      if (isValidValue(value)) {
        completed.push({
          label: field.label,
          weight: fieldWeight
        });
        weightedScore += fieldWeight;
      } else {
        remaining.push({
          label: field.label,
          weight: fieldWeight,
          type: field.type
        });
      }
    });
    
    return {
      percentage: (weightedScore / totalWeight) * 100,
      completed,
      remaining,
      totalFields: fields.length,
      weightedScore,
      totalWeight
    };
  };

  const progesscardrender = () => {
    const progress = calculateProgress();
    const { percentage, completed, remaining } = progress;
    const isComplete = percentage === 100;
    
    const getContextualFeedback = () => {
      if (percentage < 40) {
        return {
          status: "Getting Started",
          message: `${remaining.length} essential fields remaining`,
          color: "#FF6B6B"
        };
      } else if (percentage < 70) {
        return {
          status: "Making Progress",
          message: `Looking good! ${remaining.length} fields to go`,
          color: "#FFD93D"
        };
      } else if (percentage < 100) {
        return {
          status: "Almost There",
          message: `Just ${remaining.length} more field${remaining.length === 1 ? '' : 's'}!`,
          color: "#4CAF50"
        };
      } else {
        return {
          status: "All Complete! 🎉",
          message: "Perfect! Your section is complete",
          color: "#4CAF50"
        };
      }
    };

    const feedback = isComplete ? {
      status: "All Complete! 🎉",
      message: "Perfect! Your section is complete",
      color: "#4CAF50"
    } : getContextualFeedback();

    return (
      <motion.div 
        className={styles.progressDashboard}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className={styles.progressMetrics}>
          <motion.div 
            className={`${styles.progressCard} ${isComplete ? styles.completeProgress : 
              styles[`${percentage < 40 ? 'low' : percentage < 70 ? 'medium' : 'high'}Progress`]}`}
            initial={{ scale: 0.95 }}
            animate={{ 
              scale: isComplete ? [1, 1.02, 1] : 1,
              y: isComplete ? [0, -3, 0] : 0
            }}
            transition={{ 
              duration: isComplete ? 1.5 : 0.3,
              repeat: isComplete ? Infinity : 0,
              repeatType: "reverse"
            }}
          >
            <div className={styles.progressRing}>
              <svg width="60" height="60" viewBox="0 0 60 60">
                <circle
                  cx="30"
                  cy="30"
                  r="24"
                  fill="none"
                  strokeWidth="6"
                  className={styles.progressBg}
                />
                <motion.circle
                  cx="30"
                  cy="30"
                  r="24"
                  fill="none"
                  strokeWidth="6"
                  className={`${styles.progressFill} ${isComplete ? styles.completeProgress : 
                    styles[`${percentage < 40 ? 'low' : percentage < 70 ? 'medium' : 'high'}Progress`]}`}
                  style={{
                    transformOrigin: "center",
                    transform: "rotate(-90deg)",
                    strokeDasharray: "151",
                  }}
                  initial={{ strokeDashoffset: 151 }}
                  animate={{ 
                    strokeDashoffset: 151 - (151 * percentage) / 100,
                  }}
                  transition={{ 
                    duration: 1, 
                    ease: "easeInOut",
                    delay: 0.2
                  }}
                />
              </svg>
              
              {isComplete ? (
                <motion.div 
                  className={styles.completeMark}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ 
                    scale: [0, 1.2, 1],
                    opacity: 1
                  }}
                  transition={{ 
                    duration: 0.5,
                    delay: 0.3
                  }}
                >
                  <svg viewBox="0 0 24 24" fill="none" className={styles.checkmark}>
                    <motion.path
                      d="M20 6L9 17L4 12"
                      stroke="#4CAF50"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      initial={{ pathLength: 0 }}
                      animate={{ pathLength: 1 }}
                      transition={{ duration: 0.5, delay: 0.8 }}
                    />
                  </svg>
                </motion.div>
              ) : (
                <motion.div 
                  className={styles.progressValue}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  {Math.round(percentage)}%
                </motion.div>
              )}
            </div>
            
            <div className={styles.progressInfo}>
              <motion.div 
                className={styles.progressStatus}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                {feedback.status}
              </motion.div>
              <motion.div 
                className={styles.remainingItems}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                {isComplete ? (
                  <motion.div 
                    className={styles.celebrationWrapper}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.5 }}
                  >
                    <span className={styles.celebrationText}>
                      {feedback.message}
                    </span>
                    <div className={styles.confetti}>
                      {[...Array(6)].map((_, i) => (
                        <motion.div
                          key={i}
                          className={styles.confettiPiece}
                          initial={{ y: 0, opacity: 0 }}
                          animate={{ 
                            y: [-20, 20],
                            opacity: [0, 1, 0],
                            x: [-10, 10, -10]
                          }}
                          transition={{
                            duration: 2,
                            delay: i * 0.1,
                            repeat: Infinity,
                            repeatType: "reverse"
                          }}
                        />
                      ))}
                    </div>
                  </motion.div>
                ) : (
                  <span>{feedback.message}</span>
                )}
              </motion.div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    );
  };

  return (
    <div className={styles.section}> 
      <div className={styles.headingContainer}>
        <div className={styles.headerTop}>
          <h2>{name}</h2>
          {progesscardrender()}
        </div>
        {desc && <p className={styles.desc}>{desc}</p>}
      </div>
      <TipsContainer />

      <div className={styles.section_fields}>
        {fields.map((field, index) => (
          <Input
            key={index}
            type={field.type}
            label={field.label}
            name={field.name}
            placeholder={field.placeholder}
            options={field.options}
            fields={field.fields}
            inputKey={field.key}
            defaultValue={field.defaultValue}
            value={section[field.key.split(".").pop()]}
            displayKey={field.displayKey}
            element={section}
            partiCularName={name}
          />
        ))}
      </div>
    </div>
  );
};

const DynamicSection = ({
  name,
  fields,
  sectionKey,
  section,
  desc,
  displayKey,
}) => {
  const { handleResumeChange } = useResume();
  const sectionRefs = useRef([]);
  const [showSection, setShowSection] = useState(false);
  useEffect(() => {
    if (!section) return;
    sectionRefs.current = sectionRefs.current.slice(0, section?.length);
  }, [section]);

  const handleElementAdd = (e) => {
    e.stopPropagation();
    const newElement = {};
    fields.forEach((field) => {
      if (field.type === "text" || field.type === "textarea") {
        newElement[field.name] = "";
      }
    });
    handleResumeChange(sectionKey, [...section, newElement]);
  };
  
  const handleElementDelete = (e, index) => {
    e.stopPropagation();
    const element = sectionRefs.current[index];
    element.classList.add(styles.slidefadeout);
    setTimeout(() => {
      handleResumeChange(sectionKey, [
        ...section.slice(0, index),
        ...section.slice(index + 1),
      ]);
    }, 480);
  };

  const calculateProgress = () => {
    if (!section || !fields) return { 
      percentage: 0, 
      completed: [], 
      remaining: [],
      totalFields: 0
    };
    
    let completed = [];
    let remaining = [];
    let weightedScore = 0;
    const totalWeight = fields.reduce((sum, field) => sum + (field.weight || 1), 0) * Math.max(1, section.length);
    
    section.forEach(element => {
      fields.forEach(field => {
        const value = element[field.name];
        const fieldWeight = field.weight || 1;
        
        const isValidValue = (value) => {
          if (!value) return false;
          const trimmedValue = value.toString().trim();
          
          switch (field.type) {
            case 'textarea':
              return trimmedValue.length >= 50;
            case 'text':
              return trimmedValue.length >= 3;
            case 'date':
              return true;
            case 'list-string':
              return Array.isArray(value) && value.some(item => item.trim().length > 0);
            default:
              return trimmedValue.length > 0;
          }
        };

        if (isValidValue(value)) {
          completed.push({
            label: field.label,
            weight: fieldWeight
          });
          weightedScore += fieldWeight;
        } else {
          remaining.push({
            label: field.label,
            weight: fieldWeight,
            type: field.type
          });
        }
      });
    });
    
    return {
      percentage: (weightedScore / totalWeight) * 100,
      completed,
      remaining,
      totalFields: fields.length * section.length,
      weightedScore,
      totalWeight
    };
  };

  const progressCardRender = () => {
    const progress = calculateProgress();
    const { percentage, remaining } = progress;
    const isComplete = percentage === 100;
    
    const getContextualFeedback = () => {
      if (percentage < 40) {
        return {
          status: "Getting Started",
          message: `${remaining.length} essential fields remaining`,
          color: "#FF6B6B"
        };
      } else if (percentage < 70) {
        return {
          status: "Making Progress",
          message: `Looking good! ${remaining.length} fields to go`,
          color: "#FFD93D"
        };
      } else if (percentage < 100) {
        return {
          status: "Almost There",
          message: `Just ${remaining.length} more field${remaining.length === 1 ? '' : 's'}!`,
          color: "#4CAF50"
        };
      } else {
        return {
          status: "All Complete! 🎉",
          message: "Perfect! Your section is complete",
          color: "#4CAF50"
        };
      }
    };

    const feedback = isComplete ? {
      status: "All Complete! 🎉",
      message: "Perfect! Your section is complete",
      color: "#4CAF50"
    } : getContextualFeedback();

    return (
      <motion.div 
        className={styles.progressDashboard}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className={styles.progressMetrics}>
          <motion.div 
            className={`${styles.progressCard} ${isComplete ? styles.completeProgress : 
              styles[`${percentage < 40 ? 'low' : percentage < 70 ? 'medium' : 'high'}Progress`]}`}
            initial={{ scale: 0.95 }}
            animate={{ 
              scale: isComplete ? [1, 1.02, 1] : 1,
              y: isComplete ? [0, -3, 0] : 0
            }}
            transition={{ 
              duration: isComplete ? 1.5 : 0.3,
              repeat: isComplete ? Infinity : 0,
              repeatType: "reverse"
            }}
          >
            <div className={styles.progressRing}>
              <svg width="60" height="60" viewBox="0 0 60 60">
                <circle
                  cx="30"
                  cy="30"
                  r="24"
                  fill="none"
                  strokeWidth="6"
                  className={styles.progressBg}
                />
                <motion.circle
                  cx="30"
                  cy="30"
                  r="24"
                  fill="none"
                  strokeWidth="6"
                  className={`${styles.progressFill} ${isComplete ? styles.completeProgress : 
                    styles[`${percentage < 40 ? 'low' : percentage < 70 ? 'medium' : 'high'}Progress`]}`}
                  style={{
                    transformOrigin: "center",
                    transform: "rotate(-90deg)",
                    strokeDasharray: "151",
                  }}
                  initial={{ strokeDashoffset: 151 }}
                  animate={{ 
                    strokeDashoffset: 151 - (151 * percentage) / 100,
                  }}
                  transition={{ 
                    duration: 1, 
                    ease: "easeInOut",
                    delay: 0.2
                  }}
                />
              </svg>
              
              {isComplete ? (
                <motion.div 
                  className={styles.completeMark}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ 
                    scale: [0, 1.2, 1],
                    opacity: 1
                  }}
                  transition={{ 
                    duration: 0.5,
                    delay: 0.3
                  }}
                >
                  <svg viewBox="0 0 24 24" fill="none" className={styles.checkmark}>
                    <motion.path
                      d="M20 6L9 17L4 12"
                      stroke="#4CAF50"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      initial={{ pathLength: 0 }}
                      animate={{ pathLength: 1 }}
                      transition={{ duration: 0.5, delay: 0.8 }}
                    />
                  </svg>
                </motion.div>
              ) : (
                <motion.div 
                  className={styles.progressValue}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  {Math.round(percentage)}%
                </motion.div>
              )}
            </div>
            
            <div className={styles.progressInfo}>
              <motion.div 
                className={styles.progressStatus}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                {feedback.status}
              </motion.div>
              <motion.div 
                className={styles.remainingItems}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                {isComplete ? (
                  <motion.div 
                    className={styles.celebrationWrapper}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.5 }}
                  >
                    <span className={styles.celebrationText}>
                      {feedback.message}
                    </span>
                    <div className={styles.confetti}>
                      {[...Array(6)].map((_, i) => (
                        <motion.div
                          key={i}
                          className={styles.confettiPiece}
                          initial={{ y: 0, opacity: 0 }}
                          animate={{ 
                            y: [-20, 20],
                            opacity: [0, 1, 0],
                            x: [-10, 10, -10]
                          }}
                          transition={{
                            duration: 2,
                            delay: i * 0.1,
                            repeat: Infinity,
                            repeatType: "reverse"
                          }}
                        />
                      ))}
                    </div>
                  </motion.div>
                ) : (
                  <span>{feedback.message}</span>
                )}
              </motion.div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    );
  };

  return (
    <div className={styles.section}>
      <div className={styles.headingContainer}>
        <div className={styles.headerTop}>
          <h2>{name}</h2>
          {progressCardRender()}
        </div>
        {desc && <p className={styles.desc}>{desc}</p>}
      </div>

      <TipsContainer />
       {/* if section is certification */}
       {name === "Certification" && <CertificationSuggestion />}
      <div className={styles.section_elements}>
        {section?.map((element, _index) => (
          <div
            ref={(el) => (sectionRefs.current[_index] = el)}
            key={_index}
            className={`${styles.section_fields} ${styles.details_collaFpsed} `}
            open
          >
            <summary style={{ width: "100%" }}>
              {/* {element[displayKey] || <span style={{ color: "white" }}>.</span>} */}
              <div className={styles.section_element_actions}>
                <button onClick={(e) => handleElementDelete(e, _index)}>
                  <Trash2 size={15} />
                </button>
              </div>
            </summary>
            <div className={styles.section_element_actions}>
              <button onClick={(e) => handleElementDelete(e, _index)}>
                <Trash2 size={14} />
              </button>
            </div>
            <div className={styles.section_fields_each}>
              {fields?.map(
                (field, index) =>
                  field.type !== "textarea" &&
                  field.type !== "list-string" && ( // Check if field type is not textarea
                    <div className={styles.eachContainer} key={index}>
                      <Input
                        type={field.type}
                        label={field.label}
                        name={field.name}
                        placeholder={field.placeholder}
                        value={element[field.name]}
                        defaultValue={element[field.name] || field.defaultValue}
                        inputKey={`${sectionKey}.${_index}.${field.key}`}
                        index_={_index}
                        options={field.options}
                        fields={field.fields}
                        displayKey={field.displayKey}
                        element={element}
                        partiCularName={name}
                      />
                    </div>
                  )
              )}
            </div>

            <>
              {fields?.map(
                (field, index) =>
                  field.type === "textarea" && ( // Check if field type is \\ textarea
                    <div key={index}>
                      <Input
                        type={field.type}
                        label={field.label}
                        name={field.name}
                        placeholder={field.placeholder}
                        value={element[field.name]}
                        defaultValue={element[field.name] || field.defaultValue}
                        inputKey={`${sectionKey}.${_index}.${field.key}`}
                        index_={_index}
                        options={field.options}
                        fields={field.fields}
                        displayKey={field.displayKey}
                        element={element}
                        partiCularName={name}
                      />
                    </div>
                  )
              )}
            </>

            <>
              {fields?.map(
                (field, index) =>
                  field.type === "list-string" && (
                    <div key={index}>
                      <Input
                        type={field.type}
                        label={field.label}
                        name={field.name}
                        placeholder={field.placeholder}
                        value={element[field.name]}
                        defaultValue={element[field.name] || field.defaultValue}
                        inputKey={`${sectionKey}.${_index}.${field.key}`}
                        index_={_index}
                        options={field.options}
                        fields={field.fields}
                        displayKey={field.displayKey}
                        element={element}
                        partiCularName={name}
                      />
                    </div>
                  )
              )}
            </>
          </div>
        ))}
      </div>
      <div className={styles.section_actions}>
        <span className={styles.addButtons} onClick={handleElementAdd}>
          {" "}
          <Plus color={"#6B4DC4"} size={14} /> Add {name}
        </span>
      </div>
      {/* <div className={styles.hr}></div> */}
    </div>
  );
};

const Input = ({
  type,
  label,
  name,
  placeholder,
  defaultValue,
  value,
  fields,
  inputKey,
  options,
  displayKey,
  index_,
  element,
  partiCularName,
}) => {
  const { handleResumeChange, setLoading } = useResume();
  const handleInputChange = async (e) => {
    if (
      type === "text" ||
      type === "textarea" ||
      type === "title" ||
      type === "dropdown" ||
      type === "date"
    ) {
      // console.log(inputKey)
      handleResumeChange(inputKey, e.target.value);
    } else if (type === "toggle") {
      handleResumeChange(inputKey, e.target.checked);
    }
  };

  const handleInputChangeWithDebounce = (e) => {
    setLoading(true);
    handleInputChange(e);

    // const debounceId = setTimeout(() => {
    //   clearTimeout(debounceId);

    //   handleInputChange(e);
    // }, 1000);
  };

  const updateDescription = (newdescriptiono) => {
    handleResumeChange(inputKey, newdescriptiono);
  };
  switch (type) {
    case "title":
      return (
        <div className={styles.input_text}>
          <input
            className={styles.input_title}
            name={name}
            sectionKey
            type="text"
            id={name}
            placeholder={placeholder}
            defaultValue={value || defaultValue}
            onChange={(e) => {
              handleInputChangeWithDebounce(e);
            }}
          />
        </div>
      );
    case "text":
      return (
        <div className={styles.input_text}>
          <label htmlFor={name}>{label}</label>
          <input
            name={name}
            type="text"
            id={name}
            placeholder={placeholder}
            defaultValue={value || defaultValue}
            onChange={(e) => {
              handleInputChangeWithDebounce(e);
            }}
          />
        </div>
      );
    case "textarea":
      return (
        <div className={styles.input_textarea}>
          <label htmlFor={name}>{label}</label>
          <ReactQuill
            theme="snow"
            value={value || ""}
            onChange={(content) => {
              handleResumeChange(inputKey, content);
            }}
            placeholder={placeholder}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                [{ list: "bullet" }], // superscript/subscript
              ],
            }}
            className={styles.customQuill}
          />
          {!(partiCularName == "Certification") && (
            <AiDescription
              updateDescription={updateDescription}
              element={element}
              sectionKey={inputKey}
              name={name}
              section={value}
            partiCularName={partiCularName}
          />
          )}
        </div>
      );
    case "image":
      return (
        <div className={styles.input_image}>
          <label htmlFor={name}>
            <img src={value} alt="" />
            {label}
          </label>
          <input
            name={name}
            id={name}
            type="file"
            accept="image/*"
            placeholder={placeholder}
            onChange={(e) => {
              handleInputChange(e);
            }}
          />
        </div>
      );
    case "dropdown":
      return (
        <div className={styles.input_dropdown}>
          <label htmlFor={name}>{label}</label>
          <select
            name={name}
            id={name}
            onChange={(e) => {
              handleInputChange(e);
            }}
            defaultValue={value || defaultValue}
          >
            {placeholder && <option value="">{placeholder}</option>}
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option}
              </option>
            ))}
          </select>
        </div>
      );
    case "list-string":
      return (
        <DynamicListString
          name={label}
          desc={placeholder}
          sectionKey={inputKey}
          section={value}
          displayKey={displayKey}
          fields={fields}
          partiCularName={partiCularName}
          element={element}
        />
      );
    case "list":
      return (
        <DynamicList
          name={label}
          desc={placeholder}
          sectionKey={inputKey}
          section={value}
          fields={fields}
          displayKey={displayKey}
        />
      );
    case "toggle":
      return (
        <div className={styles.input_toggle}>
          <input
            name={name}
            id={name}
            type="checkbox"
            onChange={handleInputChange}
            defaultChecked={value || defaultValue}
          />
          <label htmlFor={name}>
            <span>{label}</span>
            <span className={styles.toggle}></span>
          </label>
        </div>
      );
    case "date":
      const parseDateValue = (dateStr) => {
        if (!dateStr) return { start: '', end: '', present: false };
        
        const parts = dateStr.split('-').map(part => part.trim());
        return {
          start: parts[0] || '',
          end: parts[1] === 'Present' ? '' : (parts[1] || ''),
          present: parts[1] === 'Present'
        };
      };

      const dateValue = typeof value === 'string' ? parseDateValue(value) : value;
      
      const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
      };

      return (
        <div className={styles.input_date}>
          <label>{label}</label>
          <div className={styles.date_container}>
            <div className={styles.date_input_wrapper}>
              <input 
                type="text"
                readOnly
                value={formatDate(dateValue?.start)}
                placeholder="Start Date"
                onClick={(e) => {
                  const dateInput = e.target.nextElementSibling;
                  dateInput.showPicker();
                }}
              />
              <input 
                type="month" 
                name={`${name}_start`}
                className={styles.hidden_date_input}
                onChange={(e) => {
                  const startDate = e.target.value;
                  const endDate = dateValue?.present ? 'Present' : dateValue?.end;
                  handleResumeChange(inputKey, `${formatDate(startDate)} - ${endDate}`);
                }}
                defaultValue={dateValue?.start || defaultValue?.start}
              />
            </div>
            <span className={styles.date_separator}>-</span>
            <div className={styles.end_date_section}>
              <div className={styles.date_input_wrapper}>
                <input 
                  type="text"
                  readOnly
                  value={dateValue?.present ? 'Present' : formatDate(dateValue?.end)}
                  placeholder="End Date"
                  disabled={dateValue?.present}
                  onClick={(e) => {
                    if (!dateValue?.present) {
                      const dateInput = e.target.nextElementSibling;
                      dateInput.showPicker();
                    }
                  }}
                />
                <input 
                  type="month" 
                  name={`${name}_end`}
                  className={styles.hidden_date_input}
                  onChange={(e) => {
                    const endDate = e.target.value;
                    handleResumeChange(inputKey, `${formatDate(dateValue?.start)} - ${formatDate(endDate)}`);
                  }}
                  defaultValue={dateValue?.end || defaultValue?.end}
                  disabled={dateValue?.present}
                />
              </div>
             
            </div>
            
          </div>
          <div className={styles.present_checkbox}>
                <input
                  type="checkbox"
                  id={`${name}_present`}
                  checked={dateValue?.present}
                  onChange={(e) => {
                    const isPresent = e.target.checked;
                    handleResumeChange(inputKey, `${formatDate(dateValue?.start)} - ${isPresent ? 'Present' : ''}`);
                  }}
                />
                <label htmlFor={`${name}_present`}>Present</label>
              </div>
        </div>
      );
    default:
      return null;
  }
};

const ProgressBar = ({ value, index, onUpdateValue }) => {
  const [showModal, setShowModal] = useState(false);
  
  // New function to analyze the content quality
  const analyzeContent = (text) => {
    let score = 5;
     // Give base score of 20 if user has typed something
      score = text.trim().length > 0 ? 10 : 3;
    const criteria = {
      // Comprehensive metrics patterns
      hasMetrics: /((\d{1,3}(,\d{3})*(\.\d+)?)|(\d+))(\s*\+?)(\s*[kKmMbBtT])?(%|\s*(thousand|million|billion|users|customers|clients|people|employees|teams|projects|products|services|hours|days|weeks|months|years|dollars|EUR|USD|£|€|¥|rupees|INR|CAD|AUD|points|percent|sessions|clicks|views|impressions|downloads|subscriptions|transactions|sales|leads|ROI|growth|increase|decrease|reduction|improvement|efficiency|accuracy|score|rating|rank|position|place|tier|level|phase|stage|generation|version|release|sprint|cycle|quarter|annual|monthly|weekly|daily|hourly))?|\$\s*\d+|\d+\s*%|(increased|decreased|reduced|improved|grew|shrunk|expanded|minimized|maximized|optimized|accelerated|decelerated|scaled|doubled|tripled|quadrupled)\s+by\s+\d+/gi.test(text),
      
      // Comprehensive action verbs at start
      hasActionVerb: /^(Led|Developed|Implemented|Created|Managed|Increased|Reduced|Improved|Achieved|Designed|Spearheaded|Orchestrated|Launched|Executed|Established|Streamlined|Pioneered|Transformed|Revolutionized|Generated|Delivered|Accelerated|Optimized|Engineered|Architected|Mentored|Coordinated|Automated|Simplified|Enhanced|Administered|Analyzed|Built|Championed|Conceptualized|Conducted|Consolidated|Constructed|Consulted|Controlled|Converted|Decreased|Defined|Demonstrated|Diagnosed|Directed|Discovered|Drove|Earned|Edited|Eliminated|Encouraged|Ensured|Established|Evaluated|Exceeded|Excelled|Expanded|Expedited|Facilitated|Focused|Forecasted|Formed|Formulated|Founded|Guided|Handled|Headed|Hired|Hosted|Identified|Illustrated|Impacted|Incorporated|Influenced|Informed|Initiated|Innovated|Inspected|Inspired|Installed|Instituted|Instructed|Integrated|Interpreted|Interviewed|Introduced|Invented|Investigated|Lectured|Maintained|Marketed|Mastered|Measured|Mediated|Modernized|Modified|Monitored|Motivated|Navigated|Negotiated|Operated|Organized|Originated|Overhauled|Oversaw|Performed|Planned|Prevented|Processed|Produced|Programmed|Promoted|Proposed|Provided|Published|Purchased|Recommended|Reconciled|Recorded|Recruited|Redesigned|Re-engineered|Referenced|Refined|Regulated|Rehabilitated|Remodeled|Repaired|Represented|Researched|Resolved|Restructured|Reviewed|Revised|Satisfied|Scheduled|Screened|Secured|Selected|Separated|Served|Shaped|Shared|Showcased|Solved|Sorted|Specified|Sponsored|Standardized|Steered|Strengthened|Studied|Supervised|Supported|Surpassed|Surveyed|Sustained|Synthesized|Systematized|Tabulated|Targeted|Taught|Tested|Trained|Translated|Troubleshot|Uncovered|Undertook|Unified|United|Updated|Upgraded|Utilized|Validated|Verified|Visualized|Won|Wrote)/i.test(text),
      
      // Comprehensive task patterns
      hasTask: /(using|with|through|via|by|leveraging|utilizing|applying|implementing|employing|operating|managing|handling|conducting|executing|performing|delivering|building|developing|creating|designing|architecting|engineering|coding|programming|deploying|maintaining|supporting|troubleshooting|analyzing|testing|validating|verifying|monitoring|tracking|measuring|evaluating|assessing|reviewing|auditing|inspecting|investigating|researching|studying|learning|teaching|training|mentoring|coaching|guiding|leading|directing|supervising|overseeing|coordinating|organizing|planning|strategizing|consulting|advising|recommending|suggesting|proposing|presenting|communicating|collaborating|partnering|integrating|synthesizing|combining|merging|unifying|consolidating|standardizing|optimizing|improving|enhancing|upgrading|modernizing|transforming|revolutionizing|innovating|pioneering|spearheading|championing|driving|accelerating|expediting|streamlining|simplifying|automating|mechanizing|systematizing|structuring|restructuring|reengineering|refactoring|redesigning|remodeling|revamping|overhauling|updating|maintaining|sustaining|supporting)\s+[A-Za-z0-9\s,\.\-\/\(\)]+/i.test(text),
    };

    // Calculate score based on simplified criteria
    if (criteria.hasMetrics) score += 40;
    if (criteria.hasActionVerb) score += 30;
    if (criteria.hasTask) score += 30;

     
    
    return Math.min(score, 100);
  };

  const percentage = analyzeContent(value);

  const getProgressColor = (percent) => {
    if (percent < 40) return "#FF6B6B"; // Red - Needs improvement
    if (percent < 70) return "#FFD93D"; // Yellow - Good
    return "#4CAF50"; // Green - Excellent
  };

  const tips = [
    "Begin with powerful action verbs (e.g., 'Spearheaded', 'Orchestrated', 'Pioneered', 'Transformed', 'Revolutionized')",
    "Quantify achievements with specific metrics (e.g., 'Increased efficiency by 45%', 'Managed $2M budget', 'Led team of 15+')",
    "Highlight technical skills and outcomes (e.g., 'using AWS to reduce costs by 30%', 'leveraging React.js to improve load times 5x')",
    "Focus on business impact (e.g., 'resulting in 25% revenue growth', 'driving 40% increase in user engagement')",
    "Include industry keywords relevant to the job (e.g., 'Agile methodology', 'CI/CD pipeline', 'user research')"
  ];

  const isAchievement = percentage >= 90;
  const color = getProgressColor(percentage);

  const handleImproveClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    onUpdateValue(index, newText);
  };

  return (
    <div className={styles.progress_container}>
      <div className={styles.progress_track_wrapper}>
        <motion.div
          className={styles.progress_track}
          style={{
            background: "rgba(255, 255, 255, 0.9)",
            borderRadius: "10px",
            height: "5px",
            width: "100%",
            position: "relative",
            overflow: "hidden"
          }}
        >
          <motion.div
            className={styles.progress_fill}
            style={{
              backgroundColor: color,
              height: "100%",
              borderRadius: "10px",
              boxShadow: isAchievement ? `0 0 15px ${color}` : "none",
            }}
            initial={{ width: 0 }}
            animate={{ 
              width: `${percentage}%`,
              // Stronger pulsing for lower percentages
              scale: percentage < 50 ? [1, 1.15, 1] : percentage < 100 ? [1, 1.05, 1] : 1
            }}
            transition={{ 
              width: { duration: 0.8, ease: "easeOut" },
              scale: {
                duration: percentage < 50 ? 1.5 : 2, // Faster pulse for lower percentages
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut"
              }
            }}
          >
            {percentage < 100 && (
              <motion.div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: percentage < 50 ? "30px" : "20px", // Wider glow effect for lower percentages
                  height: "100%",
                  background: `linear-gradient(90deg, transparent, ${color})`,
                  opacity: percentage < 50 ? 0.7 : 0.5 // More visible for lower percentages
                }}
                animate={{
                  x: [-30, 0],
                  opacity: percentage < 50 ? [0.3, 0.7] : [0.2, 0.5]
                }}
                transition={{
                  duration: percentage < 50 ? 1.2 : 1.5, // Faster animation for lower percentages
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: "easeInOut"
                }}
              />
            )}
          </motion.div>
        </motion.div>
      </div>
      
      <div className={styles.controls_wrapper}>
        {/* {value?.length > 0 && ( */}
          <motion.button
            className={styles.improve_button}
            onClick={handleImproveClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <RiMagicLine className={styles.improve_icon} />
            <span>Improve</span>
          </motion.button>
        {/* )} */}
      </div>

      {/* Modal */}
      {showModal && (
        <motion.div 
          className={styles.modal_overlay} 
          onClick={handleModalClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div 
            className={styles.modal_content} 
            onClick={e => e.stopPropagation()}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ type: "spring", damping: 20 }}
          >
            <div className={styles.modal_header}>
              <div className={styles.header_content}>
                <RiMagicLine className={styles.modal_icon} />
                <div className={styles.header_text}>
                  <h3>Enhance Your Experience</h3>
                  <p>Make your bullet point more impactful</p>
                </div>
              </div>
              <motion.button 
                className={styles.close_button} 
                onClick={handleModalClose}
                whileHover={{ rotate: 90 }}
                transition={{ duration: 0.2 }}
              >
                ×
              </motion.button>
            </div>

            <div className={styles.modal_body}>
              <div className={styles.modal_section}>
                <div className={styles.section_header}>
                  <h4>Your Experience</h4>
                  <span className={`${styles.text_counter} ${value?.length > 150 ? styles.text_counter_warning : ''}`}>
                    {value?.length || 0}/150
                  </span>
                </div>
                <textarea
                  value={value}
                  onChange={handleTextChange}
                  className={styles.editable_text}
                  placeholder="Enter your experience point here..."
                  rows={3}
                />
              </div>

              <div className={styles.modal_section}>
                <div className={styles.section_header}>
                  <h4>Pro Tips</h4>
                  <span className={styles.pro_badge}>PRO</span>
                </div>
                <ul className={styles.tips_list}>
                  {tips.map((tip, i) => (
                    <motion.li 
                      key={i}
                      initial={{ x: -20, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: i * 0.1 }}
                    >
                      <span className={styles.tip_bullet}>•</span>
                      {tip}
                    </motion.li>
                  ))}
                </ul>
              </div>

              <div className={styles.modal_section}>
                <AIImprovisePoints 
                  value={value} 
                  onUpdateValue={(newText) => {
                    handleTextChange({ target: { value: newText } });
                  }}
                />
              </div>
            </div>

            <div className={styles.modal_footer}>
              <motion.button 
                className={styles.close_button_secondary}
                onClick={handleModalClose}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Cancel
              </motion.button>
              <motion.button 
                className={styles.save_button}
                onClick={handleModalClose}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
               Done
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

const DynamicListString = ({
  name,
  sectionKey,
  section,
  desc,
  partiCularName,
  element,
}) => {
  const { handleResumeChange, setLoading } = useResume();
  const itemRefs = useRef([]);

  useEffect(() => {
    if (!section || section.length === 0) {
      handleResumeChange(sectionKey, ["", "", "", ""]);
    }
    itemRefs.current = itemRefs.current.slice(0, section?.length);
  }, [section]);

  const handleAdd = (e) => {
    e.stopPropagation();
    handleResumeChange(sectionKey, [...(section || []), ""]);
  };

  const handleDelete = (e, index) => {
    e.stopPropagation();

    // Ensure itemRefs.current is correctly defined and holds references to DOM elements
    if (itemRefs.current[index]) {
      const element = itemRefs.current[index];
      element.classList.add(styles.slidefadeout);

      // Delay the state update to allow the fade-out animation to complete
      setTimeout(() => {
        handleResumeChange(sectionKey, [
          ...section.slice(0, index),
          ...section.slice(index + 1),
        ]);
      }, 480); // Match this duration with the CSS animation duration
    } else {
      console.warn("Element reference not found for index:", index);
    }
  };

  const handleInputChange = (e, index) => {
    e.stopPropagation();
    handleResumeChange(`${sectionKey}.${index}`, e.target.value);
  };

  const handleInputChangeWithDebounce = (e, index) => {
    e.stopPropagation();
    setLoading(true);
    handleInputChange(e, index);
  };

  const renderSection = () => {
    // console.log(partiCularName);
    switch (partiCularName) {
      case "Skills":
        return (
          <>
            <label htmlFor={name} className={styles.input_list_label}>
              {name}
            </label>
            {desc && <p className={styles.desc}>{desc}</p>}
            <div className={styles.input_list_elements}>
              {section?.map((element, index) => (
                <div
                  ref={(el) => (itemRefs.current[index] = el)}
                  key={index}
                  className={styles.input_list_String_element}
                >
                  <input
                    type="text"
                    value={element}
                    onChange={(e) => handleInputChangeWithDebounce(e, index)}
                  />

                  <button onClick={(e) => handleDelete(e, index)}>
                    <Trash2 size={15} />
                  </button>
                </div>
              ))}
            </div>
            <div className={styles.section_actions}>
              <span className={styles.addButtons} onClick={handleAdd}>
                <FilePlus2 style={{ height: "15px" }} /> Add {name}
              </span>
            </div>
            <AISkillsPoints
              element={element}
              sectionKey={sectionKey}
              name={name}
              section={section}
              partiCularName={partiCularName}
            />
          </>
        );
      case "Experience":
        return (
          <>
            <div className={styles.input_list_label_container}>
              <label htmlFor={name} className={styles.input_list_label}>
                {name}
              </label>
              {desc && <p className={styles.desc}>{desc}</p>}
              <AIPoints
                element={element}
                sectionKey={sectionKey}
                name={name}
                section={section}
                partiCularName={partiCularName}
              />
            </div>
            <div className={styles.input_list_elements} style={{gap: "0px"}}>
              {section?.map((element, index) => (
                <div
                  ref={(el) => (itemRefs.current[index] = el)}
                  key={index}
                  className={styles.input_list_String_element}
                >
                  <div className={styles.point_container}>
                    <div className={styles.input_wrapper}>
                      <input
                        type="text"
                        value={element}
                        onChange={(e) => handleInputChangeWithDebounce(e, index)}
                      />
                    </div>
                    <div className={styles.progress_wrapper}>
                      <ProgressBar 
                        value={element} 
                        index={index} 
                        onUpdateValue={(index, newValue) => {
                          const newElements = [...section];  // Assuming 'section' is your array of points
                          newElements[index] = newValue;
                          handleResumeChange(sectionKey, newElements);  // Assuming you have a handleResumeChange function
                        }} 
                      />
                    </div>
                  </div>
                  <button onClick={(e) => handleDelete(e, index)}>
                    <Trash2 size={15} />
                  </button>
                </div>
              ))}
            </div>
            {section && section.length < 4 && (
              <div className={styles.section_actions}>
                <span className={styles.addButtons} onClick={handleAdd}>
                  <FilePlus2 style={{ height: "15px" }} /> Add {name}
                </span>
              </div>
            )}
          </>
        );
      default:
        return (
          <>
            <div className={styles.input_list_label_container}>
              <label htmlFor={name} className={styles.input_list_label}>
                {name}
              </label>
              {desc && <p className={styles.desc}>{desc}</p>}
              <AIPoints
                element={element}
                sectionKey={sectionKey}
                name={name}
                section={section}
                partiCularName={partiCularName}
              />
            </div>
            <div className={styles.input_list_elements}>
              {section?.map((element, index) => (
                <div
                  ref={(el) => (itemRefs.current[index] = el)}
                  key={index}
                  className={styles.input_list_String_element}
                >
                  <input
                    type="text"
                    value={element}
                    onChange={(e) => handleInputChangeWithDebounce(e, index)}
                  />
                  <button onClick={(e) => handleDelete(e, index)}>
                    <Trash2 size={15} />
                  </button>
                </div>
              ))}
            </div>
            {section && section.length < 4 && (
              <div className={styles.section_actions}>
                <span className={styles.addButtons} onClick={handleAdd}>
                  <FilePlus2 style={{ height: "15px" }} /> Add {name}
                </span>
              </div>
            )}
          </>
        );
    }
  };

  return (
    <div className={styles.input_list}>
      <div>{renderSection()}</div>
    </div>
  );
};

const DynamicList = ({
  name,
  sectionKey,
  section,
  desc,
  fields,
  displayKey,
}) => {
  const { handleResumeChange } = useResume();
  const itemRefs = useRef([]);

  const handleAdd = (e) => {
    e.stopPropagation();
    const newElement = {};
    fields.forEach((field) => {
      if (field.type === "text" || field.type === "textarea") {
        newElement[field.name] = "";
      } else if (field.type === "dropdown") {
        newElement[field.name] = field.placeholder || "";
      } else if (field.type === "list-string") {
        newElement[field.name] = [];
      }
    });
    handleResumeChange(sectionKey, [...(section || []), newElement]);
  };

  const handleDelete = (e, index) => {
    e.stopPropagation();
    const element = itemRefs.current[index];
    element.classList.add(styles.slidefadeout);
    setTimeout(() => {
      handleResumeChange(sectionKey, [
        ...section.slice(0, index),
        ...section.slice(index + 1),
      ]);
    }, 480);
  };

  return (
    <div className={styles.input_list}>
      <label htmlFor={name} className={styles.input_list_label}>
        {name}
      </label>
      {desc && <p className={styles.desc}>{desc}</p>}
      <div className={styles.input_list_elements}>
        {section?.map((element, _index) => (
          <details
            ref={(el) => (itemRefs.current[_index] = el)}
            key={_index}
            className={styles.input_list_element}
          >
            <summary>
              {element[displayKey] || " "}
              <button onClick={(e) => handleDelete(e, _index)}>
                <Trash2 />
              </button>
            </summary>
            {fields.map((field, index) => (
              <Input
                key={index}
                type={field.type}
                label={field.label}
                name={field.name}
                placeholder={field.placeholder}
                value={element[field.name]}
                defaultValue={element[field.name]}
                inputKey={`${sectionKey}.${_index}.${field.key}`}
                options={field.options}
                fields={field.fields}
              />
            ))}
          </details>
        ))}
      </div>
      <div className={styles.section_actions}>
        <button onClick={handleAdd}>+ Add {name}</button>
      </div>
    </div>
  );
};

export default BuildResume;
