import React, { useEffect, useState } from "react";
import styles from "../../assets/Matching.module.css";
import { ChevronDown, ChevronUp, Plus, PlusCircle, PlusIcon } from "lucide-react";
import { useResume } from "./components/context/Resume";
import { useJobs } from "./components/context/Jobs";
import axios from "axios"; 
import Model from "../../components/Model";
import OpenAI from "openai";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { privateApi } from "../utils/aixos";
import BottomBar from "../../components/BottomBar";
import { toast } from "react-hot-toast";
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
}); 

const Matching = () => {
  const [value, setValue] = useState("");
  const [response, setResponse] = useState("");
  let [misskingSkills, setMissingSkills] = useState([]);
  let [skillss, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const { values, handleResumeChange, setValues } = useResume();
  const [point, setPoint] = useState([]);
  const [pointProject, setPointProject] = useState([]);
  const { payment } = useJobs();
  const [modalShow, setModalShow] = useState(false);
  const [visibleCount, setVisibleCount] = useState(10);
  const [visibleCountKey, setVisibleCountkey] = useState(10);
  const [ProffestionalSummary, setProffestionalSummary] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [active, setActive] = useState(false);
  const [openSkill, setOpenSkill] = useState(false);
  const [openKeywords, setOpenKeywords] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [openPoints, setOpenPoints] = useState(false);
  const [showJDModal, setShowJDModal] = useState(false);
  const [structuredJD, setStructuredJD] = useState(null);
 
  const handleSubmit = () => {
    if (!value.trim()) {
      toast.error("Please enter a job description");
      return;
    }
    analyzeJobDescription();
  };

  const analyzeJobDescription = async () => {
    try {
      setLoading(true);
      console.log("Analyzing JD:", value);

      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: "You are a job description analyzer. Your task is to structure the provided job description into organized sections exactly as given, without adding or removing any information."
          },
          {
            role: "user", 
            content: `Analyze and structure this job description into these exact categories, maintaining all original content:

            1. Job Title & Overview (extract the job title and any overview/summary)
            2. Required Skills & Technologies (all mentioned skills, tools, technologies)
            3. Responsibilities & Duties (all listed responsibilities and tasks)
            4. Required Qualifications (education, certifications, etc.)
            5. Experience Requirements (years of experience, domain experience)
            6. Additional Requirements (any other requirements mentioned)
            7. Benefits & Perks (if mentioned)
            8. Company Information (if provided)

            Return as JSON with these category arrays, keeping the exact wording from the original text.
            If any category is not mentioned in the JD, return an empty array for that category.
            
            Job Description: ${value}`
          }
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.1, // Lower temperature for more consistent output
        response_format: { type: "json_object" }
      });

      console.log("OpenAI Response:", completion.choices[0].message.content);
      const parsed = JSON.parse(completion.choices[0].message.content);
      setStructuredJD(parsed);
      setShowJDModal(true);
      setLoading(false);
    } catch (error) {
      console.error("Error in analyzeJobDescription:", error);
      toast.error(error.message || "Error analyzing job description");
      setLoading(false);
    }
  };

  const getResponse = async () => {
    try {
      setLoading(true);
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content: `You are a professional resume writer specializing in creating impactful professional summaries. 
            Focus on incorporating keywords naturally while maintaining a compelling narrative.`
          },
          {
            role: "user",
            content: `Create a professional summary incorporating these missing keywords: ${response.suggestions.missing_words.join(", ")}.
            
            Guidelines:
            1. Keep it between 3-4 sentences
            2. Start with years of experience and key expertise
            3. Highlight technical skills and domain knowledge
            4. Focus on quantifiable achievements
            5. Naturally weave in the missing keywords
            6. Use active voice and professional tone
            7. Avoid generic phrases and clichés
            
            Current Job Role: ${values.sections.experience?.data[0]?.role || ""}
            Industry: ${values.sections.experience?.data[0]?.company || ""}
            `
          }
        ],
        model: "gpt-3.5-turbo",
        temperature: 0.7,
        max_tokens: 250
      });

      const summary = completion.choices[0].message.content;
      setProffestionalSummary(summary);
      setLoading(false);
      setOpenSummary(true); // Open the summary section automatically
    

    } catch (error) {
      console.error("Error generating professional summary:", error);
      setLoading(false);
      toast.error("Failed to generate professional summary. Please try again.");
    }
  };

  // Add a helper function to handle summary updates
  const handleSummaryUpdate = async () => {
    if (!response.suggestions.missing_words.length) {
      toast.warning("No missing keywords to incorporate");
      return;
    }
    
    if (loading) {
      toast.info("Please wait while generating summary...");
      return;
    }
    
    await getResponse();
  };

  const handleATSScan = async () => {
    try {
      setLoading(true);
      const { data } = await privateApi.post(`/pdf/ats`, {
        descrption: value,
        resumeContent: values,
        structuredData: structuredJD // Pass the structured data to your backend
      });
  
      setLoading(false);
      console.log(data.response);
      setResponse(data.response);
      setMissingSkills(data.response.suggestions.missing_skills);
      
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Error processing job description");
    }
  };

  // Add a function to preview the summary
  const previewSummary = () => {
    if (!ProffestionalSummary) return null;
    
    return (
      <div className={styles.summaryPreview}>
        <h4>Professional Summary Preview</h4>
        <p>{ProffestionalSummary}</p>
        <div className={styles.summaryActions}>
          <button 
            onClick={addSummary}
            className={styles.applyButton}
          >
            Apply to Resume
          </button>
          <button 
            onClick={() => handleSummaryUpdate()}
            className={styles.regenerateButton}
          >
            Regenerate
          </button>
        </div>
      </div>
    );
  };

  const generatePoint = async () => {
    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure no string will be in response each must be 150 words",
          },
          {
            role: "user",
            content: `job role= ${values.sections.experience?.data[0]?.role} company = ${values.sections.experience?.data[0]?.company}   missing Skills= ${response.suggestions.missing_skills}`,
          },
          {
            role: "user",
            content: `give me 5 point  and include percentage wise point in between the sentence aprox  while i am in this job role so i can add in my resume  and provide me only array `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      const jsonString = completion.choices[0].message.content;
      const suggestions = JSON.parse(jsonString);
      setPoint(suggestions);
      // handleResumeChange(sectionKey, [...(section || []), ""]);
    } catch (error) {
      console.log(error);
    }
  };

  const generatePointProject = async () => {
    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: "system",
            content:
              "You're a resume maker, helping users build strong resumes . only give me point in array only and make sure no string will be in response each must be 150 words",
          },
          {
            role: "user",
            content: `job role= ${values.sections.projects?.data[0]?.titlle} company = ${values.sections.projects?.data[0]?.organisation}   missing Skills= ${response.suggestions.missing_skills}`,
          },
          {
            role: "user",
            content: `give me 5 point  and include percentage wise point in between the sentence aprox  while i am in this job role so i can add in my resume  and provide me only array `,
          },
        ],
        model: "gpt-3.5-turbo",
        temperature: 1,
        max_tokens: 1000,
      });

      const jsonString = completion.choices[0].message.content;
      const suggestions = JSON.parse(jsonString);
      setPointProject(suggestions);
      // handleResumeChange(sectionKey, [...(section || []), ""]);
    } catch (error) {
      console.log(error);
    }
  };

  const showMore = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  const showMorekey = () => {
    setVisibleCountkey((prevCount) => prevCount + 10);
  };

  const addSummary = async () => {
    handleResumeChange(
      `sections.Professionalsummary.data.summary`,
      ProffestionalSummary
    );
  };
  const addSkills = (singleSkill) => {
    // Filter out the selected skill from missingSkills
    setMissingSkills((prevMissingSkills) =>
      prevMissingSkills.filter((skill) => skill !== singleSkill)
    );

    // Add the selected skill to the skills array
    setSkills((prevSkills) => {
      // Retrieve existing content from the `values` state
      setValues((prevState) => {
        let temp = { ...prevState }; // Copy the previous state

        // Safely navigate to `sections.technicalSkills.data`
        if (!temp.sections) {
          temp.sections = {};
        }
        if (!temp.sections.technicalSkills) {
          temp.sections.technicalSkills = {};
        }
        if (!temp.sections.technicalSkills.data) {
          temp.sections.technicalSkills.data = [];
        }

        // Determine the index where new skills should be added
        let currentIndex = 0;
        for (let i = 0; i < temp.sections.technicalSkills.data.length; i++) {
          const currentSkills =
            temp.sections.technicalSkills.data[i].skills || [];
          if (currentSkills.length > 0) {
            currentIndex = i; // Stay on the last non-empty index
          }
        }

        // Check if currentIndex already exists or if it needs to create a new one
        if (!temp.sections.technicalSkills.data[currentIndex]) {
          temp.sections.technicalSkills.data[currentIndex] = { skills: [] };
        }

        // Get the skills array at the current index
        const currentSkillsAtIndex =
          temp.sections.technicalSkills.data[currentIndex].skills || [];

        // Append the new skill if it doesn't already exist
        if (!currentSkillsAtIndex.includes(singleSkill)) {
          const updatedSkills = [...currentSkillsAtIndex, singleSkill]; // Append new skill

          // Update the skills array in the state at the current index
          temp.sections.technicalSkills.data[currentIndex].skills =
            updatedSkills;

          // Define sectionKey dynamically for handleResumeChange
          let sectionKey = `sections.technicalSkills.data.${currentIndex}.skills`;

          // Call handleResumeChange with the updated skills
          handleResumeChange(sectionKey, updatedSkills);

          // Update the state with the modified temp object
          return temp;
        } else {
          console.log("Skill already exists in the array, not adding again.");
          return temp; // Return the original state if no change
        }
      });

      // Add the skill to local skills array if needed for another purpose
      return [...prevSkills, singleSkill]; // Return the updated skills for local state
    });
  };

  const addExper = (item) => {
    setValues((prevState) => {
      let temp = { ...prevState }; // Copy the previous state

      // Safely navigate to `sections.experience.data`
      if (!temp.sections) {
        temp.sections = {};
      }
      if (!temp.sections.experience) {
        temp.sections.experience = {};
      }
      if (!temp.sections.experience.data) {
        temp.sections.experience.data = [];
      }

      // Ensure there is an object at `data[0]`, or initialize it if not present
      if (!temp.sections.experience.data[0]) {
        temp.sections.experience.data[0] = { points: [] }; // Initialize with an empty points array
      }

      // Get the current experience at index 0
      const currentExperienceAtIndex = temp.sections.experience.data[0];

      // Ensure there is a `points` field in the current experience, or initialize it
      if (!currentExperienceAtIndex.points) {
        currentExperienceAtIndex.points = [];
      }

      // Add the new `item` (points) to the `points` array
      const updatedPoints = [...currentExperienceAtIndex.points, item];

      // Update the `points` field at index 0 with the new data
      temp.sections.experience.data[0].points = updatedPoints;

      // Define sectionKey dynamically for handleResumeChange
      let sectionKey = `sections.experience.data.0.points`;

      // Call handleResumeChange with the updated points array
      handleResumeChange(sectionKey, updatedPoints);

      // Return the updated state
      return temp;
    });
  };

  const addProject = (item) => {
    setValues((prevState) => {
      let temp = { ...prevState }; // Copy the previous state

      // Safely navigate to `sections.projects.data`
      if (!temp.sections) {
        temp.sections = {};
      }
      if (!temp.sections.projects) {
        temp.sections.projects = {};
      }
      if (!temp.sections.projects.data) {
        temp.sections.projects.data = [];
      }

      // Ensure there is an object at `data[0]`, or initialize it if not present
      if (!temp.sections.projects.data[0]) {
        temp.sections.projects.data[0] = { points: [] }; // Initialize with an empty points array
      }

      // Get the current project at index 0
      const currentProjectAtIndex = temp.sections.projects.data[0];

      // Ensure there is a `points` field in the current project, or initialize it
      if (!currentProjectAtIndex.points) {
        currentProjectAtIndex.points = [];
      }

      // Add the new `item` (points) to the `points` array
      const updatedPoints = [...currentProjectAtIndex.points, item];

      // Update the `points` field at index 0 with the new data
      temp.sections.projects.data[0].points = updatedPoints;

      // Define sectionKey dynamically for handleResumeChange
      let sectionKey = `sections.projects.data.0.points`;

      // Call handleResumeChange with the updated points array
      handleResumeChange(sectionKey, updatedPoints);

      // Return the updated state
      return temp;
    });
  };

  const JobDescriptionContainer = () => {
    return (
      <div className={styles.container}>
        <label htmlFor="jobDescription" className={styles.label}>
          Enter Job Description
        </label>
        <div className={styles.textBoxContainer}>
          {!isFocused && (
            <div className={styles.placeholderText}>
              Example
              <br />
              - Company-wide committee facilitation and participation
              <br />
              - Employee safety, welfare, wellness and health reporting
              <br />
              - Provide direct support to employees during implementation of HR
              services, policies and programs
              <br />
              What skills will the intern learn:
              <br />
              - Active participation in strategic planning process, including
              developing goals, objectives and processes
              <br />
              - How to engage professionally in HR meetings and seminars with
              other HR professionals in the region
              <br />
              - Gain experience with Human Resources Information system (HRIS)
              database management and record keeping
              <br />
              - Application of HR law and compliance with governmental
              regulations
              <br />
              Qualifications:
              <br />
              - Proficient with Microsoft Word and Excel
              <br />
              - General knowledge of employment law and practices
              <br />
              - Able to maintain a high level of confidentiality
              <br />- Effective oral and written management communication skills
            </div>
          )}
          <textarea
            id="jobDescription"
            className={styles.textBox}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={(e) =>
              setIsFocused(e.target.value.length > 0 ? true : false)
            }
          />
        </div>

        <div className={styles.buttoncontainwer}>
          <div
            className={styles.buttons}
            onClick={
              payment?.paymentStatus !== "success"
                ? () => setModalShow(true)
                : handleSubmit
            }
          >
            {loading ? "Loading" : "Scan ATS"}
          </div>
        </div>
      </div>
    );
  };

  const TipContainer = () => {
    return (
      <div className={styles.TipsContainer}>
        <div className={styles.tipleft}>
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730031255/all/crw1wqjgdwuae4lb7ybc.svg" />
        </div>
        <div className={styles.tipright}>
          <div className={styles.tipheading}>QUICK TIPsS</div>
          <div className={styles.tippara}>
            Create an email that includes your name, avoiding nicknames or
            numbers that may seem unprofessional.{" "}
          </div>
        </div>
      </div>
    );
  };

  const SwitchATSEditor = () => {
    return (
      <div className={styles.SwitchATSEditorContainer}>
        <div
          className={`${styles.eachSwitch} ${
            !active ? styles.activeswitch : ""
          }`}
          onClick={() => setActive(!active)}
        >
          ATS Score
        </div>
        <div
          className={`${styles.eachSwitch} ${
            active ? styles.activeswitch : ""
          }`}
          onClick={() => setActive(!active)}
        >
          Editor
        </div>
      </div>
    );
  };

  const ScoreContainer = () => {
    return (
      <div className={styles.scoreContainerMain}>
        <div className={styles.upparScoreContainer}>
          <div className={styles.circularScoreContainer}>
            {/* Calculate color stops based on actual scores */}
            <svg style={{ height: 0 }}>
              <defs>
                <linearGradient
                  id="gradient"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="100%"
                >
                  <stop
                    offset={`${(response?.feedback?.matchScore / 48) * 100}%`}
                    stopColor="#5860FC"
                  />
                  <stop
                    offset={`${
                      (response?.feedback?.Relevant_Experience_Score / 19) * 100
                    }%`}
                    stopColor="#AC8FFF"
                  />
                  <stop
                    offset={`${
                      (response?.feedback?.Overall_Skills_Possessed / 3) * 100
                    }%`}
                    stopColor="#CF8CFF"
                  />
                  <stop
                    offset={`${
                      (response?.feedback?.Format_Readability / 10) * 100
                    }%`}
                    stopColor="#FFC4DA"
                  />
                  <stop
                    offset={`${
                      (response?.feedback?.Technical_Skills_Score / 20) * 100
                    }%`}
                    stopColor="#9DB7FF"
                  />
                </linearGradient>
              </defs>
            </svg>

            <CircularProgressbar
              value={response?.atsScore || 0}
              text={`${response?.atsScore || 0}%`}
              styles={buildStyles({
                rotation: 0.25,
                textColor: "#333",
                trailColor: "#d6d6d6",
                pathColor: "url(#gradient)",
                textSize: "16px",
              })}
            />
          </div>
          <div className={styles.ScoreContainer}>
            <div className={styles.score}>
              Your ATS Score is {response?.atsScore}
            </div>
            <div className={styles.scorepara}>
              We ran 20+ checks on your resume. You need a score of 35 to pass
              the scan.
            </div>
          </div>
        </div>
        <div className={styles.downScoreContainer}>
          <div className={styles.eachScoreHeadingContainer}>
            <div className={styles.eachScoreDotContainer}>
              <div className={styles.eachScoreDot}></div>
            </div>
            <div className={styles.eachScoreHeadingContainerUnder}>
              <div className={styles.eachScoreHeading}>Keyword Match</div>
              <div className={styles.eachScorePercentage}>
                {response?.feedback?.matchScore}
              </div>
            </div>
          </div>
          <div className={styles.eachScoreHeadingContainer}>
            <div className={styles.eachScoreDotContainer}>
              <div className={styles.eachScoreDot}></div>
            </div>
            <div className={styles.eachScoreHeadingContainerUnder}>
              <div className={styles.eachScoreHeading}>Relevant Experience</div>
              <div className={styles.eachScorePercentage}>
                {response?.feedback?.Relevant_Experience_Score}
              </div>
            </div>
          </div>
          <div className={styles.eachScoreHeadingContainer}>
            <div className={styles.eachScoreDotContainer}>
              <div className={styles.eachScoreDot}></div>
            </div>
            <div className={styles.eachScoreHeadingContainerUnder}>
              <div className={styles.eachScoreHeading}>Skills Matched</div>
              <div className={styles.eachScorePercentage}>
                {response?.feedback?.Technical_Skills_Score}
              </div>
            </div>
          </div>
          <div className={styles.eachScoreHeadingContainer}>
            <div className={styles.eachScoreDotContainer}>
              <div className={styles.eachScoreDot}></div>
            </div>
            <div className={styles.eachScoreHeadingContainerUnder}>
              <div className={styles.eachScoreHeading}>Overall Skills</div>
              <div className={styles.eachScorePercentage}>
                {response?.feedback?.Overall_Skills_Possessed}
              </div>
            </div>
          </div>
          <div className={styles.eachScoreHeadingContainer}>
            <div className={styles.eachScoreDotContainer}>
              <div className={styles.eachScoreDot}></div>
            </div>
            <div className={styles.eachScoreHeadingContainerUnder}>
              <div className={styles.eachScoreHeading}>Format Readability</div>
              <div className={styles.eachScorePercentage}>
                {response?.feedback?.Format_Readability}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MissingSkillsContainer = () => {
    return (
      <div className={`${styles.flfo}   ${!openSkill ? styles.opneskil : ""}`} >
        <div className={styles.ddepal}>
          <div>Missing Skills</div>
          <div className={styles.ecahScoreMian}>
            {" "}
            <div className={styles.eachScorePercentage}>
              {response?.feedback?.Technical_Skills_Score}
            </div>{" "}
               <div onClick={() => setOpenSkill(!openSkill)}>
                    {openSkill ? <ChevronUp size={20}  /> : <ChevronDown size={20}  />}
              </div>
          </div>
        </div>

        <div> 
          {openSkill && (
             <div className={styles.dd}>
             {/* Slice the missing skills to show only the visible count */}
             {misskingSkills?.length === 0 ? (
               <span className={styles.dfdf}>No skills are missing</span>
             ) : (
               misskingSkills?.slice(0, visibleCountKey).map((item, index) => (
                 <span
                   key={index}
                   className={styles.dfdf}
                   onClick={() => addSkills(item)}
                 >
                   {item} <Plus size={20} />
                 </span>
               ))
             )}
           </div>
          )}
         
        </div>

        {/* Show the "Show More" button if there are more skills to display */}
        {misskingSkills?.length > visibleCount && (
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <span onClick={showMorekey} className={styles.showMoreBtn}>
              Show More
            </span>
          </div>
        )}
      </div>
    );
  };

  const MissingKeywordsContainer = () => {
    return (
      <div className={`${styles.flfo}  ${!openKeywords ? styles.opneskil : ""}`}>
        <div className={styles.ddepal}>
          <div> Missing Keywords</div>
          <div className={styles.ecahScoreMian}>
            {" "}
            <div className={styles.eachScorePercentage}>
              {response?.feedback?.matchScore}
            </div>{" "}
            <div onClick={() => setOpenKeywords(!openKeywords)}>
              {openKeywords ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
          </div>
        </div>

        <div>
          {openKeywords && (
              <div className={styles.dd}>
              {/* Slice the suggestions to show only the visible count */}
            {response.suggestions.missing_words?.length === 0 ? (
              <span className={styles.dfdf}>No missing keywords found</span>
            ) : (
              response.suggestions.missing_words
                ?.slice(0, visibleCount)
                .map((item, index) => (
                  <span key={index} className={styles.dfdf}>
                    {" "}
                    {item}
                  </span>
                ))
              )}
            </div>
          )}
        </div>

        {/* Show the "Show More" button if there are more keywords to display */}
        {response.suggestions.missing_words?.length > visibleCount && (
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <span onClick={showMore} className={styles.showMoreBtn}>
              Show More
            </span>
          </div>
        )}
      </div>
    );
  };

  const proffestionalSummaryContainer = () => {
    return (
      <div className={`${styles.flfo}   ${!openSummary ? styles.opneskil : ""}`}>
        <div className={styles.ddepal}>
          <div> AI Generated Professional Summary</div>
          <div className={styles.ecahScoreMian}>
            {" "}
            <div onClick={() => setOpenSummary(!openSummary)}>
              {openSummary ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
          </div>
        </div>
        <div>
          {openSummary && (
             <div className={styles.proffestionald}>
             <p className={styles.mainsfr}>{ProffestionalSummary}</p>
             <div className={styles.dfroore}>
               <span className={styles.aibuttonf} onClick={() => getResponse()}>
                 <img
                   src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1731091848/all/sm89cm7yyer7emxfaoje.svg"
                   alt="re-genererate"
                 />
                 Re-Genererate Summary
               </span>
               <span
                 className={styles.boroerr}
                 onClick={() => {
                   addSummary();
                 }}
               >
                 {" "}
                 <PlusIcon size={14} /> Add To Resume
               </span>
             </div>
           </div>
          )}
         
        </div>
      </div>
    );
  };

  const AIgeneratedPointsContainer = () => {
    return (
      <div className={`${styles.flfo}   ${!openPoints ? styles.opneskil : ""}`}>
        <div className={styles.ddepal}>
          <div>AI Generated Points</div>
          <div className={styles.ecahScoreMian}>
            {" "}
            <div onClick={() => setOpenPoints(!openPoints)}>
              {openPoints ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </div>
          </div>
        </div> 


        {openPoints && (
          <div>
            {values?.sections?.experience?.data[0]?.role && (
            <div className={styles.proffestionald}>
              <div className={styles.headingiro}>
                {" "}
                {values?.sections?.experience?.data[0]?.role} at{" "}
                {values.sections.experience?.data[0]?.company}
              </div>

              {point.map((data, index) => {
                return (
                  <ul key={index}>
                    <li>
                      {data}{" "}
                      <span onClick={() => addExper(data)}>
                        <PlusCircle color={"blue"} size={14} />
                      </span>{" "}
                    </li>
                  </ul>
                );
              })}
              <div className={styles.dfroore}>
                <span
                  className={styles.aibuttonf}
                  onClick={() => generatePoint()}
                >
                  <img
                    src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1731091848/all/sm89cm7yyer7emxfaoje.svg"
                    alt="re-genererate"
                  />{" "}
                  Re-Genereratee Points
                </span>
              </div>
            </div>
          )}
          {values?.sections?.projects?.data[0]?.title && (
            <div className={styles.proffestionald}>
              <div className={styles.headingiro}>
                {values?.sections?.projects?.data[0]?.title} at{" "}
                {values.sections.projects?.data[0]?.organisation}
              </div>
              {pointProject.map((data, index) => {
                return (
                  <ul key={index}>
                    <li>
                      {data}{" "}
                      <span onClick={() => addProject(data)}>
                        <PlusCircle color={"blue"} size={14} />
                      </span>
                    </li>
                  </ul>
                );
              })}
              <div className={styles.dfroore}>
                <span
                  className={styles.aibuttonf}
                  onClick={() => generatePointProject()}
                >
                  <img
                    src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1731091848/all/sm89cm7yyer7emxfaoje.svg"
                    alt="re-genererate"
                  />{" "}
                  Re-Genereratee Points
                </span>
              </div>
            </div>
          )}
        </div>
        
      )}
      </div>
    );
  };

  const reviewContainer = () => {
    return (
      <div>
        <div className={`${styles.flfo}   `}>
          <div className={styles.ddepal}>Relivance Experience Keywords</div>
          <div>
            <div className={styles.dd}>
              {response.suggestions.relivance_Experience?.map((item, index) => (
                <span key={index} className={styles.dfdf}>
                  {item}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const JobDescriptionModal = () => {
    return (
      <div className={`${styles.modalOverlay} ${showJDModal ? styles.show : ''}`}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <div className={styles.headerContent}>
              {structuredJD?.["Job Title & Overview"]?.[0] && (
                <>
                  <div className={styles.jobTitleWrapper}>
                    <h1 className={styles.jobTitle}>{structuredJD["Job Title & Overview"][0]}</h1>
                  </div>
                  {structuredJD?.["Company Information"]?.[0] && (
                    <div className={styles.companyInfo}>
                      <span className={styles.companyName}>{structuredJD["Company Information"][0]}</span>
                    </div>
                  )}
                </>
              )}
            </div>
            {/* <button 
              className={styles.closeButton}
              onClick={() => setShowJDModal(false)}
              aria-label="Close modal"
            >
              ×
            </button> */}
          </div>

          <div className={styles.modalBody}>
            {loading ? (
              <div className={styles.loadingContainer}>
                <div className={styles.spinner}></div>
                <p>Analyzing job description...</p>
              </div>
            ) : structuredJD ? (
              <div className={styles.jobContent}>
                <div className={styles.jobHighlights}>
                  {structuredJD["Experience Requirements"]?.[0] && (
                    <div className={styles.highlightItem}>
                      <div>
                        <h4>Experience Level</h4>
                        <p>{structuredJD["Experience Requirements"][0]}</p>
                      </div>
                    </div>
                  )}
                  {structuredJD["Required Qualifications"]?.[0] && (
                    <div className={styles.highlightItem}>
                      <div>
                        <h4>Required Qualification</h4>
                        <p>{structuredJD["Required Qualifications"][0]}</p>
                      </div>
                    </div>
                  )}
                </div>

                {/* Overview Section */}
                {structuredJD["Job Title & Overview"]?.length > 1 && (
                  <div className={styles.jobSection}>
                    <h2>
                      <span className={styles.sectionIcon}>📋</span>
                      About the Role
                    </h2>
                    <div className={styles.sectionContent}>
                      {structuredJD["Job Title & Overview"].slice(1).map((item, index) => (
                        <p key={index}>{item}</p>
                      ))}
                    </div>
                  </div>
                )}

                {/* Key Skills Section */}
                {structuredJD["Required Skills & Technologies"]?.length > 0 && (
                  <div className={styles.jobSection}>
                    <h2>
                      <span className={styles.sectionIcon}>💡</span>
                      Key Skills
                    </h2>
                    <div className={styles.skillTags}>
                      {structuredJD["Required Skills & Technologies"].map((skill, index) => (
                        <span key={index} className={styles.skillTag}>{skill}</span>
                      ))}
                    </div>
                  </div>
                )}

                {/* Responsibilities Section */}
                {structuredJD["Responsibilities & Duties"]?.length > 0 && (
                  <div className={styles.jobSection}>
                    <h2>
                      <span className={styles.sectionIcon}>✅</span>
                      Key Responsibilities
                    </h2>
                    <ul className={styles.responsibilitiesList}>
                      {structuredJD["Responsibilities & Duties"].map((resp, index) => (
                        <li key={index}>{resp}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {/* Requirements Section */}
                <div className={styles.jobSection}>
                  <h2>
                    <span className={styles.sectionIcon}>📝</span>
                    Requirements
                  </h2>
                  <div className={styles.requirementsGrid}>
                    {/* Qualifications */}
                    {structuredJD["Required Qualifications"]?.length > 0 && (
                      <div className={styles.requirementCard}>
                        <h3>Qualifications</h3>
                        <ul>
                          {structuredJD["Required Qualifications"].map((qual, index) => (
                            <li key={index}>{qual}</li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {/* Experience */}
                    {structuredJD["Experience Requirements"]?.length > 0 && (
                      <div className={styles.requirementCard}>
                        <h3>Experience</h3>
                        <ul>
                          {structuredJD["Experience Requirements"].map((exp, index) => (
                            <li key={index}>{exp}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                {/* Benefits Section */}
                {structuredJD["Benefits & Perks"]?.length > 0 && (
                  <div className={styles.jobSection}>
                    <h2>
                      <span className={styles.sectionIcon}>🎁</span>
                      Benefits & Perks
                    </h2>
                    <div className={styles.benefitsList}>
                      {structuredJD["Benefits & Perks"].map((benefit, index) => (
                        <div key={index} className={styles.benefitItem}>
                          <span className={styles.benefitIcon}>✓</span>
                          {benefit}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.errorMessage}>
                <span className={styles.errorIcon}>⚠️</span>
                Failed to analyze job description. Please try again.
              </div>
            )}
          </div>

          <div className={styles.modalFooter}>
            <button
              className={styles.cancelButton}
              onClick={() => setShowJDModal(false)}
            >
              Back
            </button>
            <button
              className={styles.proceedButton}
              onClick={() => {
                setShowJDModal(false);
                handleATSScan();
              }}
              disabled={loading || !structuredJD}
            >
              {loading ? 'Processing...' : 'Run ATS Scan'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (response) {
      generatePointProject();
      generatePoint();
      getResponse();
    }
  }, [response]);

  return (
    <div className={styles.main}>
      {response && ScoreContainer()}
      {SwitchATSEditor()}
      {TipContainer()}
      {!response && JobDescriptionContainer()}

      {response && (
        <div className={styles.alllor}>
          {MissingSkillsContainer()}
          {MissingKeywordsContainer()}
          {proffestionalSummaryContainer()}
          {AIgeneratedPointsContainer()}
        </div>
      )}
      <BottomBar/>
      <Model modalShow={modalShow} setModalShow={setModalShow} />
      <JobDescriptionModal/>
    </div>
  );
};

export default Matching;
