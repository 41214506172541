import React from "react";
import styles from "./LLTResume.module.css";
import { getSafeMarkdownString } from "../../../../utils/markdown";
import { Check, GithubIcon, Linkedin, LinkedinIcon, Mail, MapPin, Phone, SendHorizonal, TicketCheck } from "lucide-react";

const LLTResume = ({ sections }) => {
  return (
    <div className={styles.main}>
      <div className={styles.headerContainer}>
        <TopHeader profile={sections.profile.data} /> 
        <div className={styles.hr}></div>
      </div>
      <div className={styles.resume}>
        <div className={styles.topContainer}>
        <div className={styles.leftpartConatiner}>
          <Contact profile={sections.profile.data} />
          <Education education={sections.education?.data} />
          <Awards awards={sections.awards?.data} />
          <TechnicalSkills technicalSkills={sections.technicalSkills?.data} />
        </div>
        <div className={styles.rightpartConatiner}>
          <About Professionalsummary={sections.Professionalsummary.data} />
          <Experience experience={sections.experience?.data} />
          <Projects projects={sections.projects?.data} />
          <Optional optional={sections.optional?.data} />
        </div>
        </div>
      </div>
    </div>
  );
};

const sectionTitle = (title) => {
  return (
    <>
      <span className={styles.sectionTitle}>{title}</span>
      <div className={styles.hr}></div>
    </>
  );
};

const TopHeader = ({ profile }) => {
  if (!profile) return null;
  return (
    <div className={styles.topHeaderContainer}>
      <div className={styles.nameandpostioncontainer}>
        <div className={styles.nameashu}>{profile.name}</div>
      </div>
      <div className={styles.postion}>{profile.position}</div>
    </div>
  );
};

const Contact = ({ profile }) => {
  if (!profile) return null;
  return ( 
    <div>
    {sectionTitle("Contact")}
    <div className={styles.headerContainer}>
      <div className={styles.resumesampletexttop}>

      <span>
          {" "}
          {profile.phone && <Phone size={17} className={styles.ticketcheck} />}
          <span className={styles.bold}>{profile.phone}</span>{" "}
        </span>
        <span>
          {profile.email && <Mail size={17} className={styles.ticketcheck} />}
          <span className={styles.bold}>{profile.email}</span>{" "}
        </span> 

        <span>
          {" "}
          {profile.address && <MapPin size={17} className={styles.ticketcheck} />}
          <span className={styles.bold}>{profile.address}</span>{" "}
        </span>

       
       
        <span>
          {" "}
          {profile.linkedinUsername && <LinkedinIcon size={17} className={styles.ticketcheck} />}
          <span className={styles.bold}>{profile.linkedinUsername}</span>{" "}
        </span>
        <span>
          {" "}
          {profile.githubUsername && <GithubIcon size={17} className={styles.ticketcheck} />}
          <span className={styles.bold}>{profile.githubUsername}</span>{" "}
        </span>
      </div>
    </div>
    </div>
  );
};

const About = ({ Professionalsummary }) => {
  if (!Professionalsummary) return null;
  return (
    <div className={styles.aboutContainer}>
      {sectionTitle("Professional Summary")}
      <div className={styles.secondline}>
        <span
          className={styles.resumesampletextabout}
          dangerouslySetInnerHTML={{ __html: Professionalsummary?.summary }}
        />
      </div>
    </div>
  );
};

const Points = ({ points, years, secondary }) => {
  if (!points) return null;
  return (
    <ul className={styles.points}>
      {points?.map((point, index) => (
        <li key={index} className={styles.li}>
          <div className={styles.eeor}>
            <div
              key={index}
              dangerouslySetInnerHTML={{
                __html: getSafeMarkdownString(point),
              }}
            />
            {years && <i>{years[index]}</i>}
          </div>
        </li>
      ))}
    </ul>
  );
};

const Experience = ({ experience }) => {
  if (!experience || experience.length === 0) return null;
  return (
    <div>
      {sectionTitle("PROFESSIONAL EXPERIENCE")}

      {experience?.map((exp, index) => (
        <div key={index} className={styles.experienceContainer}>
          <div className={styles.her}>
            <div className={styles.roleandtimeperiodcontainer}>
              <span className={styles.role}>{exp.role}</span>
              <span className={styles.timePeriod}>{exp.timePeriod}</span>
            </div>
            <div className={styles.companyandlocationcontainer}>
              <span className={styles.company}>{exp.company}</span>
              <span className={styles.location}>{exp.location}</span>
            </div>
          </div>

          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: exp.description }}
          />
          <div className={styles.pointsContainer}>
            <Points points={exp.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Projects = ({ projects }) => {
  if (!projects || projects.length === 0) return null;
  return (
    <div>
      {sectionTitle("PROJECTS")}
      {projects?.map((project, index) => (
        <div key={index}>
          <div className={styles.her}>
            <div className={styles.roleandtimeperiodcontainer}>
              <span className={styles.role}>{project.title}</span>
              <span className={styles.timePeriod}>{project.year}</span>
            </div>
            <div className={styles.companyandlocationcontainer}>
              <span className={styles.company}>{project.organisation}</span>
            </div>
          </div>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: project.description }}
          />
          <div className={styles.pointsContainer}>
            <Points points={project.points} markdown={true} secondary={true} />
          </div>
        </div>
      ))}
    </div>
  );
};

const Education = ({ education }) => {
  if (!education || education.length === 0) return null;
  return (
    <div  >
      {sectionTitle("EDUCATION")}
      <div className={styles.educationContainer}>
      {education.map((edu, index) => (
        <span key={index} className={styles.doro}>
          <div className={styles.edud}>
            <span className={styles.resumesampleh2}>{edu.college}</span>
            <span>{edu.year}</span>
          </div>
          <div className={styles.edud}>
            <span>
              {" "}
              {edu.degree}  
            </span>
            <span>
              {edu.cgpa
                ? `${edu.cgpa}`
                : "" || edu.percentage
                ? `${edu.percentage}%`
                : ""}
            </span>
          </div>
          <div className={styles.edud}>
            <span>
              {" "}
              {edu.branch} 
            </span>
             
          </div>
        </span>
      ))}
      </div>
    </div>
  );
};

const TechnicalSkills = ({ technicalSkills }) => {
  if (!technicalSkills || technicalSkills.length === 0) return null;
  return (
    <div className={styles.technicalSkillsContainer}>
      {sectionTitle("EXPERTISE ")}

      <div className={styles.skillsContainer}>
        {technicalSkills.map((tech, index) => (
          <div key={index} className={styles.skillItem}>
            <span className={styles.skillSet}>
              {tech.skills?.map((skill, idx) => (
                <span
                  key={idx}
                  className={
                    index === 0 ? styles.firstSkillSet : styles.otherSkillSet
                  }
                >
                  <SendHorizonal size={17} className={styles.ticketcheck} /> {skill}
                </span>
              ))}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const Awards = ({ awards }) => {
  if (!awards || awards.length === 0) return null;
  return (
    <div className={styles.awardContainer}>
      {sectionTitle("Carrier Highlights")}
      <ul className={`${styles.pointsAward} ${styles.noBullets}`}>
        {awards.map((award, index) => (
          <li key={index} className={styles.awardsli}>
            <div className={styles.awardpoint}>
              <strong>{award.name ? `${award.name} : ` : ""}</strong>
              <span
                className={styles.awarddescription}
                dangerouslySetInnerHTML={{ __html: award.description }}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Optional = ({ optional }) => {
  if (!optional || optional.length === 0) return null;
  return (
    <div>
      {optional.map((item, index) => (
        <>
          {sectionTitle(item.name)}

          <span
            className={styles.resumesampletextabout}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </>
      ))}
    </div>
  );
};

export default LLTResume;
