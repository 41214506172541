import React from "react";
import styles from "../../assets/Dashboard/HeadCountMobile.module.css";
import { useNavigate } from "react-router-dom";
const HeadContMobile = ({auth, modalShow, setModalShow, SecondmodalShow, setSecondModalShow}) => {
  const Navigate = useNavigate();
  return (
    <div className={styles.main}>
      <div className={styles.mainContainer}>
        <div className={styles.headingContainer}>
          <div className={styles.name}> Hi {auth?.user?.[0]?.firstname}  !</div>
          <div className={styles.para}>
            Let’s help you find your next best opportunity
          </div>
        </div>
        <div className={styles.aiButtonContianer}>
          <div className={styles.buttonai} onClick={() => setModalShow(true)}>
            {" "}
            <img
              src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1730125387/all/j0mwupkoxeg29eiuetxn.svg"
              alt="sa"
              className={styles.starimage}
            />{" "}
            Ai Resume Builder <div className={styles.circle}> </div>{" "}
          </div>
        </div>
        <div className={styles.FourBoxContianer}>
          <div className={styles.firstTwoContainer}>
            <div className={styles.box1}>
              {" "}
             

              <div className={styles.eachcont} onClick={() => Navigate("/profile")}>
                <div className={styles.eachimageContainer}>
                  <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729251588/all/yzhotd5rlcg2lmb9akne.svg" />
                </div>
                <div className={styles.beloweachImageContainer}>
                  <div className={styles.numberContainer}>
                    <div className={styles.numberContainerleft}>
                      <div className={styles.leftround}>60%</div>
                    </div>
                    <div className={styles.numberContainerright}>
                      <div className={styles.title}>Profile Completed</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.box2}>
              <div className={styles.eachcont}>
                <div className={styles.eachimageContainer}>
                  <img style={{marginTop:"1rem"}} src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729253034/all/djip5jw0tk6hlvtoqdmc.svg" />
                </div>
                <div className={styles.beloweachImageContainer}>
                  <div className={styles.numberContainer}>
                    <div className={styles.numberContainerleft}>
                      <div className={styles.leftround}>64%</div>
                    </div>
                    <div className={styles.numberContainerright}>
                      <div className={styles.title}>Resume Score</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.secondTwoContainer}>
            <div className={styles.box3}>
              {" "}
              <div className={styles.eachcont}>
                <div className={styles.eachimageContainer}>
                  <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729252768/all/vveycedsyyiinrhh3taf.svg" />
                </div>
                <div className={styles.beloweachImageContainer}>
                  <div className={styles.numberContainer}>
                    <div className={styles.numberContainerleft}>
                      <div className={styles.leftround}>07</div>
                    </div>
                    <div className={styles.numberContainerright}>
                      <div className={styles.title}>Jobs Matched</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.box4}>
              {" "}
              <div className={styles.eachcont}>
                <div className={styles.eachimageContainerspecial}>
                  <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729253676/all/jjtfmtzjjcgqrdyetv3e.svg" />
                </div>
                <div className={styles.beloweachImageContainerspecial}>
                  <div className={styles.ContentContainer}>
                    <div className={styles.ContentContainerUp}>
                      <div className={styles.contenthead}>
                        Check ATS Score before applying
                      </div>
                    </div>
                    <div className={styles.ContentContainerDown}>
                      <div className={styles.buttonCheck}>Check Now</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
      
    </div>
  );
};

export default HeadContMobile;
