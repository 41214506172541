import React from "react";
import styles from "../../assets/HomePage/Top.module.css";
import { ArrowRight, Briefcase, ScanSearch, FileSearch } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../ResumeBuilder/builder/components/context/auth";
import { motion } from "framer-motion";
import { useJobs } from "../../ResumeBuilder/builder/components/context/Jobs";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
// import CompanyScroll from "./CompanyScroll";

const buttonVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  hover: { 
    scale: 1.05,
    y: -3,
    transition: { type: "spring", stiffness: 300 }
  },
  tap: { scale: 0.98 }
};

const containerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const Top = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { setActiveResume} = useJobs();
  const { selectedTemplate , handleTemplateChange} = useResume();

  const handlesubmit = () => {
    navigate("/welcome");
  };
  const handlesubmitjob = () => {
    navigate("/jobs");
  };
  const handleNavigateAtsAnalysis = (name) => {
    setActiveResume(name)
    if (!selectedTemplate || selectedTemplate == null) {
      handleTemplateChange("iitg");
    }
    if(name === 'ATS'){
      setActiveResume(3)
    } else {
      setActiveResume(2)
    }
    navigate('/welcome')
  }
  return (
    <div className={styles.main}>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-11 col-md-6 d-flex justify-content-center  align-items-center ">
            <div className={styles.leftSection}>
             

              <div className="col-11 col-md-5 d-lg-none">
                <div className={styles.imagecon}>
                  <img
                    src="https://res.cloudinary.com/dvepwlz9j/image/upload/f_auto,q_auto/v1/all/bsqgtfftmwkfhdickjmy"
                    className="img-fluid object-contain  "
                    style={{ height: "14rem" }}
                    alt="ds"
                  />
                </div>
              </div>
              <p style={{ fontWeight: "600" }}>
                Best Job Portal and{" "}
                <span
                  style={{
                    background:
                      "linear-gradient(270deg, rgb(160, 102, 255) 16.03%, rgb(102, 108, 255) 88.19%)",
                    color: "transparent",
                    backgroundClip: "text",
                  }}
                >
                  AI Resume Maker
                </span>{" "}
              </p>
              <h2 className={styles.heading}>
                Elevate your career with MyFuse
                <span style={{ color: "rgb(249, 111, 89)" }}>.</span>In
              </h2>
              <p>
                Explore abundant career opportunities and partner with your
                desired companies!
              </p>
              {/* // this is for the showing the score to of the profile completed i am removing it due to the four other content is need to be added  */}
              {/* {auth.token !== "" && (
                <div className={styles.statuscontainerre}>
                  <div className={styles.statusinnerconteiner}>
                    <div className={styles.lefttotla}>
                      <div className={styles.imgcontainer}>
                        <img
                          width="37"
                          height="37"
                          alt="Ashutosh Kumar"
                          src={
                            auth?.user?.[0]?.images
                              ? auth?.user?.[0]?.images
                              : "https://media.lordicon.com/icons/wired/flat/21-avatar.gif"
                          }
                          className={styles.imgss}
                        />
                      </div>
                      <div className={styles.namecontainer}>
                        <span className={styles.names}>
                          Hy !! {auth?.user?.[0]?.firstname}{" "}
                          {auth?.user?.[0]?.lastname}
                        </span>
                        <p className={styles.email}>
                          Let's sprinkle some AI magic on this profile!
                        </p>
                      </div>
                    </div>
                    <div className={styles.statusbar}>
                      <div className={styles.online}>
                        <div
                          className={styles.fill}
                          style={{ width: "80%" }}
                        ></div>
                      </div>
                      <span className={styles.percent}>80%</span>
                    </div>

                    <div className={styles.completeProfileContainer}>
                      <span
                        className={styles.completeProfileButton}
                        onClick={() => navigate("welcome/account")}
                      >
                        Complete Profile
                      </span>
                    </div>
                  </div>
                </div>
              )} */}

              {/* // this is for the buttons  */}
              {/* {auth.token === "" && ( */}
                <div className={styles.cardGrid}>
                   
                  <motion.div 
                    className={styles.buttons}
                    variants={containerVariants}
                    initial="initial"
                    animate="animate"
                  >
                    <motion.span
                      className={`${styles.button} ${styles.buttonAi}`}
                      onClick={handlesubmit}
                      variants={buttonVariants}
                      whileHover="hover"
                      whileTap="tap"
                    >
                      <motion.img
                        src="https://internshala.com/static/images/ai_incentive_banners/star_logged_out.svg"
                        alt="AI Resume"
                        className={styles.buttonIcon}
                        animate={{ rotate: 360 }}
                        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                      />
                      <div className={styles.buttonContent}>
                        <span className={styles.buttonTitle}>AI Resume</span>
                        <span className={styles.buttonSubtitle}>Create Smart Resume</span>
                      </div>
                      <ArrowRight size={18} className={styles.arrowIcon} />
                    </motion.span>

                    <motion.span
                      className={`${styles.button} ${styles.findjobs}`}
                      onClick={handlesubmitjob}
                      variants={buttonVariants}
                      whileHover="hover"
                      whileTap="tap"
                    >
                       <motion.div className={styles.buttonIcon}>
                        <Briefcase size={20} />
                      </motion.div>
                      <div className={styles.buttonContent}>
                        <span className={styles.buttonTitle}>Find Jobs</span>
                        <span className={styles.buttonSubtitle}>Search for job opportunities</span>
                      </div>
                      <ArrowRight size={18} className={styles.arrowIcon} />
                    </motion.span>

                    <motion.span
                      className={`${styles.button} ${styles.jobMatching}`}
                      onClick={()=>handleNavigateAtsAnalysis('ATS')}
                      variants={buttonVariants}
                      whileHover="hover"
                      whileTap="tap"
                    >
                      <motion.div className={styles.buttonIcon}>
                        <ScanSearch size={20} />
                      </motion.div>
                      <div className={styles.buttonContent}>
                        <span className={styles.buttonTitle}>Job Matching</span>
                        <span className={styles.buttonSubtitle}>Match your resume with job opportunities</span>
                      </div>
                      <ArrowRight size={18} className={styles.arrowIcon} />
                    </motion.span>

                    <motion.span
                      className={`${styles.button} ${styles.resumeAnalysis}`}
                      onClick={() => navigate("/resume-score")}
                      variants={buttonVariants}
                      whileHover="hover"
                      whileTap="tap"
                    >
                      <motion.div className={styles.buttonIcon}>
                        <FileSearch size={20} />
                      </motion.div>
                      <div className={styles.buttonContent}>
                        <span className={styles.buttonTitle}>Resume Analysis</span>
                        <span className={styles.buttonSubtitle}>Analyze your resume</span>
                      </div>
                      <ArrowRight size={18} className={styles.arrowIcon} />
                    </motion.span>
                  </motion.div>
                </div>
              {/* )} */}

              <div className={styles.truted}>
                <p>Trusted by job seekers around the globe</p>
                <div style={{ display: "flex ", gap: "10px" }}>
                  <img
                    src="https://cdn.icon-icons.com/icons2/2428/PNG/512/google_black_logo_icon_147125.png"
                    alt="d"
                    style={{ width: "2rem" }}
                  />
                  <img
                    src="https://icones.pro/wp-content/uploads/2021/08/icone-amazon-noir.png"
                    alt="d"
                    style={{ width: "2rem" }}
                  />
                  <img
                    src="https://cdn-icons-png.freepik.com/512/5969/5969147.png"
                    alt="d"
                    style={{ width: "2rem" }}
                  />
                  <img
                    src="https://cdn1.iconfinder.com/data/icons/andriod-app-logo/32/icon_flipkart-512.png"
                    alt="d"
                    style={{ width: "2rem" }}
                  />
                  <img
                    src="https://cdn-icons-png.freepik.com/256/220/220802.png?semt=ais_hybrid"
                    alt="d"
                    style={{ width: "2rem" }}
                  />
                </div>
                <div className={styles.peekingCharacter}></div>

              </div>
            </div>
          </div>
          <div className=" d-none d-md-block col-11 col-md-5 ">
            <div className={styles.imagecon}>
              <img
                src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1721666887/support-os_g4pwfy.png"
                className="img-fluid"
                alt="sda"
              />
            </div>
            
          </div>
        </div>
        {/* <CompanyScroll/> */}
      </div>
    </div>
  );
};

export default Top;
