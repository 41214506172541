import React, { useEffect, useState } from "react";
import { useAuth } from "../../ResumeBuilder/builder/components/context/auth";
import { useNavigate } from "react-router-dom";
import styles from "../../assets/Navbar.module.css";
import { toast } from "react-hot-toast";
import imgn from "../../assets/noti.png";
import cgatn from "../../assets/chat.png";
import { useJobs } from "../../ResumeBuilder/builder/components/context/Jobs";
import {
  Bell,
  BriefcaseBusinessIcon,
  Crown,
  LogIn,
  LogOut,
  MessageCircleHeart,
  MessageSquare,
  MessageSquareMore,
  NotebookPenIcon,
  Settings,
} from "lucide-react";
const LastOne = ({ handleError, imgSrc }) => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [modalShow, setModalShow] = useState(false);
  const [loginok, setloginok] = useState(false);
  const { payment } = useJobs();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotiDropdownOpen, setIsNotiDropdownOpen] = useState(false);
  const [isChatDropdownOpen, setIsChatDropdownOpen] = useState(false);
  const handleMouseEnter = () => setIsDropdownOpen(true);
  const handleMouseLeave = () => setIsDropdownOpen(false);
  const handleNotiMouseEnter = () => setIsNotiDropdownOpen(true);
  const handleNotiMouseLeave = () => setIsNotiDropdownOpen(false);
  const handleChatMouseEnter = () => setIsChatDropdownOpen(true);
  const handleChatMouseLeave = () => setIsChatDropdownOpen(false);
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successful!!");
    navigate("/");
  };

  useEffect(() => {
    if (auth.token !== "") {
      setloginok(true);
    }
  }, [auth]);

  return (
    <div className="d-none d-lg-flex justify-content-center ml-2 mr-2  align-items-center   ">
      <div style={{ width: "10rem" }}>
        <span className={`${styles.noDisplay} ${styles.credit}`}>
          {payment?.paymentStatus === "success" ? "Unlimited Credit" : "AI Credit Left : 1"}
        </span>
      </div>

      {!auth.user ? (
        <>
          <div
            className={styles.registerButton}
            onClick={() => navigate("/signup")}
          >
            Register
          </div>
          <div
            className={styles.loginButton}
            onClick={() => navigate("/login")}
          >
            {" "}
            Login <LogIn width={19} style={{ marginLeft: "6px" }} />
          </div>
        </>
      ) : (
        <>

          {/* this is bell icon navbbar  */}
          {/* <div>
            <div
              className={styles.wavecontainer}
              onMouseEnter={handleNotiMouseEnter}
              onMouseLeave={handleNotiMouseLeave}
              style={{ marginLeft: "1rem" }}
            >
              <Bell className={styles.bellIcon} size={20} />
              <div className={styles.forhoving}> </div>
            </div>
            <div className={styles.dropdown}>
              {isNotiDropdownOpen && (
                <div
                  className={`${styles.dropdowncontentNoti}`}
                  onMouseEnter={handleNotiMouseEnter}
                  onMouseLeave={handleNotiMouseLeave}
                >
                  <div>
                    <span className={styles.notitext}>Notification</span>
                  </div>
                  <div className={styles.underline}> </div>

                  <div className={styles.noitcontent}>
                    <img src={imgn} alt="Notification" />
                    <span className={styles.notino}>No New Notification</span>
                    <p className={styles.funny}>
                      Your notifications must be taking a well-deserved break,
                      soaking up some sunshine and relaxing!
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div> */}
  
           {/* // this is message icon  */}
          {/* <div>
            <div
              className={styles.wavecontainer}
              onMouseEnter={handleChatMouseEnter}
              onMouseLeave={handleChatMouseLeave}
            >
              <MessageSquare className={styles.chatIcon} size={20} />
              <div className={styles.forhoving}> </div>
            </div>
            <div className={styles.dropdown}>
              {isChatDropdownOpen && (
                <div
                  className={`${styles.dropdowncontentNoti}`}
                  onMouseEnter={handleChatMouseEnter}
                  onMouseLeave={handleChatMouseLeave}
                >
                  <div>
                    <span className={styles.notitext}>Chats</span>
                  </div>
                  <div className={styles.underline}> </div>

                  <div className={styles.noitcontent}>
                    <img src={cgatn} alt="Chat" style={{ height: "8rem" }} />
                    <span className={styles.notino}>No New Chat</span>
                    <p className={styles.funny}>
                      Looks like your chat is enjoying a peaceful moment of
                      serenity, basking in the tranquility of no new messages!
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div> */}

          <div>
            <div
              className={styles.wavecontainer}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ marginLeft: "1rem" }}
            >
              <img
                src={auth?.user?.[0]?.images ? auth?.user?.[0]?.images : imgSrc}
                onError={handleError}
                className={styles.waveimage}
                alt="pic"
              />
              <div className={styles.wave}></div>
              <div className={styles.forhoving}> </div>
            </div>
            <div className={styles.dropdown}>
              {isDropdownOpen && (
                <div
                  className={`${styles.dropdowncontent} ${styles.opendropdown}`}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {loginok && (
                    <>
                      <div className={styles.mainsim}>
                        <div
                          className={`${styles.imageSection} ${styles.roun}`}
                        >
                          <img
                            src={
                              auth?.user?.[0]?.images
                                ? auth?.user?.[0]?.images
                                : imgSrc
                            }
                            alt="sfas"
                          />
                          <div className={styles.wave2}></div>
                        </div>

                        <div className={styles.textsecion}>
                          <span className={styles.nameofmobile}>
                            {auth?.user?.[0]?.firstname}{" "}
                            {auth?.user?.[0]?.lastname}{" "}
                          </span>
                          <span className={styles.email}>
                            {" "}
                            {auth?.user?.[0]?.email}{" "}
                          </span>
                          <span className={styles.shortDescription}>
                            {" "}
                            {auth?.user?.[0]?.shortDescription}{" "}
                          </span>
                        </div>
                      </div>
                      <div className={styles.viewandupdateContainer}>
                        <span
                          onClick={() => navigate("/welcome/account")}
                          className={styles.viewandupdate}
                        >
                          {" "}
                          View & Update Profile
                        </span>
                      </div>
                    </>
                  )}

                  <div className={styles.underline}> </div>

                  <div className={styles.dropdownItems}>
                    <div
                      className={styles.dropdownItem}
                      onClick={() => navigate("/welcome/account")}
                    >
                      <BriefcaseBusinessIcon
                        size={15}
                        style={{ marginRight: "4px" }}
                      />{" "}
                      Profile
                    </div>
                    {/* <div
                      className={styles.dropdownItem}
                      onClick={() => navigate("/welcome/jobapplication")}
                    >
                      <NotebookPenIcon
                        size={15}
                        style={{ marginRight: "4px" }}
                      />
                      Job Application
                    </div> */}
                    {/* <div
                      className={styles.dropdownItem}
                      onClick={() => navigate("/welcome/settings")}
                    >
                      <Settings size={15} style={{ marginRight: "4px" }} />
                      Settings
                    </div> */}
                    {/* <div
                      className={styles.dropdownItem}
                      onClick={() => navigate("/welcome/chat")}
                    >
                      <MessageSquareMore
                        size={15}
                        style={{ marginRight: "4px" }}
                      />
                      Chat For Help
                    </div> */}
                    {/* <div
                      className={styles.dropdownItem}
                      onClick={() => navigate("/welcome/saved")}
                    >
                      <MessageCircleHeart
                        size={15}
                        style={{ marginRight: "4px" }}
                      />
                      Feedback and rate
                    </div> */}
                    <div className={styles.dropdownItem} onClick={handleLogout}>
                      <LogOut size={15} style={{ marginRight: "4px" }} />
                      Logout
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LastOne;
