import {
  faLocationDot,
  faBriefcase,
  faUserTie,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../ResumeBuilder/builder/components/context/auth";
import { useJobs } from "../ResumeBuilder/builder/components/context/Jobs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  History,
  Share2,
  BookmarkCheck,
  BookmarkPlus,
  Search,
  Copy,
  X,
} from "lucide-react";
import slugify from "slugify";
import {
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";
import React, { useEffect,useState } from "react";
import { toast } from "react-hot-toast";
import HelmetConfig from "../components/HelmetConfig";
import BottomBar from "../components/BottomBar";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../assets/Job.module.css";
import Modal from "react-bootstrap/Modal";
import MyResponsiveNavbar from "./Navbar";
import Filter from "../components/Filter";
import "swiper/css/effect-cards";
import QRCode from "react-qr-code";
import Footer from "./Footer";
import "swiper/css";
import { motion} from "framer-motion";
import { privateApi, publicApi } from "../ResumeBuilder/utils/aixos";

const getRelativeDate = (date) => {
  const now = new Date();
  const diffTime = Math.abs(now - date);
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 0) {
    return "today";
  } else if (diffDays === 1) {
    return "yesterday";
  } else if (diffDays <= 6) {
    return `${diffDays} days ago`;
  } else if (diffDays <= 13) {
    return "1 week ago";
  } else if (diffDays <= 20) {
    return "2 weeks ago";
  } else {
    return date.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
  }
};

const JobTypeSelector = ({ showJobType, handlers }) => {
  const jobTypes = [
    { id: "all", label: "Recent Jobs", count: "500+" },
    { id: "internship", label: "Internship", count: "100+" },
    { id: "you", label: "Jobs For You", count: "80+" },
    { id: "applied", label: "Applied", count: "0" },
    { id: "saved", label: "Saved", count: "0" }
  ];

  return (
    <motion.div 
      className={styles.jobTypeContainer}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      <div className={styles.glowBackground}>
        <motion.div 
          className={styles.jobTypeWrapper}
          layout
        >
          {jobTypes.map((type) => (
            <motion.button
              key={type.id}
              className={`${styles.jobTypeButton} ${showJobType === type.id ? styles.active : ''}`}
              onClick={() => handlers[type.id]()}
              whileHover={{ 
                scale: 1.02,
                backgroundColor: "rgba(88, 17, 175, 0.15)",
              }}
              whileTap={{ scale: 0.98 }}
              layout
            >
              <motion.span className={styles.buttonContent}>
                <motion.span className={styles.label}>
                  {type.label}
                </motion.span>
                <motion.span 
                  className={styles.count}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 }}
                >
                  {type.count}
                </motion.span>
              </motion.span>
              {showJobType === type.id && (
                <motion.div
                  className={styles.activeIndicator}
                  layoutId="activeIndicator"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                />
              )}
            </motion.button>
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

const JobCard = ({ job, index, handleNavigate, handleShow, setsaved, handlBookmark, formattedDate }) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.6, -0.05, 0.01, 0.99],
        staggerChildren: 0.1
      }
    },
    hover: {
      y: -5,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  return (
    <motion.div
      className="col-md-12 col-12"
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
      style={{ margin: "auto", padding: "10px" }}
    >
      <motion.div 
        className={`${styles.jobscardMainContainer}`}>
        {/* Gradient overlay */}
        <motion.div 
          className={`${styles.jobscardGradientOverlay}`}
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 0.8, delay: index * 0.2 }}
        />

        <motion.div className="card-body p-4">
          {/* Header Section */}
          <motion.div
            className="d-flex justify-content-between align-items-start"
            onClick={() => handleNavigate(job.id_jobpost, job.jobtitle)}
            style={{ cursor: "pointer" }}
          >
            <motion.div>
              <motion.h3 
                className={styles.hddh}
                
                whileHover={{ color: "#5811af" }}
              >
                {job.jobtitle}
              </motion.h3>
              <motion.div  
                className={`${styles.jobscardCompanyName}`}
              >
                <span style={{ fontWeight: "500" }}>{job.companyName}</span>
                <span>•</span>
                <span  
                  className={`${styles.jobscardJobNature}`}  >
                  {job.job_nature}
                </span>
              </motion.div>
            </motion.div>

            <motion.div 
              className={`${styles.jobscardCompanyLogo}`}
              
            >
              <motion.img
                src={`https://old.myfuse.in/uploads/logo/${job.companyLogo}`} 
                className={`${styles.jobscardCompanyLogoImage}`}
                alt="company logo"
                whileHover={{ scale: 1.05 }}
              />
            </motion.div>
          </motion.div>

          {/* Job Details Grid */}
          <motion.div 
            className="mt-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <div className="row g-3">
              {[
                { icon: faBriefcase, label: "Qualification", value: job.qualification },
                { icon: faLocationDot, label: "Location", value: job.location },
                { icon: faMoneyCheckDollar, label: "Salary", value: job.minimumsalary },
                { icon: faUserTie, label: "Experience", value: job.experience }
              ].map((detail, idx) => (
                <motion.div 
                  key={idx}
                  className="col-6 col-md-3"
                  whileHover={{ y: -2 }}
                >
                  <motion.div
                    className={`${styles.jobscardJobDetails}`}
                     
                  >
                    <motion.div 
                      className={`${styles.jobscardJobDetailsIcon}`}
                      
                    >
                      <FontAwesomeIcon  className={`${styles.jobscardJobDetailsIconFontAwesome}`} icon={detail.icon} />
                      <span  className={`${styles.jobscardJobDetailsLabels}`}>
                        {detail.label}
                      </span>
                    </motion.div>
                    <motion.div 
                      className={`${styles.jobscardJobDetailsValue}`}
                     >
                      {detail.value?.length > 20 ? `${detail.value.substring(0, 20)}...` : detail.value}
                    </motion.div>
                  </motion.div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Footer Section */}
          <motion.div 
            className="d-flex justify-content-between align-items-center mt-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <motion.div 
              className={`${styles.jobscardJobDetaildate}`}
              
            >
              <History size={16} />
              <span>Posted {formattedDate}</span>
            </motion.div>

            <motion.div 
              style={{
                display: "flex",
                gap: "12px"
              }}
            >
              <motion.button
                className={`${styles.jobscardJobDetailsButton}`}
                
                whileHover={{ 
                  scale: 1.02,
                  background: "rgba(88, 17, 175, 0.1)"
                }}
                whileTap={{ scale: 0.98 }}
                onClick={() => handleShow(job.id_jobpost, job.jobtitle)}
              >
                <Share2 size={18} />
                <span>Share</span>
              </motion.button>

              <motion.button 
                className={`${styles.jobscardJobDetailsButton}`}
                
                whileHover={{ 
                  scale: 1.02,
                  background: "rgba(88, 17, 175, 0.1)"
                }}
                whileTap={{ scale: 0.98 }}
                onClick={handlBookmark}
              >
                {!setsaved ? (
                  <>
                    <BookmarkCheck size={18} />
                    <span>Saved</span>
                  </>
                ) : (
                  <>
                    <BookmarkPlus size={18} />
                    <span>Save</span>
                  </>
                )}
              </motion.button>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

const JobCardSkeleton = () => {
  return (
    <motion.div
      className="col-md-12 col-12"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ margin: "auto", padding: "10px" }}
    >
      <motion.div 
        style={{
          background: "rgba(255, 255, 255, 0.98)",
          backdropFilter: "blur(10px)",
          borderRadius: "16px",
          border: "1px solid rgba(88, 17, 175, 0.1)",
          boxShadow: "0 4px 20px rgba(88, 17, 175, 0.08)",
          overflow: "hidden",
          position: "relative"
        }}
      >
        {/* Gradient overlay */}
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: "linear-gradient(90deg, #5811af 0%, #9a29c0 100%)",
            opacity: 0.5
          }}
        />

        <div className="card-body p-4">
          {/* Header Section */}
          <div className="d-flex justify-content-between align-items-start">
            <div style={{ flex: 1 }}>
              <div 
                style={{
                  height: "28px",
                  width: "70%",
                  background: "rgba(88, 17, 175, 0.08)",
                  borderRadius: "6px",
                  marginBottom: "12px"
                }}
                className="skeleton-pulse"
              />
              <div 
                style={{
                  height: "20px",
                  width: "40%",
                  background: "rgba(88, 17, 175, 0.08)",
                  borderRadius: "6px"
                }}
                className="skeleton-pulse"
              />
            </div>
            <div
              style={{
                width: "56px",
                height: "56px",
                background: "rgba(88, 17, 175, 0.08)",
                borderRadius: "12px"
              }}
              className="skeleton-pulse"
            />
          </div>

          {/* Job Details Grid */}
          <div className="mt-4">
            <div className="row g-3">
              {[1, 2, 3, 4].map((_, idx) => (
                <div key={idx} className="col-6 col-md-3">
                  <div
                    style={{
                      padding: "12px",
                      background: "rgba(88, 17, 175, 0.03)",
                      borderRadius: "12px",
                      height: "100%",
                      border: "1px solid rgba(88, 17, 175, 0.08)"
                    }}
                  >
                    <div 
                      style={{
                        height: "16px",
                        width: "60%",
                        background: "rgba(88, 17, 175, 0.08)",
                        borderRadius: "4px",
                        marginBottom: "8px"
                      }}
                      className="skeleton-pulse"
                    />
                    <div 
                      style={{
                        height: "16px",
                        width: "80%",
                        background: "rgba(88, 17, 175, 0.08)",
                        borderRadius: "4px"
                      }}
                      className="skeleton-pulse"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Footer Section */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <div 
              style={{
                height: "20px",
                width: "120px",
                background: "rgba(88, 17, 175, 0.08)",
                borderRadius: "6px"
              }}
              className="skeleton-pulse"
            />
            <div style={{ display: "flex", gap: "12px" }}>
              {[1, 2].map((_, idx) => (
                <div
                  key={idx}
                  style={{
                    height: "36px",
                    width: "100px",
                    background: "rgba(88, 17, 175, 0.08)",
                    borderRadius: "8px"
                  }}
                  className="skeleton-pulse"
                />
              ))}
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const NoJobsCard = ({ message, imageSrc }) => {
  return (
    <motion.div
      className="col-md-12 col-12"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.6,
        ease: [0.6, -0.05, 0.01, 0.99]
      }}
      style={{  padding: "10px" }}
    >
      <motion.div 
        className={styles.jobsmaindiv}
        style={{
          background: "rgba(255, 255, 255, 0.98)",
          backdropFilter: "blur(10px)",
          borderRadius: "16px",
          border: "1px solid rgba(88, 17, 175, 0.1)",
          boxShadow: "0 4px 20px rgba(88, 17, 175, 0.08)",
          overflow: "hidden",
          position: "relative"
        }}
      >
        {/* Gradient overlay */}
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: "linear-gradient(90deg, #5811af 0%, #9a29c0 100%)"
          }}
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 0.8 }}
        />

        <motion.div className="card-body p-4">
          <div className="text-center">
            <motion.img
              src={imageSrc || "https://res.cloudinary.com/dvepwlz9j/image/upload/v1/all/no-results.svg"}
              alt="No jobs found"
              style={{
                maxWidth: "200px",
                marginBottom: "1.5rem",
                filter: "drop-shadow(0 4px 8px rgba(88, 17, 175, 0.15))"
              }}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            />
            
            <motion.h3
              style={{
                fontSize: "1.25rem",
                fontWeight: "600",
                color: "#2d3639",
                marginBottom: "1rem"
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              No Jobs Available
            </motion.h3>
            
            <motion.p
              style={{
                color: "#666",
                fontSize: "0.95rem",
                maxWidth: "500px",
                margin: "0 auto",
                lineHeight: "1.6"
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              {message || "We couldn't find any jobs matching your criteria. Try adjusting your search filters or check back later for new opportunities."}
            </motion.p>

            <motion.div
              style={{
                marginTop: "1.5rem"
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <motion.button
                style={{
                  border: "none",
                  background: "rgba(88, 17, 175, 0.05)",
                  padding: "10px 24px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  color: "#5811af",
                  fontWeight: "500",
                  fontSize: "0.95rem"
                }}
                whileHover={{ 
                  scale: 1.02,
                  background: "rgba(88, 17, 175, 0.1)"
                }}
                whileTap={{ scale: 0.98 }}
                onClick={() => window.location.reload()}
              >
                <Search size={18} style={{ marginRight: "8px" }} />
                Try Different Search
              </motion.button>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

const Jobs = () => {
  const { auth } = useAuth();
  const Navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [setsaved, setissaved] = useState(false);
  let [pageNumber, setPageNumber] = useState(1);
  const { CommingJobs, isfirst, setIsfirst } = useJobs();
  const [skillfromdbapi, setskillfromdibapi] = useState("");
  const [showJobType, setShowJobType] = useState("all");
  const [show, setShow] = useState(false);
  const [slug, setslug] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (value, heading) => {
    setShow(true);
    const slugnew = `${slugify(heading)}-${value}`;
    setslug(slugnew);
  };
  const {jobsInLocation} = useParams();
  useEffect(() => {
    if (!jobsInLocation?.startsWith('jobs-in-')) {
      Navigate('/jobs-in-india'); 
    }
  }, [jobsInLocation, Navigate]);
  const joblocation = jobsInLocation?.replace('jobs-in-', '');
  if(joblocation=='india') {
    joblocation=undefined;  
  }
  const [isLoading, setIsLoading] = useState(true);

  const getcommingJobs = async () => {
    try {
      setIsLoading(true);
      setJobs([]);
      const response = await publicApi.get(`/api/auth/searchJob?search=${CommingJobs}`);
      const data = response.data;
      if (data?.success) {
        const jobs = data.users;
        setJobs(jobs);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getUserSkill = async () => {
    try {
      const datalocal = JSON.parse(localStorage.getItem("auth"));
      const { data } = await privateApi.get(`/api/auth/skillsbyid`);
      setskillfromdibapi(data?.skillsfromdb?.[0].skills);
    } catch (error) {
      console.log("error is comiing from getuserskill", error);
    }
  };

  const getjobforyou = async () => {
    try {
      
      setJobs([]);
      const { data } = await publicApi.get(`/api/auth/filterjob?skills=${skillfromdbapi}`);

      if (data?.success) {
        const jobss = data;
        jobss.jobsWithPercentage.sort(
          (a, b) => b.percentageMatched - a.percentageMatched
        );

        setJobs(jobss.jobsWithPercentage);
      }
     
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (CommingJobs && isfirst) {
      getcommingJobs();
      setIsfirst(false);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CommingJobs]);

  useEffect(() => {
    if(auth.token!=='') {
      getUserSkill();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    
  };

  const handleNavigate = async (value, heading) => {
    const slug = `${slugify(heading)}-${value}`;
    window.open(`/jobs/details/${slug}`, "_blank");
  };

  const handlePrevious = () => {
    setPageNumber(pageNumber--);
  };

  const handleNext = () => {
    setPageNumber(pageNumber++);
  };

  const handleCustomNumber = (n) => {
    setPageNumber(n);
  };

  const handleSearch = async () => {
    try {
      let data; // Define data variable outside of the if-else block
      setJobs([]);
      if (searchTerm !== "") {
        const response = await publicApi.get(`/api/auth/searchJob?search=${searchTerm}`);
        data = response.data;
      }
      if (data?.success) {
        const jobs = data.users;
        setJobs(jobs);
       
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleyearsearch = (value) => {
    Navigate(`/jobs?param=${value}`);
  };

  const handlealljob = () => {
    setShowJobType("all");
    Navigate('')
  };

  const handleInternship = () => {
    setShowJobType("internship");
     Navigate('?jobMode=Internship')
  };

  const handleJobForYou = () => {
    setShowJobType("you");
    if (auth.token !== "") {
      getjobforyou();
    } else {
      setJobs([]);
    }
  };

  const handleapplied = () => {
    setShowJobType("applied");
    setJobs([]);
  };
  const handlesaved = () => {
    setShowJobType("saved");
    setJobs([]);
  };
  const handlBookmark = () => {
    setissaved(!setsaved);
  };

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(`https://myfuse.in/jobs/details/${slug}`)
      .then(() => {
        toast.success("Job URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handlers = {
    all: handlealljob,
    internship: handleInternship,
    you: handleJobForYou,
    applied: handleapplied,
    saved: handlesaved
  };

  return (
    <div>
      <HelmetConfig
        title={`${joblocation ? `Jobs in ${joblocation.charAt(0).toUpperCase() + joblocation.slice(1)}` : 'Job Listings'} | MyFuse Career Portal ${new Date().getFullYear()}`}
        description={`Discover ${joblocation ? `jobs in ${joblocation}` : 'jobs across India'} on MyFuse. Browse through thousands of verified job openings with direct company applications. Find full-time, part-time, remote work, and internship opportunities across IT, Sales, Marketing, Finance, and more sectors. Apply to top companies hiring now!`}
        keywords={`
          ${joblocation ? `Jobs in ${joblocation}, Career opportunities in ${joblocation}, Work in ${joblocation}, ` : ''}
          Latest Job Openings ${new Date().getFullYear()}, Current Job Vacancies, Active Job Listings,
          IT Jobs, Software Developer Jobs, Marketing Jobs, Sales Jobs, Finance Jobs, HR Jobs,
          Entry Level Positions, Mid-Senior Level Jobs, Leadership Roles, CXO Positions,
          Work From Home Jobs, Remote Work Opportunities, Hybrid Work Model,
          Fresher Jobs, Experience Required Jobs, Walk-in Interviews, Direct Company Jobs,
          Salary ${(new Date().getFullYear() - 1)}-${new Date().getFullYear()}, Job Market Trends, Career Growth,
          Top Companies Hiring, Startups Hiring, MNCs Jobs, Indian Companies,
          Full Time Jobs, Part Time Work, Internship Opportunities, Contract Positions,
          Job Search India, Professional Careers, Employment Opportunities, Recruitment ${new Date().getFullYear()},
          Easy Apply Jobs, Urgent Hiring, Immediate Joining, Latest Vacancies
        `}
        canonical={`https://myfuse.in/jobs-in-${joblocation || 'india'}`}
      >
        {/* Add Open Graph tags for better social sharing */}
        <meta property="og:title" content={`${joblocation ? `Jobs in ${joblocation}` : 'Job Listings'} | MyFuse Career Portal ${new Date().getFullYear()}`} />
        <meta property="og:description" content={`Discover ${joblocation ? `jobs in ${joblocation}` : 'jobs across India'} on MyFuse. Browse through thousands of verified job openings with direct company applications.`} />
        <meta property="og:image" content="https://myfuse.in/og-image.jpg" />
        <meta property="og:url" content={`https://myfuse.in/jobs-in-${joblocation || 'india'}`} />
        <meta property="og:type" content="website" />

        {/* Add Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@myfuse" />
        <meta name="twitter:title" content={`${joblocation ? `Jobs in ${joblocation}` : 'Job Listings'} | MyFuse Career Portal ${new Date().getFullYear()}`} />
        <meta name="twitter:description" content={`Discover ${joblocation ? `jobs in ${joblocation}` : 'jobs across India'} on MyFuse. Browse through thousands of verified job openings with direct company applications.`} />
        <meta name="twitter:image" content="https://myfuse.in/twitter-card-image.jpg" />

        {/* Add structured data for job posting */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "JobPosting",
              "name": "${joblocation ? `Jobs in ${joblocation}` : 'Job Listings'} - MyFuse Career Portal",
              "description": "Find the latest job opportunities on MyFuse Career Portal",
              "datePosted": "${new Date().toISOString()}",
              "hiringOrganization": {
                "@type": "Organization",
                "name": "MyFuse",
                "sameAs": "https://myfuse.in"
              },
              "jobLocation": {
                "@type": "Place",
                "address": {
                  "@type": "PostalAddress",
                  "addressCountry": "IN",
                  "addressRegion": "${joblocation || 'India'}"
                }
              }
            }
          `}
        </script>
      </HelmetConfig>
      <MyResponsiveNavbar />
      {/* <div className={styles.job_search_page_banner}>
        <div class={styles.banner_content}>
          <div class={styles.banner_text_container}>
            <div class={styles.banner_text_1}>
              Say goodbye to job search stress!
            </div>
            <div class={styles.banner_text_2}>
              Find jobs with salaries up to{" "}
              <span className="bold" style={{ fontWeight: "800" }}>
                {" "}
                20 LPA
              </span>
            </div>
          </div>
          <div class={styles.banner_image_container}>
            <div class={styles.banner_image}></div>
          </div>
        </div>
      </div> */}
      <div
        className="container-fluid   d-flex justify-content-center"
        style={{ backgroundColor: "#F4F7FE", fontSize: "14px" }}
      >
        <div className="row d-flex  justify-content-center " style={{width:'100%'}}>
          <Filter setJobs={setJobs} jobs={jobs} pageNumber={pageNumber} joblocation={joblocation} isLoading={isLoading}  setIsLoading={setIsLoading}/>

          <div
            className={` col-md-7 scrollable-center mb-4 d-flex   flex-column align-items-start ${styles.dwo}`}
          >
            <motion.div 
              className={styles.search}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, ease: "easeOut" }}
            >
              <motion.input
                type="text"
                placeholder="Search for jobs, companies, or keywords..."
                value={searchTerm}
                onChange={handleInputChange}
                className={styles.des}
                onKeyDown={handleKeyDown}
                aria-label="Search jobs"
                whileFocus={{ scale: 1.01 }}
                transition={{ duration: 0.2 }}
              />
              <motion.button 
                className={styles.searchButton}
                onClick={handleSearch}
                aria-label="Submit search"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.2 }}
              >
                <motion.span
                  animate={{ rotate: searchTerm ? [0, 360] : 0 }}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                >
                  <Search size={19} />
                </motion.span>
                <motion.span 
                  className={styles.searchButtonText}
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 }}
                >
                  Search
                </motion.span>
              </motion.button>
            </motion.div>

            <JobTypeSelector showJobType={showJobType} handlers={handlers} />

            {isLoading ? (
              Array(3).fill(0).map((_, index) => (
                <JobCardSkeleton key={index} />
              ))
            ) : jobs.length > 0 ? (
              jobs?.map((job, index) => (
                <JobCard
                  key={job.id_jobpost}
                  job={job}
                  index={index}
                  handleNavigate={handleNavigate}
                  handleShow={handleShow}
                  setsaved={setsaved}
                  handlBookmark={handlBookmark}
                  formattedDate={getRelativeDate(new Date(job.createdat))}
                />
              ))
            ) : (
              <NoJobsCard 
                message={
                  showJobType === "you" 
                    ? "No personalized job recommendations found. Update your profile to get better matches!"
                    : showJobType === "saved"
                    ? "You haven't saved any jobs yet. Start bookmarking jobs you're interested in!"
                    : showJobType === "applied"
                    ? "You haven't applied to any jobs yet. Start your job search journey today!"
                    : "No jobs found matching your search criteria. Try adjusting your filters or check back later."
                }
                imageSrc={
                  showJobType === "you"
                    ? "https://img.freepik.com/free-vector/artificial-intelligence-concept-illustration_114360-7000.jpg"
                    : showJobType === "saved"
                    ? "https://framerusercontent.com/images/XnZTbKUJdmuRb4sYeRGs1o6Rc5s.svg"
                    : showJobType === "applied"
                    ? "https://img.freepik.com/free-vector/hiring-concept-illustration_114360-532.jpg"
                    : "https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg"
                }
              />
            )}
          </div>
          {/* )} */}

          {/* <div className="col-md-3 mb-4 fixed-col col-11 d-none d-lg-block">
            <div className={styles.sogr}>
              <div className={styles.teammy}>
                <p>
                  Jobs posted on our portal come directly from employers, marked
                  with the '
                  <span
                    className={styles.sssd}
                  >
                    Easy Apply
                  </span>{" "}
                  'option, and are exclusively available through our site.
                </p>
                <p>
                  We’ve handpicked these jobs for you, ensuring you have
                  everything in one place. Share these opportunities with your
                  friends so they can benefit too.
                </p>
                <p  className={styles.fontbold} >---Team Myfuse</p>
              </div>
              <img
                className="img-fluid"
                style={{ marginTop: "1rem", borderRadius: "10px" }}
                src="https://i.ytimg.com/vi/9C9hGpkJNnk/maxresdefault.jpg"
                alt="i"
              />
            </div>
          </div> */}
        </div>
      </div>
      {jobs.length !== 0 && (
        <div
          className="d-flex justify-content-center  "
          style={{ gap: "10px", position: "relative", top: "-2rem" }}
        >
          <span className={`${styles.numbercss}`} onClick={handlePrevious}>
            Previous
          </span>
          <span
            className={`${styles.numbercss} ${
              pageNumber === 1 ? styles.activenum : ""
            }`}
            onClick={() => handleCustomNumber(1)}
          >
            1
          </span>
          <span
            className={`${styles.numbercss} ${
              pageNumber === 2 ? styles.activenum : ""
            }`}
            onClick={() => handleCustomNumber(2)}
          >
            2
          </span>
          <span
            className={`${styles.numbercss} ${
              pageNumber === 3 ? styles.activenum : ""
            }`}
            onClick={() => handleCustomNumber(3)}
          >
            3
          </span>
          <span
            className={`${styles.numbercss} ${
              pageNumber === 4 ? styles.activenum : ""
            }`}
            onClick={() => handleCustomNumber(4)}
          >
            4
          </span>
          <span className={`${styles.numbercss}`} onClick={handleNext}>
            Next
          </span>
        </div>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={styles.shareModal}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3 }}
        >
          <div className={styles.modalHeader}>
            <motion.button
              className={styles.closeButton}
              onClick={handleClose}
              whileHover={{ scale: 1.1, rotate: 90 }}
              whileTap={{ scale: 0.9 }}
            >
              <X size={24} />
            </motion.button>
          </div>

          <Modal.Body className={styles.modalBody}>
            <motion.div 
              className="container p-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <motion.h2 
                className={styles.shareTitle}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                Share This Opportunity
              </motion.h2>

              <motion.div 
                className={styles.shareButtonsContainer}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4 }}
              >
                {[
                  { Button: LinkedinShareButton, Icon: LinkedinIcon, name: 'LinkedIn' },
                  { Button: WhatsappShareButton, Icon: WhatsappIcon, name: 'WhatsApp' },
                  { Button: TwitterShareButton, Icon: TwitterIcon, name: 'Twitter' },
                  { Button: FacebookShareButton, Icon: FacebookIcon, name: 'Facebook' },
                  { Button: EmailShareButton, Icon: EmailIcon, name: 'Email' }
                ].map(({ Button, Icon, name }, index) => (
                  <motion.div
                    key={name}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 + index * 0.1 }}
                  >
                    <Button
                      url={`https://myfuse.in/jobs/details/${slug}`}
                      title="MyFuse Jobs"
                      className={styles.shareButton}
                    >
                      <Icon size={40} round className={styles.shareIcon} />
                      <span className={styles.shareName}>{name}</span>
                    </Button>
                  </motion.div>
                ))}
              </motion.div>

              <motion.div 
                className={styles.shareDetailsContainer}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
              >
                <motion.div 
                  className={styles.qrCodeSection}
                  whileHover={{ scale: 1.02 }}
                >
                  <QRCode
                    value={`https://myfuse.in/jobs/details/${slug}`}
                    size={128}
                    level="H"
                    fgColor="#5811af"
                    className={styles.qrCode}
                  />
                  <p className={styles.qrLabel}>Scan to share</p>
                </motion.div>

                <motion.div className={styles.messageSection}>
                  <motion.textarea
                    className={styles.shareMessage}
                    value={`🚀 Exciting job opportunity alert! 

I found this great position on MyFuse that might interest you:

📎 Check it out here: https://myfuse.in/jobs/details/${slug}

#MyFuse #CareerOpportunity`}
                    readOnly
                    whileFocus={{ scale: 1.01 }}
                  />
                  
                  <motion.button
                    className={styles.copyButton}
                    onClick={handleCopyClick}
                    whileHover={{ scale: 1.05, backgroundColor: "#4c0d99" }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Copy size={18} style={{ marginRight: "8px" }} />
                    Copy to Clipboard
                  </motion.button>
                </motion.div>
              </motion.div>
            </motion.div>
          </Modal.Body>
        </motion.div>
      </Modal>

      <Footer />
      <BottomBar />
    </div>
  );
};

export default Jobs;
