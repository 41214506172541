import React from "react";
import { ArrowRight } from "lucide-react";
import styles from "../assets/AboveNavbar.module.css";
import { useNavigate } from "react-router-dom";
const AboveNavbar = () => {
  const navigate = useNavigate();
  const handlesubmit = () => {
    navigate("/welcome");
  };

  return (
    <div>
      <div className={styles.animation}></div>
      <div className={styles.main}>
        <p className="text-center">Launched AI Resume Builder </p>
        <p className={styles.buttonAi} onClick={handlesubmit}>
          <img
            className={styles.image}
            src="https://internshala.com/static/images/ai_incentive_banners/star_logged_out.svg"
            alt="dfd"
          />{" "}
          AI Resume <ArrowRight size={15} />
        </p>
      </div>
    </div>
  );
};

export default AboveNavbar;
