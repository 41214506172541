import React  from "react";
import styles from "../../assets/Dashboard/left.module.css";
import { BookCopy, FileSearch, BriefcaseBusiness, LayoutDashboard, BarChart2 } from "lucide-react";
import { PiReadCvLogoBold } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { useJobs } from "../../ResumeBuilder/builder/components/context/Jobs";
import { useResume } from "../../ResumeBuilder/builder/components/context/Resume";
const Left = ({auth, image}) => {
  const navigate = useNavigate();
  const { setActiveResume} = useJobs();
  const { selectedTemplate , handleTemplateChange} = useResume();
  
  const handleNavigateAtsAnalysis = (name) => {
    setActiveResume(name)
    if (!selectedTemplate || selectedTemplate == null) {
      handleTemplateChange("iitg");
    }
    if(name === 'ATS'){
      setActiveResume(3)
    } else {
      setActiveResume(2)
    }
    navigate('/editor')
  } 
 
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.arrow}>
          <img src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729184893/all/jkyoipqkrfap83ucnly3.svg" />
        </div>
        <span className={styles.logo}>
          MyFuse<span className={styles.dorcolor}>.</span>In
        </span>
        <div className={styles.subcontainer}>
          <div className={styles.profilecontainer}>
            <div className={styles.imageContainer}>
              <img
                src={image}
                alt="profile" 
                className={styles.profileimage}
              />
              <span onClick={()=>navigate('/account')}>
                <img
                  src="https://res.cloudinary.com/dvepwlz9j/image/upload/v1729186563/all/bqrjzzlw6qfepvwn0o3g.svg"
                  alt="pen"
                />
              </span>
            </div>
          </div>
          <div className={styles.detailsContainer}>
            <span className={styles.name}>{auth?.user?.[0]?.firstname}  {auth?.user?.[0]?.lastname} </span>
            
                {/* not yet in database so comment out  */}
            {/* <span className={styles.email}>-----</span>
            <span className={styles.exp}>-</span> */}
          </div>

          <div className={styles.navigationContainer}>
            <div className={`${styles.ee} ${styles.active}`}> <LayoutDashboard size={20} /> <span>  Dashboard </span>  <div className={styles.bar}></div> </div>
            <div className={styles.ee} onClick={()=>navigate('/jobs-in-india')}> <BriefcaseBusiness size={20} />  <span>Jobs </span></div>
            <div className={styles.ee} onClick={()=>navigate('/editor')}> <PiReadCvLogoBold size={20} /> <span>Resume </span></div>
            <div className={styles.ee} onClick={()=>handleNavigateAtsAnalysis('ATS')}> <FileSearch size={20} />  <span>ATS </span></div>
            <div className={styles.ee} onClick={()=>handleNavigateAtsAnalysis('Analysis')}> <BarChart2 size={20} />  <span>Analysis </span></div> 
            <div className={styles.ee} onClick={()=>navigate('/resume-examples')}> <BookCopy size={20} /> <span>Resume Examples </span></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Left;
